import React, {FC, useCallback, useMemo, useState, useEffect} from "react";
import Modal from "../../../../../../../components/modal/Modal";
import {Button, CircularProgress, Grid, Typography, Stack} from "@mui/material";
import axiosInstance from "../../../../../../../utils/axios";
import {URLS} from "../../../../../../../utils/constants/urls";
import {Reload, setCandidateProfileReload, setNotificationMessage, setReload} from "../../../../../../../utils/redux";
import {useDispatch, useSelector} from "react-redux";
import {Validators} from "../../../../../../../utils/validators";
import InputField from "../../../../../../../components/InputField";

const VettingDocNumberModal: FC<{
  openModal: boolean,
  setOpenModal: (value: boolean) => void;
  id: string | undefined,
  vetting_document_id: string | undefined,
  candidate_id: string | undefined,
  title: string,
  number: string | undefined,
  type: string
}> = ({
        openModal,
        setOpenModal,
        id,
        vetting_document_id,
        candidate_id,
        title,
        number,
        type
      }) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [vettingNumber, setVettingNumber] = useState(number);
  const reload = useSelector(Reload);

  const [formError, setFormError] = useState<boolean>();

  const handleSave = useCallback(async () => {
    try {
      setLoading(true);
      await axiosInstance.post(
        `${process.env.REACT_APP_NODE_V3_API_URL}${URLS.vetting_doc_number_change}/${type}`,
        {
          request_id: id,
          vetting_document_id: vetting_document_id,
          candidate_id: candidate_id,
          number: vettingNumber
        }
      );
      dispatch(setReload(!reload));
      dispatch(setCandidateProfileReload(!reload));
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: `${title} has been changed successfully`,
        })
      );
      setOpenModal(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error updating data, try again later.",
        })
      );
      setOpenModal(false);
    }
  }, [dispatch, id, reload, vettingNumber]);

  const handleNumberChange = (value: string, field: string, error?: { error: boolean; message: string }) => {
    if (error && error.error) {
      setFormError(true);
    } else {
      setFormError(false);
    }
    setVettingNumber(value);
  }

  useEffect(() => {
    setVettingNumber(number);
  }, [number]);

  const numberModal = useMemo(() => {
    return (
      <Grid container>
        <Grid item>

          <InputField
            label={title}
            sx={{mt: 1}}
            fullWidth
            focused
            textChange={handleNumberChange}
            disabled={loading}
            value={vettingNumber}
            validators={[{
              check: type === 'rtw' ? Validators.RTW : Validators.NIN,
              message: type === 'rtw' ?  'Please enter a valid RTW Number' : 'Please enter a valid NIN Number'
            }, {
              check: Validators.required,
              message: 'This field is required'
            }]}
          />

        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Stack gap={"1rem"} direction={"row"}>
            <Button
              sx={{
                mt: 1,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
                p: "10px 24px",
                '&:hover': {
                  background: "#3e9bda",
                },
                '&:disabled': {
                  background: "#e7e7e7",
                  color: '#8f8f8f'
                }
              }}
              disabled={loading || formError}
              onClick={handleSave}
            >
              {loading && <CircularProgress sx={{mr: 1, color: '#fff'}} size={"1rem"}/>}Save Changes
            </Button>
            <Button
              disabled={loading}
              fullWidth={false}
              sx={{
                border: "none",
                color: "#000",
                fontSize: 16,
                fontWeight: 500,
                p: "10px",
              }}
              onClick={() => {
                setVettingNumber(number)
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  }, [handleSave, loading, number, vettingNumber]);

  return (
    <>
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={numberModal}
        title={title}
        size="sm"
        className={undefined}
      />
    </>
  )


}
export default VettingDocNumberModal
