import {
  Grid,
  Paper,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  Stack,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useState, useEffect } from "react";
import PageLoader from "../../../../../../../../components/PageLoader";
import axiosInstance from "../../../../../../../../utils/axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import { DocumentModel } from "../../../../../DocumentRepository/DocumentRepositoryModel";
import dayjs from "dayjs";
import CheckBox from "../../../../../../../../components/CheckBox";
import { images } from "../../../../../../../../utils/constants/images";
import {
  setNotificationMessage,
  VettingDocumentList,
  CandidateVettingDocActiveIndex,
  VettingDocuments,
} from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";

interface FromRepositoryProps {
  selectedFile: string;
  setSelectedFile: (name: string) => void;
}

const FromRepository: FC<FromRepositoryProps> = ({
  selectedFile,
  setSelectedFile,
}) => {
  const dispatch = useDispatch();
  const documentList = useSelector(VettingDocumentList);
  const vettingDocuments = useSelector(VettingDocuments);
  const active = useSelector(CandidateVettingDocActiveIndex);
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(false);

  const getDocuments = useCallback(async () => {
    setDocumentsLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `${URLS.document}?vetting_doc_id=${vettingDocuments[active].id}`,
        null
      );
      setDocuments(
        data.data.map((document: DocumentModel) => {
          const vettingDocument = documentList.filter((vetting_document) => {
            if (
              vetting_document?.sub_documents?.find((sub_document) => {
                return sub_document?.name === document.document_name;
              })
            ) {
              return vetting_document;
            }
            return null;
          });
          const first = vettingDocument.splice(0, 1)[0];
          const type = vettingDocument.reduce(
            (a, b) => a + " / " + b.name,
            first?.name
          );
          return {
            ...document,
            type,
          };
        })
      );
      setDocumentsLoading(false);
    } catch (e: any) {
      console.log(e);
      setDocumentsLoading(false);
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in fetching documents",
          })
        );
      }
    }
  }, [active, dispatch, documentList, vettingDocuments]);

  useEffect(() => {
    (async () => {
      await getDocuments();
    })();
  }, [getDocuments]);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#f5fbff",
      color: "#727272",
      border: "none",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "13px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      width: "14rem",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderTopLeftRadius: "12px",
      borderBottomLeftRadius: "12px",
      borderLeft: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      borderRight: "1px solid #DADADA",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      backgroundColor: "white",
      borderRadius: "8px",
      borderTop: "1px solid #DADADA",
    },
  }));

  return (
    <Grid container sx={{ px: 0, backgroundColor: "#f5fbff" }}>
      <Grid item xs={12} md={12} sx={{ paddingRight: 3 }}>
        {!documentsLoading ? (
          <>
            <Grid container xs={12} className={"mb-3"}>
              <Grid item xs={12} md={12}>
                <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                  <Table
                    sx={{
                      minWidth: 700,
                      borderSpacing: "0 1rem",
                      borderCollapse: "separate",
                      backgroundColor: "#f5fbff",
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>Document Name</StyledTableCell>
                        <StyledTableCell sx={{ minWidth: "111px" }}>
                          {" "}
                          Upload Date
                        </StyledTableCell>
                        <StyledTableCell>Document Type</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={"documents-table-body"}>
                      {documents?.map((document, index) => (
                        <StyledTableRow
                          className={"documents-table-body-row"}
                          key={index}
                        >
                          <StyledTableCell>
                            <CheckBox
                              checked={document.id === selectedFile}
                              type="circle"
                              sx={{ marginBottom: "8px" }}
                              onClick={() => setSelectedFile(document.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            {document.document_name}
                          </StyledTableCell>
                          <StyledTableCell>
                            {" "}
                            {dayjs(new Date(document.uploaded_on)).format(
                              "DD-MM-YYYY"
                            )}
                          </StyledTableCell>
                          <StyledTableCell>{document.type}</StyledTableCell>
                        </StyledTableRow>
                      ))}
                      {documents.length === 0 && !documentsLoading && (
                        <StyledTableRow className={"request-table-body-row"}>
                          <StyledTableCell colSpan={7}>
                            <Stack textAlign={"center"}>
                              <img
                                src={images.emptyIcon}
                                alt={"no request"}
                                className={"vertical-align-middle empty-image"}
                              />
                              <Typography variant={"body2"}>
                                You do not have any previously uploaded
                                documents
                              </Typography>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        ) : (
          <PageLoader />
        )}
      </Grid>
    </Grid>
  );
};

export default FromRepository;
