import React, {
  Dispatch,
  FC,
  Fragment,
  RefObject,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect, SyntheticEvent, ChangeEvent,
} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextFieldProps,
  Typography,
} from "@mui/material";
import Form, { FormDataModel } from "../../../../../../components/Form";
import { identifiers } from "../../../../../../utils/constants/identifiers";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import { RefereeFormModel } from "./RefereeFormModel";
import { useParams } from "react-router-dom";
import { removeErrorFieldsFromValues } from "../../../../../../utils/validators";
import axiosInstance from "../../../../../../utils/axios";
import { URLS } from "../../../../../../utils/constants/urls";
import {
  Reload,
  setNotificationMessage,
  setReload, UserRole,
} from "../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { Dayjs } from "dayjs";
import { DateRangePicker, DateRange } from "@mui/lab";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputField from "../../../../../../components/InputField";
import Modal from "../../../../../../components/modal/Modal";
import { images } from "../../../../../../utils/constants/images";
import { Stack } from "@mui/system";
import CheckBox from "../../../../../../components/CheckBox";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../../../utils/constants/coachMarks";
import {
  User,
  setUserData,
} from "../../../../../../utils/redux/reducer/authentication-slice";
import { CognitoUser } from "amazon-cognito-identity-js";
import * as Amplify from "../../../../../../utils/services/amplify";
import { isTourCompleted, markTourAsCompleted } from "../../../../../../utils/storage/tours";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import { FileCopy } from "@mui/icons-material";
import axios from "axios";

const ReferenceForm: FC<{
  setShowAddForm: (showAddForm: boolean) => void;
  type: string;
  setReInitiateRef: Dispatch<SetStateAction<boolean>>;
  reInitiate: boolean;
  referenceId: string;
}> = ({ setShowAddForm, type, setReInitiateRef, reInitiate, referenceId }) => {
  const dispatch = useDispatch();
  const { id, candidate_id } = useParams() as any;
  const reload = useSelector(Reload);
  let RefereeForm: RefObject<Form | null | undefined> = useRef();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [modalOpen, setModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [isGapReference, setIsGapReference] = useState<boolean>();

  const fileUploadRef = useRef<any>(null);
  const [attachedFile, setAttachedFile] = useState<File>();

  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const role = useSelector(UserRole);

  const setCoachMarksValue = useCallback(async () => {
    //if (!!user && user["custom:reference_tutorial"] !== "true") {
    const currentUser: CognitoUser = await Amplify.UserDetail();
    const body = {
      "custom:req_ref_form": "true",
    };
    await Amplify.UpdateUserDetails(body, currentUser);
    await Amplify.RefreshSession();
    dispatch(setUserData({ ...user, ...body }));
    //}
  }, [user, dispatch]);

  useEffect(() => {
    if (!!user && user["custom:req_ref_form"] !== "true") {
      if (type === "character") {
        if (isGapReference === true && isGapReference !== undefined) {
          console.log(isGapReference);
          setCurrentStep(tourStep.candidate_required_reference_form.index);
        }
      }
    }
  }, [isGapReference, setCurrentStep, type, user]);

  const handleUpload = (e: SyntheticEvent) => {
    e.preventDefault();
    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  };

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files)
    if (event.target.files) {
      setAttachedFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    if (isGapReference === true && isGapReference !== undefined) {
      if (tourStep.candidate_required_reference_form.index === currentStep) {
        if (!isTourCompleted('candidate_required_reference_form')) {
          setIsOpen(true);
        }
      } else if (
        currentStep ===
        tourStep.candidate_required_reference_form.index +
        tourStep.candidate_required_reference_form.steps
      ) {
        setIsOpen(false);
        markTourAsCompleted('candidate_required_reference_form');
        if (!!user && user["custom:req_ref_form"] !== "true") {
          (async () => {
            await setCoachMarksValue();
          })();
        }
      }
    }
  }, [currentStep, setCoachMarksValue, setIsOpen, user, isGapReference]);

  const reInitiateReference = useCallback(async () => {
    try {
      await axiosInstance.delete(`${URLS.candidate_reference}/${referenceId}`);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Reference Re-initiated Successfully",
        })
      );
      setReInitiateRef(false);
      setLoading(false);
      setModalOpen(false);
      dispatch(setReload(!reload));
      setShowAddForm(false);
    } catch (e) {
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in re-initiating the reference",
        })
      );
    }
  }, [referenceId, dispatch, setReInitiateRef, reload, setShowAddForm]);

  const createReference = useCallback(async () => {
    try {
      setLoading(true);
      const { getFormData } = RefereeForm.current as {
        getFormData: () => { formData: FormDataModel; isFormValid: boolean };
      };
      const { formData, isFormValid } = getFormData();
      const formBody: FormDataModel = removeErrorFieldsFromValues(formData);
      let body;
      if (isFormValid) {
        if (type === "character" && isGapReference) {
          body = {
            candidate_id: role === 'agency' ? candidate_id : '',
            request_id: id,
            ref_type: type,
            gap_reference: isGapReference,
            has_referee_document: false,
            form_data: {
              ...formBody,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
              comments: comment,
            },
          };
        } else if (type === "character" && !isGapReference) {
          body = {
            candidate_id: role === 'agency' ? candidate_id : '',
            request_id: id,
            ref_type: type,
            has_referee_document: false,
            form_data: {
              ...formBody,
            },
          };
        } else {
          body = {
            candidate_id: role === 'agency' ? candidate_id : '',
            request_id: id,
            ref_type: type,
            has_referee_document: false,
            form_data: {
              ...formBody,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
            },
          };
        }
        // check if file is attached
        if (attachedFile) {
          const max_size = identifiers.agency_max_file_size as number;
          // if file size is smaller than allowed
          if (attachedFile.size <= max_size) {
            body.has_referee_document = true;
            const reference = await axiosInstance.post(`${URLS.candidate_reference}`, body);

            if (reference.data && reference.data.reference_id) {

              const reference_id = reference.data.reference_id;
              const session = await Amplify.currentSession();
              const token = session.getIdToken().getJwtToken();
              const formRequestData = new FormData();
              formRequestData.append("files", attachedFile, attachedFile.name);
              formRequestData.append("doc_type", 'candidate_upload');

              await axios.post(
                `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/referee-document/${reference_id}`,
                formRequestData,
                {
                  headers: {
                    Authorization: `${token}`
                  }
                }
              );

              // if (fileUploadRef.current) {
              //   fileUploadRef.current.value = null;
              // }
            }
          } else {
            setLoading(false);
            dispatch(
              setNotificationMessage({
                display: true,
                severity: "error",
                message: `You cannot upload file more than ${identifiers.maxFileSizeText}`,
              })
            );
            if (fileUploadRef.current) {
              fileUploadRef.current.value = null;
            }
          }
        } else {
          // if not attached any files submit normal
          await axiosInstance.post(`${URLS.candidate_reference}`, body);
        }

        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: "Reference Requested Successfully",
          })
        );
        if (reInitiate) {
          await reInitiateReference();
        } else {
          setLoading(false);
          setModalOpen(false);
          dispatch(setReload(!reload));
          setShowAddForm(false);
        }
      } else {
        setLoading(false);
        setHasError(true);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in requesting references",
        })
      );
    }
  }, [
    dateRange,
    dispatch,
    id,
    reload,
    setShowAddForm,
    type,
    comment,
    isGapReference,
    reInitiate,
    reInitiateReference,
    attachedFile
  ]);

  const createReferenceModalComp = useMemo(() => {
    return (
      <Grid container padding={"0px"}>
        <Grid item xs={12} md={12}>
          <Stack
            sx={{
              background: "#F3FCFF",
              padding: "20px ",
              justifyContent: "space-between",
            }}
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
          >
            <Box
              sx={{ display: "flex", direction: "row", alignItems: "center" }}
            >
              <img src={images.warningTooltip} alt={"tooltip"} />
              <Typography
                variant="h6"
                sx={{
                  ml: 2,
                  fontSize: 18,
                  fontWeight: 500,
                  color: "#000000",
                  textAlign: "center",
                  letterSpacing: "0.15px",
                }}
              >
                Confirm Referee details
              </Typography>
            </Box>
            <img
              onClick={() => {
                setModalOpen(false);
              }}
              style={{ width: "20px" }}
              src={images.closeBtn}
              alt={"close button"}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12} padding={"20px"}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 18,
              fontWeight: 400,
              color: "#333333",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Please double-check the referee's information as once submitted, you
            will not be able to change it.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          padding={"20px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Button
            sx={{
              border: "1px solid #5AB9F9",
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px 2rem",
              '&:hover': {
                background: "#3489c2",
              }
            }}
            disabled={loading}
            onClick={createReference}
          >

            {loading && <CircularProgress sx={{ mr: 1, "color": '#fff' }} size={20} />}Continue

          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: '#fff',
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [createReference, loading]);

  const CreateReferenceModal = useMemo(() => {
    return (
      <Modal
        open={modalOpen}
        setModalClose={setModalOpen}
        children={createReferenceModalComp}
        title={undefined}
        size="sm"
        className={undefined}
        padding="0px"
        Radius="15px"
      />
    );
  }, [modalOpen, createReferenceModalComp]);


  return (
    <>
      {CreateReferenceModal}
      <Grid container className="card-vetting">
        <Grid container className="card-head-table">
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              py: 1,
              minHeight: "40px",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography
                variant={"body2"}
                fontSize={"16px"}
                fontWeight={"500"}
                textTransform={"capitalize"}
                sx={{ pl: 1 }}
              >
                {type + " Reference"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            padding: {
              xs: '2rem',
              md: '0'
            }
          }}
        >

          <Grid container sx={{
            display: "flex",
            padding: {
              xs: '0',
              md: '0 5rem'
            }
          }}>
            <Grid item xs={12} lg={10}>
              <Typography
                variant="h6"
                fontWeight={"500"}
                fontSize={"14px"}
                sx={{ mb: 2 }}
              >
                Referee Details
              </Typography>
            </Grid>
            <Grid item xs={12} lg={10}>
              <Grid container spacing={2}>
                <Form
                  values={{}}
                  hasError={hasError}
                  fieldError={identifiers.field_error as string}
                  ref={RefereeForm as RefObject<Form>}
                  model={RefereeFormModel(type)}
                  onChange={(field, value, formData, isFormValid) =>
                    setButtonActive(isFormValid)
                  }
                />
                {type !== identifiers.character && (
                  <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        open={open}
                        onClose={() => setOpen(false)}
                        startText={
                          type === identifiers.academic
                            ? "Course start date"
                            : "Employment from"
                        }
                        endText={
                          type === identifiers.academic
                            ? "Course end date"
                            : "Employment to"
                        }
                        calendars={1}
                        value={dateRange}
                        onChange={(newValue: any) => {
                          setDateRange(newValue);
                        }}
                        inputFormat={identifiers.date_format as string}
                        renderInput={(
                          startProps: TextFieldProps,
                          endProps: TextFieldProps
                        ) => (
                          <Fragment>
                            <InputField
                              {...startProps}
                              size={"small"}
                              autoComplete="off"
                              sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                              onClick={() => setOpen(true)}
                            />

                            <Box sx={{ mx: 2 }}> to </Box>

                            <InputField
                              {...endProps}
                              size={"small"}
                              autoComplete="off"
                              sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                              onClick={() => setOpen(true)}
                            />
                          </Fragment>
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {type === identifiers.character && (
              <Grid item md={10}>
                <CheckBox
                  style={{ zoom: 0.8, marginBottom: "8px", marginRight: "8px" }}
                  type={"square"}
                  checked={isGapReference}
                  onChange={() => setIsGapReference(!isGapReference)}
                />
                <Typography
                  variant={"body1"}
                  display={"inline-block"}
                  sx={{ pr: 2 }}
                  data-tut="candidate_gap_reference"
                >
                  This is an Employment Gap Reference
                </Typography>
                {isGapReference && (
                  <Stack>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        open={open}
                        onClose={() => setOpen(false)}
                        startText={"Gap from"}
                        endText={"Gap to"}
                        calendars={1}
                        value={dateRange}
                        onChange={(newValue: any) => {
                          setDateRange(newValue);
                        }}
                        inputFormat={identifiers.date_format as string}
                        renderInput={(
                          startProps: TextFieldProps,
                          endProps: TextFieldProps
                        ) => (
                          <Fragment>
                            <InputField
                              {...startProps}
                              size={"small"}
                              autoComplete="off"
                              sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                              onClick={() => setOpen(true)}
                            />

                            <Box sx={{ mx: 2 }}> to </Box>

                            <InputField
                              {...endProps}
                              size={"small"}
                              autoComplete="off"
                              sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                              onClick={() => setOpen(true)}
                            />
                          </Fragment>
                        )}
                      />
                    </LocalizationProvider>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        className=" pl-2 py-3"
                        display={"flex"}
                        alignItems={"center"}
                      >
                        Reason Of Gap in Employment{" "}
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <InputField
                        data-tut="candidate_gap_reference_additional_comments"
                        multiline
                        value={comment}
                        textChange={(e) => {
                          setComment(e);
                          console.log(e, comment);
                        }}
                        rows={6}
                        sx={{ background: "#ffffff" }}
                        placeholder={
                          "The applicant may email once they have fulfilled the requirement of this request."
                        }
                      />
                    </Grid>
                  </Stack>
                )}
              </Grid>
            )}

            <Grid item md={12} sx={{ marginTop: '1rem' }}>
              <Typography
                variant="h6"
                fontWeight={"500"}
                fontSize={"14px"}
                sx={{ mb: 1 }}
              >
                Upload Referee Document (Optional)
              </Typography>
              <Typography
                variant="h6"
                fontWeight={"400"}
                fontSize={"14px"}
                sx={{ mb: 2 }}
              >
                If you have already obtained a reference file from this referee, you use the button provided below to upload it
              </Typography>

              <Stack sx={{ width: 320 }}>


                {!!attachedFile ? (
                  <PrimaryButton
                    sx={{
                      border: "1px solid #5AB9F9",
                      borderRadius: "6px",
                      background: "white",
                      color: "#5AB9F9",
                      minWidth: "180px",
                    }}
                    onClick={() => {
                      fileUploadRef.current.value = null;
                      setAttachedFile(undefined);
                    }}
                  >
                    <FileCopy />
                    <Typography
                      variant={"body2"}
                      width={"100%"}
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                    >
                      {attachedFile.name}
                    </Typography>
                    <CancelIcon />
                  </PrimaryButton>
                ) : (
                  <PrimaryButton
                    sx={{
                      border: "1px dashed #5AB9F9",
                      borderRadius: "6px",
                      background: "white",
                      color: "#5AB9F9",
                      minWidth: "180px",
                    }}
                    disabled={loading}
                    className="uploadBtn mb-2"
                    onClick={handleUpload}
                  >
                    <AddCircleRoundedIcon />
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "22px",
                        ml: 1,
                      }}
                      data-tut="candidate_upload_academic_qualification_"
                    >
                      Upload Document
                    </Typography>
                  </PrimaryButton>
                )}
                <input
                  ref={fileUploadRef}
                  hidden
                  accept={identifiers.accept as string}
                  size={identifiers.maxFileSize as number}
                  type="file"
                  onChange={selectFile}
                />

              </Stack>
            </Grid>

          </Grid>

          <Grid
            item
            textAlign={"center"}
            xs={12}
            sx={{
              py: 1,
              px: 3,
              mt: "20px",
              background: "#e9f6ff",
              borderRadius: "18px",
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#FFFFFF",
                border: " 1px solid #5AB9F9",
                borderRadius: "5px",
                padding: "4px 44px",
                color: "#5AB9F9",
                fontSize: "14px",
                margin: "0px 20px",
                maxHeight: "40px",
              }}
              onClick={() => {
                setShowAddForm(false);
              }}
            >
              Cancel
            </PrimaryButton>
            <PrimaryButton
              fullWidth={false}
              sx={{
                background: "#5AB9F9",
                boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                borderRadius: "6px",
                color: "white",
                maxHeight: "40px",
                minWidth: "180px",
              }}
              data-tut="candidate_gap_reference_submit_btn"
              disabled={
                type !== identifiers.character
                  ? !buttonActive ||
                  loading ||
                  !dateRange[0]?.format(identifiers.date_input as string) ||
                  !dateRange[1]?.format(identifiers.date_input as string)
                  : isGapReference
                    ? comment === "" ||
                    !buttonActive ||
                    loading ||
                    !dateRange[0]?.format(identifiers.date_input as string) ||
                    !dateRange[1]?.format(identifiers.date_input as string)
                    : !buttonActive || loading
              }
              onClick={() => {
                setModalOpen(true);
              }}
            >
              {loading && <CircularProgress sx={{ mr: 1 }} size={20} />}
              Send Request
            </PrimaryButton>
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};

export default ReferenceForm;
