import { Paper, Stack } from "@mui/material";
import AgencyHeader from "./Components/AgencyHeader";
import AgencyList from "./AgencyList/AgencyList";
import { useCallback, useEffect, useState } from "react";
import CreateAgency from "./Forms/CreateAgency";
import { useDispatch, useSelector } from "react-redux";
import { AgencyDetailsModel } from "./Interfaces/AgencyInterfaces";
import axiosInstance from "../../../../utils/axios";
import { Auth } from "aws-amplify";
import { setFilteredDate, setNotificationMessage } from "../../../../utils/redux";
import EditAgency from "./Forms/EditAgency";
import CreateComplianceOfficer from "./Forms/CreateComplianceOfficer";
import dayjs from "dayjs";
import ChangeLimitGap from "./Forms/ChangeLimitGap";
import LimitCapHistory from "./Forms/LimitCapHistory";
import {
  IsLimitCapChangeDialogOpened,
  setOpenedAgency,
  IsHistoryDialogOpened,
  GetOpenedAgency,
  setServicesData,
  setServicesDataLoading,
  IsCreateAgencyDialogOpened,
  IsEditAgencyDialogOpened,
  IsAddComplianceOfficerDialogOpened
} from "../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";

const Agencies = () => {
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [dateFrom, setDateFrom] = useState<string | null>(null);
  const [dateTo, setDateTo] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);
  const [openRows, setOpenRows] = useState<string | null>(null);
  const [rows, setRows] = useState<AgencyDetailsModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  const isChangeDialogOpened = useSelector(IsLimitCapChangeDialogOpened);
  const isHistoryDialogOpen = useSelector(IsHistoryDialogOpened);
  const isCreateAgencyDialogOpened = useSelector(IsCreateAgencyDialogOpened);
  const isAddComplianceOfficerDialogOpened = useSelector(IsAddComplianceOfficerDialogOpened);
  const isEditAgencyDialogOpened = useSelector(IsEditAgencyDialogOpened);
  const openedAgency = useSelector(GetOpenedAgency);

  const api_url = `${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies`;

  //dates header
  const applyFilters = (start_date: Date | null, end_date: Date | null) => {
    const formattedStartDate = start_date ? dayjs(start_date).format('DD-MM-YYYY') : null;
    const formattedEndDate = end_date ? dayjs(end_date).format('DD-MM-YYYY') : null;
    setDateFrom(formattedStartDate);
    setDateTo(formattedEndDate);

    dispatch(setFilteredDate({
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }))
  };

  //get all agency list
  const getAgencyData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        api_url
      );
      setRows(response.data);
      setIsLoading(false);
    } catch (error) {
      if (await Auth.currentUserInfo()) {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: 'There is an error in fetching agencies',
          })
        );
      }
    }
    setIsLoading(false);
  }, [api_url, dispatch]);

  useEffect(() => {
    getAgencyData();
  }, [getAgencyData]);

  // fetch the service data
  const fetchServicesData = useCallback(async () => {
    dispatch(setServicesDataLoading(true))
    try {
      const response = await axiosInstance.get(
        `${api_url}/${openedAgency?.agency_id}?start_date=${dateFrom}&end_date=${dateTo}`
      );
      dispatch(setServicesData(response.data))
    } catch (error) {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: 'There is an error in fetching service data.',
        })
      )
    } finally {
      dispatch(setServicesDataLoading(false))
    }
  }, [dispatch, openedAgency, dateFrom, dateTo]);

  useEffect(() => {
    if (openedAgency !== null) {
      fetchServicesData();
    }
  }, [fetchServicesData, openedAgency, dateFrom, dateTo])

  //handle toggle of per agency
  const handleToggle = (index: string) => {
    setOpenRows((prevIndex) => {
      return prevIndex === index ? null : index;
    });
    const opendRow = rows.find(row => row.agency_id === index)
    dispatch(setOpenedAgency(index !== null ? opendRow : null))
  };

  //handle change page
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  //handle search agency
  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  //On update agency handlers
  const handleAgencyUpdates = async () => {
    await getAgencyData();
    setOpenRows(null);
  };

  //Excel export
  const handleExcelExport = async () => {
    try {
      console.log(dateFrom);
      console.log(dateTo);
      setIsExporting(true)
      const response = await axiosInstance.get(
        `${api_url}/serviceCountExcelExport?startDate=${dateFrom}&endDate=${dateTo}`,
        { responseType: 'blob' } // Ensures the file is downloaded
      );

      const getTodayDate = () => {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const yyyy = today.getFullYear();
        return `${dd}-${mm}-${yyyy}`;
      };

      // Generate dynamic filename
      let filename = "All-agency-export";

      if (dateFrom && dateTo) {
        filename += `-${dateFrom}-${dateTo}`;
      } else if (dateFrom) {
        filename += `-${dateFrom}-${getTodayDate()}`;
      } else if (dateTo) {
        filename += `-beginning-${dateTo}`;
      }

      filename += ".xlsx";

      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;

      // Filename for the downloaded Excel
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'success',
          message: 'Excel file downloaded successfully!',
        })
      );
      setIsExporting(false)
    } catch (error) {
      setIsExporting(false)
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: 'Failed to download the Excel file.',
        })
      );
    }
  };

  return (
    <>
      <Stack padding={'1rem'} component={Paper}>
        <AgencyHeader
          onSearch={handleSearch}
          searchQuery={searchQuery}
          onApplyFilter={applyFilters}
          onExcelExport={handleExcelExport}
          isExporting={isExporting}
        />
        <AgencyList
          searchQuery={searchQuery}
          rows={rows}
          openRows={openRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onToggle={handleToggle}
          onChangePage={handleChangePage}
          onServiceRefresh={fetchServicesData}
          isLoading={isLoading}
        />
        {isCreateAgencyDialogOpened && (
          <CreateAgency
            isOpen={isCreateAgencyDialogOpened}
            onComplete={handleAgencyUpdates}
          />
        )}
        {isEditAgencyDialogOpened &&
          <EditAgency
            isOpen={isEditAgencyDialogOpened}
            onComplete={handleAgencyUpdates}
          />
        }
        {isAddComplianceOfficerDialogOpened &&
          <CreateComplianceOfficer
            isOpen={isAddComplianceOfficerDialogOpened}
            onComplete={handleAgencyUpdates}
          />
        }
        {isChangeDialogOpened &&
          <ChangeLimitGap
            isOpen={isChangeDialogOpened}
            onComplete={handleAgencyUpdates}
          />
        }
        {isHistoryDialogOpen &&
          <LimitCapHistory
            isOpen={isHistoryDialogOpen}
          />
        }
      </Stack>
    </>
  );
};

export default Agencies;
