import {Button, CircularProgress, Grid, Stack, Typography} from "@mui/material";
import {BlockOutlined, DeleteForever, FileCopy, InfoOutlined, RemoveRedEye} from "@mui/icons-material";
import React, {useMemo, useState} from "react";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import Modal from "../../../../components/modal/Modal";
import axiosInstance from "../../../../utils/axios";
import {URLS} from "../../../../utils/constants/urls";
import {setNotificationMessage} from "../../../../utils/redux";
import {useDispatch} from "react-redux";
import {routes} from "../../../../utils/constants/routes";
import {useNavigate} from "react-router-dom";

interface RefereeDocumentTableProps {
  document_key: string,
  document_type: string,
  showDelete?: boolean,
  showDecline?: boolean,
  reference_id?: string | null,
  onRemoveFile?: () => void;
  onDecline?: (have_document: boolean) => void;
  isDeclined?: boolean | null,
  showDeclinedMessage?: boolean,
  showRefereeDocUploadButton?: boolean
}

const RefereeDocumentTable: React.FC<RefereeDocumentTableProps> = (
  {
    document_key,
    document_type,
    showDelete = false,
    showDecline = false,
    reference_id,
    onRemoveFile,
    onDecline,
    isDeclined,
    showDeclinedMessage,
    showRefereeDocUploadButton = false
  }
) => {

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);


  const getFileNameFromUrl = (url: string): string => {
    // Split the URL by slashes (/)
    const parts = url.split('/');

    // Return the last part of the split array, which is the file name
    return parts[parts.length - 1];
  }

  const confirmDelete = async () => {
    setLoading(true);
    try {
      await axiosInstance.delete(`${process.env.REACT_APP_NODE_V3_API_URL}/referee-document/${reference_id}`);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "Document removed successfully.",
        })
      );
      if (onRemoveFile) {
        onRemoveFile()
      }

    } catch (e) {
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Something went wrong while deleting the document, please try again.",
        })
      );
    }
  }

  const confirmDecline = async (have_document: boolean) => {
    setLoadingDecline(true);
    try {
      await axiosInstance.patch(`${process.env.REACT_APP_NODE_V3_API_URL}/referee-document/${reference_id}`);
      if (onDecline) {
        onDecline(have_document);
      }
      setDeclineModal(false)
      setLoading(false);
    } catch (e) {
      setLoadingDecline(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Something went wrong while declining the document, please try again.",
        })
      );
    }
  }

  const modalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              textAlign: "center",
              letterSpacing: "0.15px",
            }}
          >
            Delete Document
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Are you sure?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Button
            sx={{
              border: "none",
              color: "#fff",
              fontSize: 16,
              fontWeight: 500,
              mr: 2,
              mt: 1,
              p: "10px",
              background: '#dc3545',
              '&:hover': {
                background: 'red'
              }
            }}
            disabled={loading}
            onClick={confirmDelete}
          >
            {loading && <CircularProgress sx={{mr: 1, color: '#fff'}} size={'1rem'}/>}Delete Document
          </Button>
          <PrimaryButton
            disabled={loading}
            fullWidth={false}
            sx={{
              mt: 1,
              background: "#5AB9F9",
              color: "#ffffff",
              fontSize: 16,
              fontWeight: 500,
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Cancel
          </PrimaryButton>
        </Grid>
      </Grid>
    );
  }, [confirmDelete, loading]);

  const declineModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          <Typography
            variant="h6"
            sx={{
              marginBottom: "20px",
              fontSize: 18,
              fontWeight: 500,
              color: "#000000",
              letterSpacing: "0.15px",
            }}
          >
            Decline Document
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mb: 3,
              fontSize: 16,
              fontWeight: 500,
              color: "#727272",
            }}
          >
            Do you have a reference file to upload for this applicant?
          </Typography>
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Stack direction={"row"} gap={"1rem"}>
            <Button
              sx={{
                fontSize: '1rem',
                color: 'white',
                paddingLeft: '2rem',
                paddingRight: '2rem',
              }}
              color={"primary"}
              variant={"contained"}
              disabled={loadingDecline}
              onClick={() => confirmDecline(true)}
            >
              {loading && <CircularProgress sx={{mr: 1, color: '#fff'}} size={'18px'}/>}Yes
            </Button>
            <Button
              disabled={loadingDecline}
              fullWidth={false}
              variant={"outlined"}
              onClick={() => confirmDecline(false)}
              sx={{
                fontSize: '1rem'
              }}
            >
              No
            </Button>

          </Stack>
        </Grid>
      </Grid>
    );
  }, [confirmDecline, loadingDecline]);

  const openDeletePopup = useMemo(() => {
    return (
      <Modal
        open={openModal}
        setModalClose={setOpenModal}
        children={modalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [openModal, modalText]);

  const openDeclinePopup = useMemo(() => {
    return (
      <Modal
        open={declineModal}
        setModalClose={setDeclineModal}
        children={declineModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [declineModal, declineModalText]);

  return (
    <>
      {openDeletePopup}
      {openDeclinePopup}
      <Stack sx={{borderRadius: '0.5rem', border: '1px solid #d6e8fe'}}>
        <Stack direction={"row"} sx={{
          padding: '1rem',
          background: '#e9f6ff',
          gap: '0.5rem',
          borderTopRightRadius: '0.5rem',
          borderTopLeftRadius: '0.5rem'
        }}>
          <Typography
            variant="h6"
            fontWeight={"500"}
            fontSize={"14px"}
          >
            Referee Document

          </Typography>

          <Typography
            variant="h6"
            fontWeight={"500"}
            fontSize={"14px"}
          >
            {document_type === 'candidate_upload' &&
              <>(Uploaded by Candidate)</>
            }
            {document_type === 'ref_upload' &&
              <>(Uploaded by Referee)</>
            }
          </Typography>
        </Stack>
        <Stack sx={{
          padding: '1rem',
          background: '#f5fbff',
          borderBottomRightRadius: '0.5rem',
          borderBottomLeftRadius: '0.5rem'
        }}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              background: '#fff',
              padding: '1rem',
              borderRadius: '0.5rem',
              boxShadow: '1px 1px 8px #eee',
              cursor: 'pointer',
              '&:hover': {
                boxShadow: '1px 1px 14px #ddd',
              }
            }}
            onClick={() => {
              window.open(`${process.env.REACT_APP_S3_BUCKET}${document_key}`, '_blank');
            }}
          >

            <Stack direction={"row"} gap={"1rem"}>
              <FileCopy sx={{color: '#adadad'}}/>
              <Typography
                variant="h6"
                fontWeight={"500"}
                fontSize={"14px"}
              >
                {getFileNameFromUrl(document_key)}
              </Typography>
            </Stack>

            <Stack direction={"row"} gap={"1rem"}>
              <Button
                startIcon={<RemoveRedEye/>}
                variant={"outlined"}
                onClick={() => {
                  window.open(`${process.env.REACT_APP_S3_BUCKET}${document_key}`, '_blank');
                }}
              >
                View
              </Button>
              {showDelete &&
                <Button
                  startIcon={<DeleteForever/>}
                  variant={"outlined"}
                  color={"error"}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpenModal(true)
                  }}
                >
                  Delete
                </Button>
              }
            </Stack>

          </Stack>

          {showDecline && !isDeclined &&
            <Stack sx={{
              padding: '1rem',
              borderBottomRightRadius: '0.5rem',
              borderBottomLeftRadius: '0.5rem',
              background: '#fff3e6',
              gap: '1rem'
            }}>

              <Stack direction={"row"} gap={"0.5rem"} alignItems={"center"}>
                <InfoOutlined sx={{color: '#c59b6e'}}/>
                <Typography
                  variant="h6"
                  fontWeight={"400"}
                  fontSize={"14px"}
                >
                  If candidate provided an invalid document please click decline button below.
                </Typography>
              </Stack>

              <Button
                startIcon={<BlockOutlined/>}
                variant={"outlined"}
                color={"error"}
                onClick={(e) => {
                  setDeclineModal(true)
                }}
                sx={{
                  width: 'fit-content'
                }}
              >
                Decline Reference Document
              </Button>
            </Stack>
          }
          {isDeclined &&
            <Stack sx={{
              padding: '1rem',
              borderBottomRightRadius: '0.5rem',
              borderBottomLeftRadius: '0.5rem',
              background: '#ffd8d8',
              gap: '1rem'
            }}>
              <Stack direction={"row"} gap={"0.5rem"}>
                <InfoOutlined sx={{color: '#d05252'}}/>

                <Stack flexDirection={"column"} gap={"0.5rem"}>

                  <Typography
                    variant="h6"
                    fontWeight={"400"}
                    fontSize={"14px"}
                  >
                    It seems you've declined this document.
                    To continue with the reference process,
                    please click the <strong>Proceed</strong> button below.
                  </Typography>

                  {showRefereeDocUploadButton &&
                    <Stack flexDirection={"row"} gap={"0.5rem"} alignItems={"center"}>
                      <Typography
                        variant="h6"
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        Should you possess a reference file for this applicant already,
                      </Typography>
                      <Button variant={"outlined"} size={"small"}
                              sx={{
                                color: 'black',
                                borderColor: 'black',
                                '&:hover': {
                                  borderColor: 'black'
                                }
                              }}
                              onClick={() => Navigate(`../${routes.referee_details}?ref_doc=true`)}
                      >
                        Click Here
                      </Button>
                      <Typography
                        variant="h6"
                        fontWeight={"400"}
                        fontSize={"14px"}
                      >
                        to upload.
                      </Typography>
                    </Stack>
                  }
                </Stack>

              </Stack>

            </Stack>
          }

          {showDeclinedMessage &&
            <Stack sx={{
              padding: '1rem',
              borderBottomRightRadius: '0.5rem',
              borderBottomLeftRadius: '0.5rem',
              background: '#ffd8d8',
              gap: '1rem'
            }}>
              <Stack direction={"row"} gap={"0.5rem"}>
                <InfoOutlined sx={{color: '#d05252'}}/>
                <Typography
                  variant="h6"
                  fontWeight={"400"}
                  fontSize={"14px"}
                >
                  The referee declined the original document and has uploaded a new document below.
                </Typography>
              </Stack>

            </Stack>
          }
        </Stack>
      </Stack>
    </>
  )
}
export default RefereeDocumentTable;