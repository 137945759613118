import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Pagination,
  Stack,
  Typography
} from "@mui/material";
import axiosInstance from "../../../utils/axios";
import {
  getTotalNewNotificationsCount,
  isNewNotification,
  setNotificationMessage,
  setWebSocketData
} from "../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { User, UserRole, } from "../../../utils/redux/reducer/authentication-slice";
import avatar from "../../../utils/Avatar/avatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { agency } from "../../../utils/constants/routes";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SubNotifications from "../../../common/NavBar/NotificationListing/SubNotifications";
import relativeTime from "dayjs/plugin/relativeTime";
import { ReplayOutlined } from "@mui/icons-material";

const NotificationPage: FC = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  dayjs.extend(relativeTime);

  const [notifications, setNotifications] = useState<any[]>([]);
  const [totalUnseenCount, setTotalUnseenCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMarking, setIsMarking] = useState<boolean>(false);
  const [openRows, setOpenRows] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isTotalPagesLoading, setTotalPagesLoading] = useState(true);
  const role = useSelector(UserRole);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const newNotification = useSelector(isNewNotification);
  const notificationCount = useSelector(getTotalNewNotificationsCount)
  const originalTitleRef = useRef(document.title);

  useEffect(() => {
    // Log the change in newNotification
    if (newNotification) {
      let intervalId: NodeJS.Timeout | null = null;
      getNotifications(page, true);
      dispatch(setWebSocketData({
        new_notification: false,
        is_tab_notification: false,
        total_new_notifications: 0
      }));

      // Define the function to handle visibility change
      const handleVisibilityChange = () => {
        if (document.hidden && notificationCount > 0) {
          // Update the document title and set an interval to blink the title
          document.title = `(${notificationCount}) Notifications - KED`;
          intervalId = setInterval(() => {
            document.title = document.title === originalTitleRef.current ? `(${notificationCount}) Notifications - KED` : originalTitleRef.current;
          }, 3000);
        } else {
          // Clear the interval and restore the original title
          if (intervalId) {
            clearInterval(intervalId);
          }
          document.title = originalTitleRef.current;
        }
      };

      // Add the visibility change event listener
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Clean up the interval and event listener when the component unmounts or newNotification changes
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }

  }, [newNotification, notificationCount, page]);

  const handleToggle = (index: number) => {
    setOpenRows((prevIndex) => (prevIndex === index ? null : index));
  }
  useEffect(() => {
    if (openRows === null) {
      getNotifications(page, true);
    }
  }, [openRows]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    getNotifications(value, true);
  };

  const getNotifications = useCallback(async (page = 1, silent: boolean = false) => {
    try {
      if (!silent) {
        setLoading(true);
      }
      const limit = 10;
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_V3_API_URL}/authorized/notification?limit=${limit}&page=${page}`);
      if (response.status === 200) {
        const notifications = Object.values(response.data.notifications);
        notifications.sort((a: any, b: any) => a.seen - b.seen);
        setNotifications(notifications);
        setTotalUnseenCount(response.data.total_notification_unseen);
        await getTotalPages();
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to get notifications",
        })
      );
    }
  }, [dispatch, page]);


  const getTotalPages = useCallback(async () => {
    setTotalPagesLoading(true);
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_V3_API_URL}/authorized/notification-pages`);
      if (response.status === 200) {
        setTotalPages(response.data.totalPages);
        setTotalPagesLoading(false);
      }
    } catch (e) {
      setTotalPagesLoading(false);
      console.error("Error fetching total pages:", e);
    }
  }, [setTotalPages]);
  const handleClose = () => {
    setNotifications([]);
  };

  const navigate = async (candidateId: string, requestId: string) => {
    await markNotificationsAsSeen(candidateId, requestId)
    Navigate(`${agency.requests}/${requestId}/applicants/${candidateId}`);
    handleClose();
  };

  const markNotificationsAsSeen = async (candidateId: String, requestId: String) => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_NODE_V3_API_URL}/notification/candidate/${candidateId}/${requestId}`);
    } catch (error) {
      console.error('Error marking notifications as seen:', error);
    }
  };
  const markAllNotificationsAsSeen = async () => {
    try {
      setIsMarking(true)
      await axiosInstance.post(`${process.env.REACT_APP_NODE_V3_API_URL}/notification/mark-all/${user.sub}`);
      getNotifications(page, true);
      setIsMarking(false)
    } catch (error) {
      console.error('Error marking notifications as seen:', error);
    }
  };
  return (
    <Stack>
      {loading ? (
        <Stack alignItems={'center'} justifyContent={'center'}
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            width: '100vw',
            height: '100vh',
          }}>
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <Stack>
          <Stack flexDirection="row" alignItems="center" justifyContent={'space-between'} margin={2}>
            <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
              <Typography>
                Unread
              </Typography>
              <Chip
                color="primary"
                label={totalUnseenCount}
                size="small"
                variant={"filled"}
                sx={{
                  color: 'white'
                }}
              />
            </Stack>
            {/*<Stack flexDirection={'row'} gap={1} alignItems={'center'}>*/}
            {/*  {totalUnseenCount > 0 && !loading &&*/}
            {/*    <Button variant="outlined" disabled={isMarking} onClick={() => markAllNotificationsAsSeen()}>*/}
            {/*      MARK ALL AS READ*/}
            {/*    </Button>*/}
            {/*  }*/}
            {/*</Stack>*/}
            <Stack>
              <IconButton onClick={() => getNotifications(1, false)}>
                <ReplayOutlined />
              </IconButton>
            </Stack>
          </Stack>
          <Divider />
          {notifications.map((notificationGroup: any, index: number) => (
            <React.Fragment key={index}>
              <Stack sx={{ padding: '1rem' }}>
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ opacity: notificationGroup.total_unseen === 0 ? '0.7' : 1, width: '100%' }}
                >
                  <Stack
                    direction={'row'}
                    gap={'1rem'}
                    alignItems={'center'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(notificationGroup.candidate_id, notificationGroup.request_id);
                    }}
                  >
                    <Badge
                      color="primary"
                      badgeContent={notificationGroup.total_unseen}
                      invisible={notificationGroup.total_unseen === 0}
                      sx={{
                        '& .MuiBadge-badge': {
                          color: 'white',
                        },
                      }}
                    >
                      <Avatar {...avatar(`${notificationGroup.candidate_name}`)} />
                    </Badge>
                    <Stack>
                      <Typography fontWeight={notificationGroup.total_unseen > 0 ? "fontWeightBold" : "normal"}>
                        {notificationGroup.candidate_name}
                      </Typography>

                      {notificationGroup.total_unseen == 0 ? (
                        <Typography fontSize={'0.75rem'}>
                          No recent activity
                          on <strong>{notificationGroup.request_info.name} ({notificationGroup.request_info.designation})</strong>.
                        </Typography>
                      ) : (
                        <Typography fontSize={'0.75rem'}>
                          There is a new activity in
                          application <strong>{notificationGroup.request_info.name} ({notificationGroup.request_info.designation})</strong>.
                        </Typography>
                      )
                      }
                    </Stack>
                  </Stack>
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    gap={'1rem'}
                  >
                    <IconButton onClick={() => {

                      handleToggle(index);
                    }}>
                      {openRows === index ?
                        <ExpandLessIcon /> : <ExpandMoreIcon />
                      }
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack>
                <Stack sx={{ backgroundColor: '#F6F6F6', alignItems: 'center' }}>
                  <Collapse in={openRows === index} timeout="auto" unmountOnExit
                    sx={{ alignItems: 'center', width: '100%' }}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'} margin={2}>
                      <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                        <Typography>Total</Typography>
                        <Chip label={notificationGroup.total_notifications} variant="outlined" size="small" />
                      </Stack>
                      <Stack flexDirection={'row'} gap={6} alignItems={'center'}>
                        <Typography>Request: <strong
                          style={{ textDecoration: 'underline' }}>{notificationGroup.request_info.name} {notificationGroup.request_info.designation}</strong></Typography>
                        <Button variant="contained" sx={{ color: 'white' }}
                          onClick={() => navigate(notificationGroup.candidate_id, notificationGroup.request_id)}>VIEW
                          APPLICANT PROFILE</Button>
                      </Stack>
                    </Stack>
                    <SubNotifications handleClose={handleClose} notificationGroup={notificationGroup}
                      navigate={navigate} showArrows={false} />
                  </Collapse>
                </Stack>
              </Stack>
            </React.Fragment>
          ))}
          <Stack alignItems={'center'} marginTop={'4rem'} marginBottom={'4rem'}>
            <Pagination count={totalPages} page={page} onChange={handlePageChange} shape="rounded" color="primary" />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}
export default NotificationPage;
