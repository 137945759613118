import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Stack,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Alert,
  AlertTitle,
  Card,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CircleIcon from '@mui/icons-material/Circle';
import axiosInstance from "../../../../../utils/axios";
import { setLimitCapChangeDialog, setNotificationMessage } from "../../../../../utils/redux";
import AddIcon from '@mui/icons-material/Add';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { CreateAgencyProps } from '../Interfaces/AgencyInterfaces';
import { setIsCreateAgencyDialogOpened } from '../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice';

const validationSchema = yup.object().shape({
  name: yup.string().required('Agency Name is required'),
  ucheck_dbs_key: yup.string(),
  team_members: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required('First Name is required'),
      last_name: yup.string().required('Last Name is required'),
      email: yup.string().required('Email Address is required').email('Enter a valid email address'),
    })
  ).min(1, 'At least one compliance officer is required'),
  limits: yup.object().shape({
    rtw: yup.number().required('Required').positive('Limit must be a positive number').typeError('Must be a valid number.'),
    dbs: yup.number().required('Required').positive('Limit must be a positive number').typeError('Must be a valid number.'),
    esign: yup.number().required('Required').positive('Limit must be a positive number').typeError('Must be a valid number.'),
  }),
});

const CreateAgency: React.FC<CreateAgencyProps> = ({ isOpen, onComplete }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setAPIError] = useState<{ message: string; existing_emails?: string[] } | null>(null);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      ucheck_dbs_key: '',
      team_members: [{ first_name: '', last_name: '', email: '' }],
      limits: { rtw: 0, dbs: 0, esign: 0 }
    },
  });

  useEffect(() => {
    if (!isOpen) {
      reset();
      setAPIError(null);
    }
  }, [isOpen, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "team_members",
  });

  const handleAddComplianceOfficer = () => {
    append({ first_name: '', last_name: '', email: '' });
  };

  const handleRemoveComplianceOfficer = (index: number) => {
    remove(index);
  };

  const handleClose = () => {
    reset();
    dispatch(setIsCreateAgencyDialogOpened(false))
  }

  const handleCreateAgency = async (data: any) => {
    setLoading(true);
    setAPIError(null)
    try {
      await axiosInstance.post(`${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies`, data);
      reset();
      onComplete();
      handleClose();
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'success',
          message: 'Agency Created Successfully.',
        })
      );
    } catch (error: any) {
      if (error.response) {
        console.log(error.response)
        if (error.response.status === 409) {
          const errorData = error.response.data?.Error;
          if (errorData) {
            const { message, existing_emails } = errorData;
            setAPIError({ message, existing_emails: existing_emails });
          }
        } else {
          const errorData = error.response.data?.Error || 'Something went wrong while creating agency.';
          dispatch(
            setNotificationMessage({
              display: true,
              severity: 'error',
              message: errorData,
            })
          );
        }
      }
    }
    setLoading(false);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={'lg'} fullWidth>
      <DialogTitle>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontSize={'1.25rem'} fontWeight={500} letterSpacing={'0.00938rem'} lineHeight={'160%'}>
            New Agency
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <Stack flexDirection={{ xs: 'column', md: 'row' }}>
        <Stack gap={{ xs: '1rem', md: '2rem' }} padding={{ xs: '1rem', md: '2rem' }} width={'100%'}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="name"
                label="Agency Name"
                variant="outlined"
                required
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="ucheck_dbs_key"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="ucheck_dbs_key"
                label="DBS Key (optional)"
                variant="outlined"
                fullWidth
              />
            )}
          />
          <Divider />
          <Stack flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography alignItems={'center'} color={'var(--text-secondary, rgba(0, 0, 0, 0.60))'} letterSpacing={'0.00938rem'}>
              Compliance Officers
            </Typography>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={handleAddComplianceOfficer}
              disabled={fields.length >= 5}
            >
              ADD
            </Button>
          </Stack>
          {fields.map((officer, index) => (
            <Stack gap={{ xs: '1rem', md: '2rem' }} key={index}>
              <Stack gap={'0.5rem'} flexDirection={
                {
                  xs: 'column',
                  md: 'row'
                }
              }>
                <Controller
                  name={`team_members[${index}].first_name` as any}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={`first_name_${index}`}
                      label="First Name"
                      variant="outlined"
                      required
                      fullWidth
                      error={!!errors.team_members?.[index]?.first_name}
                      helperText={errors.team_members?.[index]?.first_name?.message}
                    />
                  )}
                />
                <Controller
                  name={`team_members[${index}].last_name` as any}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={`last_name_${index}`}
                      label="Last Name"
                      variant="outlined"
                      required
                      fullWidth
                      error={!!errors.team_members?.[index]?.last_name}
                      helperText={errors.team_members?.[index]?.last_name?.message}
                    />
                  )}
                />
              </Stack>
              <Stack flexDirection={'row'} gap={'1rem'}>
                <Controller
                  name={`team_members[${index}].email` as any}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id={`email_${index}`}
                      label="Email Address"
                      variant="outlined"
                      required
                      fullWidth
                      error={!!errors.team_members?.[index]?.email}
                      helperText={errors.team_members?.[index]?.email?.message}
                    />
                  )}
                />
                {index > 0 && (
                  <Button color="error" onClick={() => handleRemoveComplianceOfficer(index)}>
                    REMOVE
                  </Button>
                )}
              </Stack>
              {fields.length > 1 && (
                <Divider />
              )}
            </Stack>
          ))}
          {apiError && (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <Stack gap={"0.5rem"}>
                {apiError.message}
                {apiError.existing_emails && (
                  <>
                    {apiError.existing_emails.map((email, index) => (
                      <Stack flexDirection={'row'} alignItems={'center'} gap={'0.25rem'} key={index}>
                        <CircleIcon sx={{ fontSize: '0.25rem' }} />
                        <Typography>{email}</Typography>
                      </Stack>
                    ))}
                  </>
                )}
              </Stack>
            </Alert>
          )}
        </Stack>
        <Stack padding={{ xs: '1rem', md: '2rem' }} sx={{ background: '#EFFBFF' }} width={{ xs: '100%', md: '80%' }}>
          <Card style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' }}>
            <Stack padding={'1rem'} gap={'1.5rem'}>
              <Typography color={'#717171'} fontSize={'0.875rem'} fontWeight={500}>Initial Limit Cap</Typography>
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography fontSize={'1rem'} fontWeight={500} lineHeight={'1.5rem'}>Right to Work</Typography>
                <Controller
                  name="limits.rtw"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size='small'
                      variant='outlined'
                      type="number"
                      error={!!errors.limits?.rtw}
                      helperText={errors.limits?.rtw?.message}
                    />
                  )}
                />
              </Stack>
              <Divider />
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography fontSize={'1rem'} fontWeight={500} lineHeight={'1.5rem'}>DBS Check</Typography>
                <Controller
                  name="limits.dbs"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size='small'
                      variant='outlined'
                      type="number"
                      error={!!errors.limits?.dbs}
                      helperText={errors.limits?.dbs?.message}
                    />
                  )}
                />
              </Stack>
              <Divider />
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography fontSize={'1rem'} fontWeight={500} lineHeight={'1.5rem'}>E-Sign</Typography>
                <Controller
                  name="limits.esign"
                  control={control}
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size='small'
                      variant='outlined'
                      type="number"
                      error={!!errors.limits?.esign}
                      helperText={errors.limits?.esign?.message}
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Card>
        </Stack>
      </Stack>
      <Divider />
      <DialogActions>
        <Button
          onClick={handleSubmit(handleCreateAgency)}
          variant="contained"
          style={{ color: 'white' }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : 'ADD NEW AGENCY'}
        </Button>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          Close
        </Button>
      </DialogActions>
    </Dialog >
  );
};

export default CreateAgency;
