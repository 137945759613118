import { FC } from "react";
import {
    Stack,
    Typography,
    Divider,
    Chip,
    Avatar,
    CardContent,
    CardActions,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Card } from "react-bootstrap";
import avatar from "../../../../../utils/Avatar/avatar";
import { AgencySummaryProps } from "../Interfaces/AgencyInterfaces";
import dayjs from "dayjs";

const AgencySummary: FC<AgencySummaryProps> = ({
    agency,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    return (
        <Card style={{
            width: isMobile ? '100%' : '20rem',
        }}>
            <Stack flexDirection={'row'} gap={'0.5rem'} alignItems={'center'} padding={'1rem'}>
                <Avatar {...avatar(agency.agency_name)} />
                <Typography sx={{ fontWeight: 500, fontSize: '1rem' }}>{agency.agency_name}</Typography>
            </Stack>
            <Divider />
            <CardContent>
                <Stack flexDirection={'row'} alignItems={'center'}>
                    <Stack alignItems={'center'} justifyContent={'center'} width={'100%'}>
                        <Stack alignItems={'center'}>
                            <Typography fontWeight={500} fontSize={'1.25rem'} alignItems={'center'}>
                                {agency.agency_request_count}
                            </Typography>
                        </Stack>
                        <Stack alignItems={'center'}>
                            <Typography fontSize={'0.85rem'} color={'rgba(0, 0, 0, 0.60)'} alignItems={'center'}>
                                Total Requests
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack alignItems={'center'} justifyContent={'center'} width={'100%'}>
                        <Stack alignItems={'center'}>
                            <Typography fontWeight={500} fontSize={'1.25rem'} alignItems={'center'}>
                                {agency.agency_candidate_count}
                            </Typography>
                        </Stack>
                        <Stack alignItems={'center'}>
                            <Typography fontSize={'0.85rem'} color={'rgba(0, 0, 0, 0.60)'} alignItems={'center'}>
                                Candidates
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </CardContent>
            <Divider />
            <CardActions>
                <Stack padding={'0rem 1rem'} gap={'0.5rem'}>
                    <Typography fontSize={'1rem'} fontWeight={500}>DBS Key</Typography>
                    <Chip
                        label={agency.agency_dbs_key !== "" ? `${agency.agency_dbs_key}` : "N/A"}
                    />
                </Stack>
            </CardActions>
            <Divider />
            <CardActions>
                <Stack padding={'0rem 1rem'} gap={'0.5rem'}>
                    <Typography fontSize={'1rem'} fontWeight={500}>Created At</Typography>
                    <Chip
                        label={agency.created_at !== null ? `${dayjs(agency.created_at).format('DD MMM YYYY')}` : "N/A"}
                    />
                </Stack>
            </CardActions>
        </Card >
    );
}


export default AgencySummary;