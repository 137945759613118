import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Divider,
  Button,
  Stack,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import axiosInstance from '../../../../../utils/axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetOpenedAgency,
  setIsHistoryDialogOpened,
} from '../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice';
import {
  LimitCapHistoryProps,
  LimitCapHistoryModel,
} from '../Interfaces/AgencyInterfaces';
import LimitCapHistoryTimeline from '../Components/AgencyDetails/LimitCapHistoryTimeline'
import { setNotificationMessage } from '../../../../../utils/redux';

const LimitCapHistory: React.FC<LimitCapHistoryProps> = ({ isOpen }) => {
  const [limitCapHistoryLoading, setLimitCapHistoryLoading] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<LimitCapHistoryModel | null>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const openedAgency = useSelector(GetOpenedAgency);

  const fetchData = async () => {
    try {
      setLimitCapHistoryLoading(true);
      const response = await axiosInstance(
        `${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies/${openedAgency?.agency_id}/limits/history`
      );
      setHistoryData(response.data);
    } catch (error) {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: 'There is an error in fetching limit cap history.',
        })
      )
    } finally {
      setLimitCapHistoryLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleClose = () => {
    dispatch(setIsHistoryDialogOpened(false));
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth={false} fullWidth={isMobile}>
      <DialogTitle>
        <Typography fontSize={'1.25rem'} fontWeight={500} letterSpacing={'0.00938rem'} lineHeight={'160%'}>
          Limit Cap History
        </Typography>
      </DialogTitle>
      <Divider />
      {limitCapHistoryLoading && (
        <Stack alignItems="center" justifyContent="center" minHeight={200} minWidth={{ xs: '100%', md: 600 }}>
          <CircularProgress size={40} />
        </Stack>
      )}
      {!limitCapHistoryLoading && historyData && (
        <Stack gap={{ md: '2rem', sx: '1rem' }} flexDirection={{ xs: 'column', md: 'row' }} padding={'0rem 1.5rem'}>
          <LimitCapHistoryTimeline history={historyData.cap_history.rtw} label="Right to Work" currentLimit={historyData.current_limits.rtw} />
          {isMobile ? <Divider /> : <Divider orientation="vertical" flexItem />}
          <LimitCapHistoryTimeline history={historyData.cap_history.dbs} label="DBS Check" currentLimit={historyData.current_limits.dbs} />
          {isMobile ? <Divider /> : <Divider orientation="vertical" flexItem />}
          <LimitCapHistoryTimeline history={historyData.cap_history.esign} label="E-Sign" currentLimit={historyData.current_limits.esign} />
        </Stack>
      )}
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LimitCapHistory;
