import React, {
  FC,
  Fragment,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, Box, Grid, Stack, TextFieldProps, Typography, Button } from "@mui/material";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Form, { FormDataModel } from "../../../../components/Form";
import { removeErrorFieldsFromValues } from "../../../../utils/validators";
import { identifiers } from "../../../../utils/constants/identifiers";
import { RefereeFormModel } from "./RefereeFormModel";
import PrimaryButton from "../../../../components/Button/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { ReferenceData, setReferenceData } from "../../../../utils/redux";
import { routes } from "../../../../utils/constants/routes";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/lab/DateRangePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/lab";
import InputField from "../../../../components/InputField";
import { useTour } from "@reactour/tour";
import { tourStep } from "../../../../utils/constants/coachMarks";
import { isTourCompleted, markTourAsCompleted } from "../../../../utils/storage/tours";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FileCopy, InfoOutlined, RemoveRedEye } from "@mui/icons-material";
import RefereeDocumentTable from "./RefereeDocumentTable";
import FromDevice
  from "../../../Authenticated/Candidate/ViewRequest/VettingDocuments/DocumentList/common/UploadFiles/FromDevice";
import RefereeUploadDocument from "./RefereeUploadDocument";

const RefereeDetails: FC = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { type } = useParams() as any;
  let RefereeForm: RefObject<Form | null | undefined> = useRef();
  const { setIsOpen, currentStep, setCurrentStep } = useTour();

  const reference = useSelector(ReferenceData);

  const [hasError, setHasError] = useState<boolean>(false);
  const [buttonActive, setButtonActive] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);
  const [isRefereeUpload, setIsRefereeUpload] = useState<boolean>(false);
  const [isRefereeUploading, setIsRefereeUploading] = useState<boolean>(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    // Check if 'ref_doc' query parameter is set to 'true'
    const refDocIsTrue = searchParams.get('ref_doc') === 'true';
    setIsRefereeUpload(refDocIsTrue);
  }, [location.search]);

  useEffect(() => {
    if (!reference.referee_details) {
      setCurrentStep(tourStep.referee_application_details.index);
    }
  }, [reference, setCurrentStep]);

  useEffect(() => {
    if (tourStep.referee_application_details.index === currentStep) {
      if (!isTourCompleted('referee_application_details')) {
        setIsOpen(true);
      }
    } else if (
      currentStep ===
      tourStep.referee_application_details.index +
      tourStep.referee_application_details.steps
    ) {
      setIsOpen(false);
      markTourAsCompleted('referee_application_details');
      dispatch(
        setReferenceData({ ...reference, referee_application_details: true })
      );
      //setCoachMarksValue()
    }
  }, [currentStep, dispatch, reference, setIsOpen]);

  useEffect(() => {
    if (reference.referee_details?.candidate_employment_date) {
      const { from, to } = reference.referee_details
        ?.candidate_employment_date as {
          from: string;
          to: string;
        };
      setDateRange([!!from ? dayjs(from) : null, !!to ? dayjs(to) : null]);
    }
  }, [reference.referee_details]);

  const proceed = () => {
    const { getFormData } = RefereeForm.current as {
      getFormData: () => { formData: FormDataModel; isFormValid: boolean };
    };
    const { formData, isFormValid } = getFormData();
    const body: FormDataModel = removeErrorFieldsFromValues(formData);
    if (isFormValid) {
      const active_index = reference.active_index;
      if (type === "character") {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
            },
            active_index: active_index + 1,
          })
        );
      } else if (type === "employment-gap") {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
              comments: reference.referee_details?.comments,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
            },
            active_index: active_index + 1,
          })
        );
      } else {
        dispatch(
          setReferenceData({
            ...reference,
            referee_details: {
              ...body,
              candidate_employment_date: {
                from: dateRange[0]?.format(),
                to: dateRange[1]?.format(),
              },
            },
            active_index: active_index + 1,
          })
        );
      }
      Navigate(`../${routes[reference.available_routes[active_index + 1]]}`);
    } else {
      setHasError(true);
    }
  };

  const refereeUploadCompleteHandler = (key: string) => {
    setIsRefereeUploading(false)
    const available_routes: string[] = reference.available_routes.filter(
      (route) => route !== identifiers.ked_questionnaire
    );
    dispatch(
      setReferenceData({
        ...reference,
        referee_uploaded_doc_key: key,
        available_routes
      }));
  }
  const refereeOnUploadHandler = () => {
    setIsRefereeUploading(true)
  }
  const handleRemoveRefDocument = () => {
    const available_routes: string[] = [
      reference.available_routes[0],
      identifiers.ked_questionnaire as string,
      ...reference.available_routes.slice(1),
    ];
    dispatch(
      setReferenceData({
        ...reference,
        referee_uploaded_doc_key: '',
        available_routes
      }));
  }
  const refereeDeclineHandler = async (have_document: boolean) => {
    if (have_document) {
      await dispatch(
        setReferenceData({
          ...reference,
          is_referee_declined_doc: true
        }));
      Navigate(`../${routes.referee_details}?ref_doc=true`);
    } else {
      const available_routes: string[] = [
        reference.available_routes[0],
        identifiers.ked_questionnaire as string,
        ...reference.available_routes.slice(1),
      ];
      await dispatch(
        setReferenceData({
          ...reference,
          available_routes,
          is_referee_declined_doc: true
        }));
    }
  }

  return (
    <Stack padding={{
      xs: '1rem',
      md: '2rem 5rem 2rem 5rem'
    }}>

      <Grid
        container
        display={"flex"}
        justifyContent={"center"}
        marginTop={'2rem'}
      >
        <Grid
          item
          xs={12}
          lg={8}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ mb: 2 }}>
              <>
                <Stack
                  direction={"row"}
                  alignItems="center"
                  sx={{
                    // background: '#FFFAE7',
                    background: '#FFDEDE',
                    color: "black",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                    padding: "10px",
                    border: "1px dashed white",
                    flex: 1,
                  }}
                  gap={'1rem'}
                >
                  <WarningAmberIcon sx={{ color: "red" }} />
                  <Typography color={'red'} fontSize={'1.25rem'}>
                    Verify {type === 'employment' ? 'Employment' : ''} Details Before Proceeding
                  </Typography>
                </Stack>
                <Box sx={{
                  background: "#fff",
                  margin: "0",
                  padding: "1rem",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                  boxShadow: "0px 2px 4px #d9d9d9",
                  fontSize: "13px"
                }}
                >
                  <Typography>Please ensure that the {type === 'employment' ? 'employment' : ''} period is accurate before
                    proceeding. Changes cannot be made later.<br /> Thank you for your attention.</Typography>
                </Box>
              </>
            </Grid>
            <Grid item xs={12}>
              <Typography
                data-tut="apllication_details_page"
                variant="h6"
                fontWeight={"500"}
                fontSize={"14px"}
                sx={{ mb: 2 }}
              >
                Referee Details
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Form
              values={reference.referee_details}
              hasError={hasError}
              fieldError={identifiers.field_error as string}
              ref={RefereeForm as RefObject<Form>}
              model={RefereeFormModel(reference.type)}
              onChange={(field, value, formData, isFormValid) =>
                setButtonActive(isFormValid)
              }
            />
            {reference.type !== identifiers.character && (
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateRangePicker
                    open={open}
                    disabled={reference.type === identifiers.gap}
                    onClose={() => setOpen(false)}
                    startText={
                      reference.type === identifiers.academic
                        ? "Course start date"
                        : reference.type === identifiers.gap
                          ? "Gap from"
                          : "Employment from"
                    }
                    endText={
                      reference.type === identifiers.academic
                        ? "Course end date"
                        : reference.type === identifiers.gap
                          ? "Gap to"
                          : "Employment to"
                    }
                    calendars={2}
                    value={dateRange}
                    onChange={(newValue: any) => {
                      setDateRange(newValue);
                    }}
                    inputFormat={identifiers.date_format as string}
                    renderInput={(
                      startProps: TextFieldProps,
                      endProps: TextFieldProps
                    ) => (
                      <Fragment>
                        <InputField
                          {...startProps}
                          disabled={reference.type === identifiers.gap}
                          size={"small"}
                          autoComplete="off"
                          sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                          onClick={() => {
                            if (reference.type !== identifiers.gap) {
                              setOpen(true);
                            }
                          }}
                          error={!dateRange[0]}
                          helperText={!dateRange[0] ? 'Start date is required' : ''}
                        />

                        <Box sx={{ mx: 2 }}> to </Box>

                        <InputField
                          {...endProps}
                          size={"small"}
                          autoComplete="off"
                          sx={{ background: "#FFFFFF", borderRadius: "8px" }}
                          onClick={() => {
                            if (reference.type !== identifiers.gap) {
                              setOpen(true);
                            }
                          }}
                          error={!dateRange[1]}
                          helperText={!dateRange[1] ? 'End date is required' : ''}
                          disabled={reference.type === identifiers.gap}
                        />
                      </Fragment>
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {reference.type === identifiers.gap &&
              reference?.is_gap_reference && (
                <Grid item xs={12} md={12}>
                  {/* {isGapReference===true && */}
                  <InputField
                    label={"Reason Of Gap in Employment"}
                    multiline
                    value={reference?.referee_details?.comments}
                    // textChange={(e) => {
                    //   console.log(e,);
                    // setComment(e);
                    // }}
                    disabled={true}
                    rows={6}
                    sx={{ background: "#ffffff" }}
                    placeholder={
                      "The applicant may email once they have fulfilled the requirement of this request."
                    }
                  />
                </Grid>
              )}
          </Grid>


          {reference && reference.referee_document_key && !isRefereeUpload &&
            <Stack sx={{ marginTop: '1rem' }}>
              <RefereeDocumentTable
                document_type={'candidate_upload'}
                document_key={reference.referee_document_key}
                showDecline={true}
                reference_id={reference.id}
                onDecline={refereeDeclineHandler}
                isDeclined={reference.is_referee_declined_doc}
                showRefereeDocUploadButton={!reference.referee_uploaded_doc_key}
              />
            </Stack>
          }
          {isRefereeUpload && !reference.referee_uploaded_doc_key &&
            <RefereeUploadDocument
              reference_id={reference.id}
              onComplete={refereeUploadCompleteHandler}
              onLoading={refereeOnUploadHandler}
            />
          }

          {reference && reference.referee_uploaded_doc_key &&
            <Stack sx={{ marginTop: '1rem' }}>
              <RefereeDocumentTable
                document_key={reference.referee_uploaded_doc_key}
                showDelete={true}
                reference_id={reference.id}
                document_type={'ref_upload'}
                onRemoveFile={handleRemoveRefDocument}
              />
            </Stack>
          }

          {!isRefereeUpload && !reference.referee_document_key && !reference.referee_uploaded_doc_key &&
            <Stack sx={{
              padding: '1rem',
              borderRadius: '0.5rem',
              background: '#dfffd2',
              gap: '1rem',
              marginTop: '1rem',
              border: '1px solid #79b162'
            }}>
              <Stack direction={"row"} gap={"0.5rem"}>
                <InfoOutlined sx={{ color: '#4a8d2e' }} />
                <Typography
                  variant="h6"
                  fontWeight={"400"}
                  fontSize={"14px"}
                >
                  Should you possess a reference file for this applicant already, kindly click below button to upload
                  it.
                </Typography>
              </Stack>
              <Button color={"primary"}
                startIcon={<FileCopy />}
                sx={{
                  border: '1px solid #79b162',
                  background: '#fff',
                  color: '#000',
                  width: 'fit-content',
                  paddingLeft: '1rem',
                  paddingRight: '1rem'
                }}
                onClick={() => Navigate(`../${routes.referee_details}?ref_doc=true`)}>
                Upload Reference File
              </Button>

            </Stack>
          }

          <Grid container spacing={2}>
            <Grid
              item
              textAlign={"center"}
              xs={12}
              sx={{
                position: { xs: "fixed", sm: "fixed", md: "relative" },
                bottom: "0px",
                left: "0px",
                width: "100%",
                zIndex: "99",
                textAlign: { xs: "center", md: "center" },
                background: { xs: "white", md: "transparent" },
                boxShadow: { xs: "0px -4px 7px #e6e6e6", md: "none" },
                borderRadius: { xs: "15px 15px 0px 0px", md: "none" },
                py: 1,
                px: 3,
                mt: "20px",
              }}
            >
              <PrimaryButton
                fullWidth={false}
                sx={{
                  background: "#FFFFFF",
                  border: " 1px solid #5AB9F9",
                  borderRadius: "5px",
                  padding: "4px 44px",
                  color: "#5AB9F9",
                  fontSize: "14px",
                  margin: "0px 20px",
                }}
                disabled={isRefereeUploading}
                onClick={() => {
                  const active_index = reference.active_index;
                  dispatch(
                    setReferenceData({
                      ...reference,
                      active_index: active_index - 1,
                    })
                  );
                  Navigate("../");
                }}
              >
                Go Back
              </PrimaryButton>
              <PrimaryButton
                fullWidth={false}
                sx={{
                  background: "#5AB9F9",
                  boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
                  borderRadius: "5px",
                  padding: "4px 30px",
                  color: "white",
                }}
                disabled={
                  !buttonActive ||
                  (type !== identifiers.character &&
                    (!dateRange[0] || !dateRange[1])) ||
                  isRefereeUploading ||
                  (isRefereeUpload && !reference.referee_uploaded_doc_key)
                }
                onClick={() => proceed()}
                data-tut="details_proceed_btn"
              >
                Proceed
              </PrimaryButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default RefereeDetails;