import React, { useState } from "react";
import {
  CircularProgress,
  IconButton,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from '@mui/icons-material/Add';
import InputField from "../../../../../components/InputField";
import PrimaryButton from "../../../../../components/Button/PrimaryButton";
import { AgencyHeaderProps } from "../Interfaces/AgencyInterfaces";
import TableDatePickerForm from "../Forms/TableDatePickerForm";
import { useDispatch } from "react-redux";
import DownloadIcon from '@mui/icons-material/Download';
import { setIsCreateAgencyDialogOpened } from "../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";

const AgencyHeader: React.FC<AgencyHeaderProps> = ({
  onSearch,
  searchQuery,
  onApplyFilter,
  onExcelExport,
  isExporting
}) => {
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    onSearch(query);
  };

  const dispatch = useDispatch();
  const handleOpenCreateAgencyDialog = () => {
    dispatch(setIsCreateAgencyDialogOpened(true))
  }

  const handleExportClick = async () => {
    try {
      onExcelExport();
    } catch (error) {
      console.error("Export failed", error);
    }
  };

  return (
    <Stack
      padding={{ xs: '0.5rem', md: '1rem' }}
      gap={'0.5rem'}
      flexDirection={{
        XS: 'column',
        md: 'row'
      }}
      justifyContent={'space-between'}>
      <InputField
        sx={{ alignItems: 'center', width: '18.75rem' }}
        placeholder="Search by agency name"
        InputProps={{ endAdornment: <SearchIcon /> }}
        value={searchQuery}
        onChange={handleSearchChange}
      />
      <Stack flexDirection={'row'} gap={'1rem'} justifyContent={{ xs: 'space-between' }} alignItems={'center'}>

        <TableDatePickerForm onApplyFilter={onApplyFilter} />
        <PrimaryButton
          fullWidth={false}
          sx={{
            background: "#5AB9F9",
            padding: '1rem 1.375rem',
            color: "#ffffff",
            fontSize: 16,
            fontWeight: 500,
            flexWrap: 'wrap',
            boxShadow: "0px 10px 25px rgba(90, 185, 249, 0.25)",
          }}
          startIcon={<AddIcon />}
          onClick={handleOpenCreateAgencyDialog}
        >
          CREATE NEW AGENCY
        </PrimaryButton>
        <IconButton color="primary" onClick={handleExportClick} disabled={isExporting}>
          {isExporting ? (
            <CircularProgress size={24}/>
          ) : (
            <DownloadIcon />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default AgencyHeader;
