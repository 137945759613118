import store from './store';
import {
  setAuthentication,
  logout,
  Authenticated,
  ProfileComplete,
  UserRole,
  setUserData
} from './reducer/authentication-slice';
import {
  setRequest,
  setAdditionalForms,
  setVettingDocuments,
  setIsEditable,
  setReference,
  setAdditionalFormData,
  RequestId,
  VettingDocuments,
  AdditionalForms,
  IsEditable,
  Reference,
  setComment,
  Comment,
  AdditionalFormData,
  setVettingDocumentList,
  VettingDocumentList,
  setCandidateIDV,
  CandidateIDV,
  initialReferenceState,
  setPreviewReference,
  PreviewReference,
} from './reducer/request-slice';
import {
  setValue,
  setName,
  setReload,
  setReloading,
  setRequestData,
  DocumentName,
  Reload,
  Reloading,
  RoutingPageValue,
  setAcademicQualificationData,
  AcademicQualificationData,
  AcademicQualificationIds,
  AcademicData,
  setCandidateProfileAcademicData,
  setCandidateProfileReload,
  CandidateProfileReload,
  setCandidateVettingDocActiveIndex,
  CandidateVettingDocActiveIndex,
} from './reducer/candidate-vetting-slice';
import {
  NotificationDisplay,
  setNotificationMessage,
  NotificationSeverity,
  NotificationMessage,
} from './reducer/Notification-details-slice';
import { ReferenceData, setReferenceData } from './reducer/reference-slice';
import {
  UCheckPersonalInformation,
  setPersonalInformation,
  setPersonalHistory,
  UCheckPersonalHistory,
  setUCheckFormID,
  UCheckFormID,
  uCheckInitialState,
  setCurrentAddress,
  setPreviousAddress,
  UCheckCurrentAddress,
  UCheckPreviousAddresses,
  setPassPortInformation,
  setLicenceInformation,
  UCheckPassportInformation,
  UCheckDrivingLicence,
  setUserInformation,
  UserInformationData,
  setStatus,
  uCheckStatus
} from './reducer/u-check-slice';

// Admin Store
import {
  setLimitCapChangeDialog,
  IsLimitCapChangeDialogOpened,
  IsCreateAgencyDialogOpened,
  IsAddComplianceOfficerDialogOpened,
  IsEditAgencyDialogOpened,
  setEditAgencyData,
  setFilteredDate
} from "./reducer/admin-reducers/admin-agency-slice"

import {
  isNewNotification,
  isTabNotification,
  getTotalNewNotificationsCount,
  setWebSocketData
} from './reducer/websocket-slice';

export default store;

export {
  setAuthentication,
  logout,
  Authenticated,
  ProfileComplete,
  UserRole,
  setRequest,
  setValue,
  setName,
  setReload,
  setReloading,
  setRequestData,
  setAdditionalForms,
  setVettingDocuments,
  setIsEditable,
  setReference,
  RequestId,
  VettingDocuments,
  AdditionalForms,
  IsEditable,
  Reference,
  NotificationDisplay,
  NotificationSeverity,
  NotificationMessage,
  setNotificationMessage,
  setComment,
  Comment,
  setReferenceData,
  ReferenceData,
  setAdditionalFormData,
  AdditionalFormData,
  Reload,
  Reloading,
  RoutingPageValue,
  setVettingDocumentList,
  VettingDocumentList,
  setCandidateIDV,
  CandidateIDV,
  AcademicQualificationData,
  setAcademicQualificationData,
  AcademicQualificationIds,
  AcademicData,
  setCandidateProfileAcademicData,
  setCandidateProfileReload,
  CandidateProfileReload,
  setCandidateVettingDocActiveIndex,
  CandidateVettingDocActiveIndex,
  setPreviewReference,
  PreviewReference,
  DocumentName,
  setUCheckFormID,
  setPersonalInformation,
  setPersonalHistory,
  setCurrentAddress,
  setPreviousAddress,
  UCheckFormID,
  UCheckPersonalInformation,
  UCheckPersonalHistory,
  UCheckCurrentAddress,
  UCheckPreviousAddresses,
  initialReferenceState,
  uCheckInitialState,
  setPassPortInformation,
  setLicenceInformation,
  UCheckPassportInformation,
  UCheckDrivingLicence,
  setUserInformation,
  UserInformationData,
  setStatus,
  uCheckStatus,
  setUserData,
  setLimitCapChangeDialog,
  IsLimitCapChangeDialogOpened,
  IsCreateAgencyDialogOpened,
  IsAddComplianceOfficerDialogOpened,
  IsEditAgencyDialogOpened,
  setEditAgencyData,
  setFilteredDate,
  isNewNotification,
  isTabNotification,
  getTotalNewNotificationsCount,
  setWebSocketData
};
