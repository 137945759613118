import React from 'react';
import {
  Stack,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import AgencyListRow from './AgencyListRow';
import { AgencyListProps } from '../Interfaces/AgencyInterfaces';
import { images } from '../../../../../utils/constants/images';

const AgencyList: React.FC<AgencyListProps> = ({
  searchQuery,
  rows,
  openRows,
  rowsPerPage,
  page,
  onToggle,
  onChangePage,
  onServiceRefresh,
  isLoading,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack alignItems={'center'}>
      <>
        <Box sx={{ width: '100%' }}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell>Agency Name</TableCell>
                  {!isMobile &&
                    <>
                      <TableCell>Compliance Officers</TableCell>
                      <TableCell>Total Requests</TableCell>
                      <TableCell>Total Candidates</TableCell>
                      <TableCell>DBS Key</TableCell>
                    </>
                  }
                  <TableCell sx={{ textAlign: 'right' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading &&
                  <TableCell colSpan={6}>
                    <CircularProgress size={"1.25rem"} />
                  </TableCell>
                }
                {!isLoading && rows.length > 0 && rows
                  .filter((row) => !searchQuery || row.agency_name.toLowerCase().includes(searchQuery.toLowerCase()))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <AgencyListRow
                      row={row}
                      key={index}
                      isOpen={row.agency_id === openRows}
                      onToggle={() => onToggle(row.agency_id)}
                      onServiceRefresh={onServiceRefresh}
                    />
                  ))}

                {!isLoading && rows.length === 0 &&
                  <TableCell colSpan={6}>
                    <Stack alignItems={"center"}>
                      <img
                        src={images.emptyIcon}
                        alt="no request"
                        className="vertical-align-middle empty-image mb-2"
                      />
                      <Typography fontSize="1rem" fontWeight={500} color="#61677A">
                        No Agencies Found
                      </Typography>
                    </Stack>
                  </TableCell>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            rowsPerPageOptions={[rowsPerPage]}
            labelRowsPerPage={''}
          />
        </Box>
      </>
    </Stack>
  );
};

export default AgencyList;
