import { FC, useState } from 'react';
import { Divider, Stack, Typography } from "@mui/material";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import ServiceInfoDialog from './ServiceInfoDialog/ServiceInfoDialog';

interface InnerDetailItemProps {
  label: string;
  value: number;
}

interface OuterDetailItemProps {
  label?: string;
  value?: number;
  count: InnerDetailItemProps[];
  
}

interface ServiceCategoryProps {
  label: string;
  count?: number;
  details: OuterDetailItemProps[];
}

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const tabItems = [
  {
    index: 0,
    label: "RTW Services",
    childLabels: [
      { label: "On Going", value: "ONGOING" },
      { label: "Submitted", value: "SUBMITTED" },
      { label: "Success", value: "SUCCESS" },
      { label: "Rejected", value: "REJECTED" }
    ]
  },
  {
    index: 1,
    label: "DBS Services",
    childLabels: [
      { label: "Submitted", value: "SUBMITTED" },
      { label: "In Review", value: "IN_REVIEW" },
      { label: "Approved", value: "APPROVED" }
    ]
  },
  {
    index: 2,
    label: "E-Sign Services",
    childLabels: [
      { label: "Created", value: "CREATED" },
      { label: "Signed", value: "SIGNED" },
      { label: "Rejected", value: "REJECTED" }
    ]
  },
  {
    index: 3,
    label: "References",
    childLabels: [
      { label: "Total Applicants", value: "TOTAL_APPLICANTS" },
      { label: "Total References", value: "TOTAL_REFERENCES" }

    ]
  },
  {
    index: 4,
    label: "Documents",
    childLabels: [
      { label: "Total Applicants", value: "TOTAL_APPLICANTS" },
      { label: "Total Documents", value: "TOTAL_DOCUMENTS" }

    ]
  }
];

const renderInnerDetails = (
  innerDetails: InnerDetailItemProps[],
  handleClickOpen: (parentLabel: string, childLabel: string) => void,
  tabName: string
) => (
  innerDetails.map((innerItem, innerIndex) => (
    <Stack key={innerIndex} flexDirection="row" gap="1rem" alignItems="center">
      <CircleRoundedIcon sx={{ height: '0.25rem', width: '0.25rem', color: getRandomColor() }} />
      <Typography color="#5E5E5E" fontSize="0.875rem">{innerItem.label}</Typography>
      <Divider sx={{ borderStyle: 'dotted', flex: '1 1 auto' }} />
      <Typography
        color="#5E5E5E"
        fontWeight={500}
        fontSize="1rem"
        onClick={() => handleClickOpen(tabName, innerItem.label)}
        sx={{ cursor: 'pointer' }}
      >
        {innerItem.value}
      </Typography>
    </Stack>
  ))
);

const ServiceCategory: FC<ServiceCategoryProps> = ({
  label,
  count,
  details
}) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeFilterTab, setActiveFilterTab] = useState('');

  const handleClickOpen = (parentLabel: string, childLabel: string) => {
    const selectedTab = tabItems.find((item) => item.label === parentLabel);
    if (selectedTab) {
      setActiveTab(selectedTab.index);
      const childTab = selectedTab.childLabels.find((item) => item.label === childLabel);
      if (childTab) {
        setActiveFilterTab(childTab.value);
      } else {
        setActiveFilterTab('')
      }
    }
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Stack gap="1.5rem" width="100%">
      <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography
          color="#5E5E5E"
          fontWeight={700}
          fontSize="0.875rem"
          onClick={() => handleClickOpen(label, '')}
          sx={{ cursor: 'pointer' }}
        >
          {label}
        </Typography>
        {count !== undefined && (
          <Typography
            color="#5E5E5E"
            fontWeight={700}
            fontSize="1rem"
            onClick={() => handleClickOpen(label, '')}
            sx={{ cursor: 'pointer' }}
          >
            {count}
          </Typography>
        )}
      </Stack>
      {details.map((outerItem) => (
        <Stack gap="0.5rem" key={outerItem.label}>
          {(outerItem.label || outerItem.value) && (
            <Stack flexDirection="row" gap="1rem" alignItems="center">
              <Typography color="#5E5E5E" fontSize="0.875rem">{outerItem.label}</Typography>
              <Divider sx={{ borderStyle: 'dotted', flex: '1 1 auto' }} />
              <Typography
                color="#5E5E5E"
                fontWeight={500}
                fontSize="1rem"
                onClick={() => handleClickOpen(label, outerItem.label || '')}
                sx={{ cursor: 'pointer' }}
              >
                {outerItem.value}
              </Typography>
            </Stack>
          )}
          {renderInnerDetails(outerItem.count, handleClickOpen, label)}
        </Stack>
      ))}
      {open && (
        <ServiceInfoDialog
          open={open}
          onClose={handleClose}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          activeFilterTab={activeFilterTab}
          setActiveFilterTab={setActiveFilterTab}
        />
      )}
    </Stack>
  );
};

export default ServiceCategory;
