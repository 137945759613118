import React, {FC, useEffect, useMemo, useState} from "react";
import {
  Box,
  Grid,
  Typography,
  TableCell,
  styled,
  tableCellClasses,
  TableRow,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableBody,
  Divider,
  Button, CircularProgress,
} from "@mui/material";
import {Stack} from "@mui/system";
import {AccordionSummaryProps, AccordionProps} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Checks from "./Checks";
import Tasks from "./Task";
import {images} from "../../../../../../utils/constants/images";
import axiosInstance from "../../../../../../utils/axios";
import {URLS} from "../../../../../../utils/constants/urls";
import {setNotificationMessage} from "../../../../../../utils/redux";
import {useDispatch} from "react-redux";
import PageLoader from "../../../../../../components/PageLoader";

import {Document, Page} from "react-pdf";
import Modal from "../../../../../../components/modal/Modal";
import {ReportOutlined, WarningAmberOutlined} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import FailedCases from "./FailChecks/FailedCases";

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ExpandMoreIcon/>} {...props} />
))(() => ({
  padding: "0px 5px 0px 0px",
  height: "57px",
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    {...props}
    disableGutters
    elevation={0}
    square
    children={props.children}
  />
))(() => ({
  border: 0,
  marginBottom: "40px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "none",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  margin: "20px 0px",
}));

const YotiResult: FC<{
  yotiReport: {
    check_result: any;
    documents: any[];
    status: string,
    identity_profile_status: string,
    id: string };
}> = ({yotiReport}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState<"pdf" | "image">();

  const [documentUrl, setDocumentUrl] = useState<string>();

  const [viewDocument, setViewDocument] = useState(false);

  const [numPages, setNumPages] = useState<number | null>(null);

  const [failChecks, setFailChecks] = useState<any>([])
  const [isFailChecking, setIsFailChecking] = useState<boolean>(false);

  const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      color: "#727272",
      border: "none",
      borderBottom: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}`]: {
      color: "#263238",
      fontSize: "14px",
      fontWeight: 500,
      padding: "5px 16px",
    },
    [`&.${tableCellClasses.head}:first-of-type`]: {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [`&.${tableCellClasses.head}:last-of-type`]: {
      width: "14rem",
      textAlign: "start",
      paddingLeft: "35px",
    },
    [`&.${tableCellClasses.body}:first-of-type`]: {
      borderBottom: "1px solid #DADADA",
    },
    [`&.${tableCellClasses.body}:last-of-type`]: {
      borderTopRightRadius: "12px",
      borderBottomRightRadius: "12px",
      textAlign: "center",
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&": {
      borderBottom: "1px solid gray",
      backgroundColor: "white",
    },
  }));

  useEffect(() => {
    const getYotiFailCheck = async () => {
      setIsFailChecking(true);
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/yoti/checks/${yotiReport?.id}`
      );
      setFailChecks(response.data);
      setIsFailChecking(false)
    }
    if (yotiReport?.id) {
      getYotiFailCheck();
    }
  }, [yotiReport])

  const download = async (id: string, view: boolean) => {
    setLoading(true);
    try {
      const {data} = await axiosInstance.get(`${URLS.document}/${id}`);

      const type =
        data.mimetype.indexOf("pdf") > -1
          ? "pdf"
          : data.mimetype.indexOf("image") > -1
            ? "image"
            : null;
      if (!!type && view) {
        setDocumentType(type);
        setDocumentUrl(data.url);
        setViewDocument(true);
      } else {
        window.open(data.url, "_blank");
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in downloading document",
        })
      );
    }
  };

  // yoti check labels
  const checkLabels: { [key: string]: string } = {
    ID_DOCUMENT_AUTHENTICITY: "Authenticity Checks",
    ID_DOCUMENT_TEXT_DATA_CHECK: "Text Data Checks",
    LIVENESS: "Liveness Checks",
    ID_DOCUMENT_FACE_MATCH: "Face Match Checks",
    THIRD_PARTY_IDENTITY: "Third Party Identity Checks",
    THIRD_PARTY_IDENTITY_FRAUD_1: "Third Party Identity Fraud Checks",
  };

  const viewModalText = useMemo(() => {
    return (
      <Grid container>
        <Grid item>
          {documentType === "pdf" && (
            <Document
              file={{url: documentUrl}}
              onLoadSuccess={({numPages}) => setNumPages(numPages)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1}/>
              ))}
            </Document>
          )}
          {documentType === "image" && (
            <img src={documentUrl} alt={"document"} className={"img-fluid"}/>
          )}
        </Grid>
      </Grid>
    );
  }, [documentType, documentUrl, numPages]);
  const openViewModal = useMemo(() => {
    return (
      <Modal
        open={viewDocument}
        setModalClose={setViewDocument}
        children={viewModalText}
        title={undefined}
        size="sm"
        className={undefined}
      />
    );
  }, [viewDocument, viewModalText]);

  return (
    <>
      {loading && <PageLoader/>}
      {openViewModal}

      <Grid
        container
        className="mb-3"
        display={"flex"}
        justifyContent={"center"}
        sx={{maxHeight: "600px", overflowY: isFailChecking ? "hidden" : "scroll", px: 2, position: 'relative'}}
      >
        {isFailChecking &&
          <Stack
            position={"absolute"}
            left={0}
            right={0}
            bottom={0}
            alignItems={"center"}
            justifyContent={"center"}
            top={0}
            sx={{ background: 'rgba(255, 255, 255, 0.8)', backgroundFilter: 'blur(4px)', zIndex: 2 }}>
            <CircularProgress size={20} />
          </Stack>
        }
        <Grid item xs={12} md={12} sx={{mb: 2}}>
          <TableContainer
            component={Paper}
            sx={{boxShadow: "none", width: "100%"}}
          >
            <Table
              sx={{
                minWidth: 400,
                borderCollapse: "separate",
              }}
            >
              <StyledTableRow>
                <StyledTableCell variant="head">State</StyledTableCell>
                <StyledTableCell>{yotiReport?.identity_profile_status.replace(/_/g, ' ')}</StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell variant="head">
                  User Tracking ID
                </StyledTableCell>
                <StyledTableCell>
                  {yotiReport?.check_result?.tracking_id}
                </StyledTableCell>
              </StyledTableRow>
            </Table>
          </TableContainer>
        </Grid>

        {
          failChecks && failChecks.aborted && failChecks.aborted.length > 0 &&
          <FailedCases
            checks={failChecks.aborted}
            subChecks={failChecks.checks}
            type={"ABORTED"}
            id={yotiReport.id}
          />
        }
        {
          failChecks && failChecks.requirement_not_met && failChecks.requirement_not_met.length > 0 &&
          <FailedCases
            checks={failChecks.requirement_not_met}
            subChecks={failChecks.checks}
            type={"REQUIREMENT_NOT_MET"}
            id={yotiReport.id}
          />
        }

        <Grid item xs={12} md={12}>

          <Stack sx={{
            background: '#fff',
            borderRadius: '0.5rem',
            boxShadow: '0 0 10px #eee',
          }}>
            <Typography variant="h6" sx={{padding: '0.5rem 1rem', borderBottom: '1px solid #ddd'}}>Checks</Typography>
            <Stack direction={"column"} sx={{border: "none"}}>
              {yotiReport?.check_result?.checks?.map((check: any) => (
                <Accordion sx={{background: "white", marginBottom: "0px"}}>
                  <AccordionSummary
                    sx={{
                      borderBottom: "1px solid #ddd",
                      background: "#fff",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#555",
                          fontWeight: 500,
                          fontSize: "16px",
                          m: 2,
                        }}
                      >
                        {checkLabels[check.type] || ""}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Checks check={check}/>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Stack>


        </Grid>
        <Grid xs={12} md={12} mt={3}>

          <Typography variant="h6" sx={{padding: '0.5rem 0', borderBottom: '1px solid #ddd'}}>ID
            Documents</Typography>
          <Stack gap={"1rem"}>
            {yotiReport?.documents?.map((document) => (
              <Stack sx={{
                background: '#fff',
                borderRadius: '0.5rem',
                boxShadow: '0 0 10px #eee',
              }}>
                <Typography variant="h6" sx={{
                  padding: '0.5rem 1rem',
                  fontSize: '1rem',
                  borderBottom: '1px solid #ddd',
                  fontWeight: 'bold'
                }}>{document.document}</Typography>
                <Stack
                  direction={"column"}
                  sx={{border: "none"}}
                >
                  <Accordion sx={{background: "white", marginBottom: "0px"}}>
                    <AccordionSummary
                      sx={{
                        borderBottom: "1px solid #ddd",
                        background: "#fff",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#555",
                            fontWeight: 500,
                            fontSize: "16px",
                            m: 2,
                          }}
                        >
                          Text Extraction Tasks
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{background: "white"}}>
                      <Tasks fields={document?.fields}/>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={{background: "white", marginBottom: "0px"}}>
                    <AccordionSummary
                      sx={{
                        borderBottom: "1px solid #ddd",
                        background: "#fff",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#555",
                            fontWeight: 500,
                            fontSize: "16px",
                            m: 2,
                          }}
                        >
                          Pages
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails sx={{background: "white"}}>
                      <TableContainer
                        component={Paper}
                        sx={{boxShadow: "none", width: "100%"}}
                      >
                        <Table
                          sx={{
                            minWidth: 400,
                            borderSpacing: "0 1rem",
                            borderCollapse: "separate",
                            backgroundColor: "#f5fbff",
                          }}
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell sx={{minWidth: "150px"}}>
                                Page Name
                              </StyledTableCell>
                              <StyledTableCell>Action</StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={"documents-table-body"}>
                            {document?.pages?.map(
                              (page: string, index: number) => (
                                <>
                                  <StyledTableRow
                                    className={"documents-table-body-row"}
                                    key={index}
                                  >
                                    <StyledTableCell>
                                      Page {index + 1}
                                    </StyledTableCell>

                                    <StyledTableCell>
                                      <Grid
                                        item
                                        display={"flex"}
                                        flexWrap={"nowrap"}
                                        md={6}
                                      >
                                        <Button
                                          onClick={() => download(page, false)}
                                          sx={{
                                            border: "none",
                                            color: "#727272",
                                            fontSize: 16,
                                            fontWeight: 500,
                                            mr: 2,
                                            mt: 1,
                                            p: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={images.downloadIcon}
                                            alt="download"
                                          />
                                        </Button>
                                        <Button
                                          onClick={() => download(page, true)}
                                          sx={{
                                            border: "none",
                                            color: "#727272",
                                            fontSize: 16,
                                            fontWeight: 500,
                                            mr: 2,
                                            mt: 1,
                                            p: "10px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <img
                                            src={images.viewIcon}
                                            alt="view"
                                            className="action-icon"
                                          />
                                        </Button>
                                      </Grid>
                                    </StyledTableCell>
                                  </StyledTableRow>
                                </>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default YotiResult;
