import React, {FC, useState} from "react";
import {Grid, Typography, Stack, Paper, Button} from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {setNotificationMessage} from "../../../../../../../utils/redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyToClipboard from "react-copy-to-clipboard";
import {useDispatch} from "react-redux";
import VettingDocNumberModal from "./VettingDocNumberModal";
import {AddOutlined} from "@mui/icons-material";

const VettingDocNumberSection: FC<{
  number: string,
  id: string | undefined,
  activeDocument: any,
  candidate_id: string | undefined,
  title: string,
  type: string
}> = ({
        number,
        id,
        activeDocument,
        candidate_id,
        title,
        type
      }) => {


  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);


  return (
    <>
      <Grid xs={12} container className="card-vetting" sx={{marginTop: '2rem !important'}}>
        <Grid
          container
          className="card-head-table"
          direction="row"
          justifyContent={"space-between"}
          alignItems={'center'}
          sx={{marginBottom: '0 !important'}}
        >
          <Grid
            item
            xs={12}
            sx={{pl: 1, pt: 1, pb: 2}}
          >

            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              paddingTop={"10px"}
              sx={{pl: {xs: "18px", md: 2}}}
            >
              {title}
            </Typography>

          </Grid>
        </Grid>
        <Grid xs={12} item md={12}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >

            <Stack sx={{
              backgroundColor: "#f5fbff",
              padding: '1.5rem 3rem',
              width: '100%',
              borderBottomRightRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}>

              <Stack justifyContent={"space-between"} direction={"row"} sx={{flex: 1, padding: '1rem 2rem'}}
                     component={Paper}>

                {number && number !== '' ?
                  (
                    <>
                      <Typography
                        variant={"body2"}
                        fontSize={"16px"}
                        fontWeight={"bold"}
                        paddingTop={"10px"}
                      >
                        {number}
                      </Typography>
                      <Stack direction={"row"} gap={"1rem"}>
                        <Button
                          sx={{width: 'fit-content'}}
                          startIcon={<EditOutlinedIcon/>}
                          variant={"outlined"}
                          onClick={() => setOpenModal(true)}
                        >
                          Change
                        </Button>

                        <CopyToClipboard
                          text={number}
                          onCopy={() =>
                            dispatch(
                              setNotificationMessage({
                                display: true,
                                severity: "success",
                                message: `${title} is copied to Clipboard`
                              })
                            )
                          }
                        >
                          <Button variant={"text"}>
                            <ContentCopyIcon/>
                          </Button>
                        </CopyToClipboard>
                      </Stack>
                    </>
                  ) : (
                    <Button
                      sx={{width: 'fit-content'}}
                      startIcon={<AddOutlined/>}
                      variant={"outlined"}
                      onClick={() => setOpenModal(true)}
                    >
                      Add {title}
                    </Button>
                  )
                }





              </Stack>

            </Stack>

            <VettingDocNumberModal
              openModal={openModal}
              setOpenModal={setOpenModal}
              id={id}
              vetting_document_id={activeDocument.id}
              candidate_id={candidate_id}
              title={title}
              number={number}
              type={type}
            />


          </Grid>
        </Grid>
      </Grid>


    </>
  );
};
export default VettingDocNumberSection
