import React, {FC, useState, useEffect, useCallback} from "react";
import {
  Stack,
  Button,
  Typography, CircularProgress,
} from "@mui/material";
import axiosInstance from "../../../../utils/axios";
import RenderMappingColumn from "./RenderMappingColumn";
import { useForm, Controller } from 'react-hook-form';
import {setNotificationMessage} from "../../../../utils/redux";
import {useDispatch} from "react-redux";
import dayjs from "dayjs";
import {LoopOutlined} from "@mui/icons-material";

type CustomItemType = {
  type: string;
  heading: string;
  custom_fields: {
    name: string;
    id: string;
    type: string;
    type_id: number;
    attachment_category_id: number | "";
    custom_field_id: number | "";
  }[];
};

const ItrisSettings: FC = () => {
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [leftColumnData, setLeftColumnData] = useState<CustomItemType[]>([]);
  const [rightColumnData, setRightColumnData] = useState<CustomItemType[]>([]);
  // custom fields
  const [customFieldDropdowns, setCustomFieldDropdowns] = useState<{ id: string; name: string, type: number }[]>([]);
  const [customFields, setCustomFields] = useState<{ [key: string]: string }>({});
  // attachment categories
  const [attachmentCategoriesDropdowns, setAttachmentCategoriesDropdowns] = useState<{ id: string; name: string, type: number }[]>([]);
  const [attachmentCategories, setAttachmentCategories] = useState<{ [key: string]: string }>({});
  // reference types
  const [referenceTypes, setReferenceTypes] = useState<{ id: string; name: string, type: number }[]>([]);

  const [isDirty, setIsDirty] = useState(false);
  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");

  const [isFetchingDataFromItris, setIsFetchingDataFromItris] = useState(false);


  const onSubmit = async () => {
    setIsSaving(true)
    const transformData = [...leftColumnData, ...rightColumnData].map((column) => ({
      type: column.type,
      mappings: column.custom_fields.map((field) => ({
        id: field.id,
        custom_field_id: customFields[field.id],
        attachment_category_id: attachmentCategories[field.id],
      })),
    }));
    await axiosInstance.post(
      `${process.env.REACT_APP_NODE_ITRIS_API_URL}/authorized/itris/mappings`,
      transformData
    );
    if(!isAutoSaving){
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "itris custom field mapping saved successfully",
        })
      );
    }
    setIsSaving(false)
    setIsAutoSaving(false);
    setIsDirty(false);
    const lastUpdated = new Date().getTime().toString();
    setLastUpdated(lastUpdated)
  };

  const manageCredentials = (rightColumnData: CustomItemType[]) => {
    return rightColumnData.map(column => {
      if (column.type === 'credentials') {

        const existingCount = column.custom_fields.filter(field =>
          field.custom_field_id !== ""
        ).length;
        if(existingCount > 3){
          return {
            ...column,
            custom_fields: column.custom_fields.slice(0, existingCount),
          };
        } else {
          return {
            ...column,
            custom_fields: column.custom_fields.slice(0, 3),
          };
        }
      }
      return column;
    });
  };

  const getMappings = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_ITRIS_API_URL}/authorized/itris/mappings`);
      const {
        left_column,
        right_column,
        itris_custom_fields,
        itris_attachment_categories,
        itris_reference_types,
        last_updated
      } = response.data;

      setLeftColumnData(left_column);
      setRightColumnData(manageCredentials(right_column));
      setCustomFieldDropdowns(itris_custom_fields);
      setAttachmentCategoriesDropdowns(itris_attachment_categories);
      setReferenceTypes(itris_reference_types);
      setLastUpdated(last_updated)

      const allCustomFields = [
        ...left_column.flatMap((column: any) => column.custom_fields),
        ...right_column.flatMap((column: any) => column.custom_fields),
      ];
      // Create an object with IDs as keys and empty strings as values
      const customFieldsObject = allCustomFields.reduce((acc, field) => {
        acc[field.id] = field.custom_field_id;
        return acc;
      }, {} as { [key: string]: string });
      setCustomFields(customFieldsObject);

      const attachmentCategoryObject = allCustomFields.reduce((acc, field) => {
        acc[field.id] = field.attachment_category_id;
        return acc;
      }, {} as { [key: string]: string });
      setAttachmentCategories(attachmentCategoryObject);

      setIsLoading(false)
    } catch (e) {
      console.error('Error fetching data:', e);
    }
  }, [leftColumnData, rightColumnData, customFields, customFieldDropdowns]);

  useEffect(() => {
    getMappings()
  }, []);

  // useEffect(() => {
  //   console.log(customFields)
  // }, [customFields]);

  const handleCustomFieldChange = (event: any, newValue: any, id: string) => {
    setCustomFields(prevState => ({
      ...prevState,
      [id]: newValue ? newValue.id : '' // Store selected name in customFields state
    }));
    setIsDirty(true);
  };

  const handleAttachmentCategoryChange = (event: any, newValue: any, id: string) => {
    setAttachmentCategories(prevState => ({
      ...prevState,
      [id]: newValue ? newValue.id : '' // Store selected name in customFields state
    }));
    setIsDirty(true);
  };

  // handle Add Credential
  const handleAddCredential = () => {
    setRightColumnData(prevRightColumnData =>
      prevRightColumnData.map(column => {
        if (column.type === 'credentials') {
          if (column.custom_fields.length < 10) {
            const newCredential: CustomItemType['custom_fields'][number] = {
              name: `Credential ${column.custom_fields.length + 1}`,
              id: `credentials_${column.custom_fields.length + 1}`,
              type: 'Text',
              type_id: 6,
              attachment_category_id: "",  // Default empty string for attachment_category_id
              custom_field_id: ""
            };
            return {
              ...column,
              custom_fields: [...column.custom_fields, newCredential]
            };
          }
        }
        return column;
      })
    );
    setIsDirty(true);
  };

// handleRemoveCredential
  const handleRemoveCredential = (index: number) => {
    setRightColumnData(prevRightColumnData =>
      prevRightColumnData.map(column => {
        if (column.type === 'credentials') {
          const updatedFields = column.custom_fields.filter((_, i) => i !== index);

          if (updatedFields.length >= 3) { // Ensure a minimum of 3 credentials
            // Re-index the names and IDs after removing
            const reIndexedFields = updatedFields.map((field, i) => ({
              ...field,
              name: `Credential ${i + 1}`,
              id: `credentials_${i + 1}`
            }));
            return {
              ...column,
              custom_fields: reIndexedFields
            };
          }
        }
        return column;
      })
    );
    setIsDirty(true);
  };

  // Auto-save functionality
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isDirty) {
      intervalId = setInterval(() => {
        setIsAutoSaving(true);
        onSubmit();
      }, 10000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isDirty]);

  // fetching itris data
  const refetchItrisData = useCallback(async () => {
    try {
      setIsFetchingDataFromItris(true);
      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_ITRIS_API_URL}/authorized/itris/data`);
      const {
        itris_custom_fields,
        itris_attachment_categories,
      } = response.data;
      setCustomFieldDropdowns(itris_custom_fields);
      setAttachmentCategoriesDropdowns(itris_attachment_categories);
      setIsFetchingDataFromItris(false);

      dispatch(
        setNotificationMessage({
          display: true,
          severity: "success",
          message: "itris data re-fetched successfully",
        })
      );
    } catch (e) {
      console.log(e);
      setIsFetchingDataFromItris(false);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Something went wrong while fetching data from itris. Please try again.",
        })
      );
    }
  }, [customFields, customFieldDropdowns, isFetchingDataFromItris])


      return (
    <Stack paddingBottom={"2rem"} position={"relative"} minHeight={"100vh"}>
      {isLoading && <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"} sx={{
        position: 'absolute',
        background: 'rgba(255, 255, 255, 0.5)',
        zIndex: 3
      }}>
        <CircularProgress />
      </Stack>
      }
      <Stack
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          padding: '10px',
          position: 'sticky',
          top: 0
        }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
          <Typography color={'#707070'}>Custom Fields Mapping</Typography>
          <Button
            variant={"text"}
            startIcon={isFetchingDataFromItris ? <CircularProgress sx={{color: '#9a9a9a'}} size={18}/> : <LoopOutlined/>}
            disabled={isFetchingDataFromItris}
            onClick={() => refetchItrisData()}
          >
            Re-fetch Data from Itris
          </Button>
        </Stack>
        <Stack direction={"row"} alignItems={"center"} gap={"1rem"}>
          {lastUpdated &&
            <Typography color={'#707070'} sx={{ fontSize: '0.75rem'}}>
              Last Updated:
              <strong>{dayjs(new Date(parseInt(lastUpdated))).format(
                "DD MMM YYYY hh:mm A"
              )}</strong>
            </Typography>
          }
          <Button disabled={isSaving} onClick={handleSubmit(onSubmit)} variant="contained" sx={{ color: 'white' }}>
            {isSaving && <CircularProgress sx={{mr: 1, color: '#9a9a9a'}} size={18}/>}
            <>
              {isAutoSaving ? "Auto Saving" : "Saving Changes"}
            </>
          </Button>
        </Stack>
      </Stack>

      {/* Main Content with Left and Right Columns */}
      <Stack gap={'2rem 1.5rem'} paddingTop={'2rem'}>
        <Stack direction={{xs: 'column', md: 'row'}} spacing={6}>
          {/* Left Column */}

          <Stack flex={1} spacing={6}>
            {leftColumnData.map((columnData: CustomItemType, index: number) => (
              <RenderMappingColumn
                key={index}
                columnData={columnData}
                customFieldDropdowns={customFieldDropdowns}
                customFields={customFields}
                attachmentCategories={attachmentCategories}
                attachmentCategoriesDropdowns={attachmentCategoriesDropdowns}
                handleCustomFieldChange={handleCustomFieldChange}
                handleAttachmentCategoryChange={handleAttachmentCategoryChange}
              />
            ))}
          </Stack>
          <Stack flex={1} spacing={3}>
            {rightColumnData.map((columnData: CustomItemType, index: number) => (
              <RenderMappingColumn
                key={index}
                columnData={columnData}
                customFieldDropdowns={columnData.type === 'references' ? referenceTypes : customFieldDropdowns}
                customFields={customFields}
                attachmentCategories={attachmentCategories}
                attachmentCategoriesDropdowns={attachmentCategoriesDropdowns}
                handleCustomFieldChange={handleCustomFieldChange}
                handleAttachmentCategoryChange={handleAttachmentCategoryChange}
                handleAddCredential={handleAddCredential}
                handleRemoveCredential={handleRemoveCredential}
              />
            ))}
          </Stack>

          {/* Right Column */}

        </Stack>
      </Stack>
    </Stack>
  );
};

export default ItrisSettings;
