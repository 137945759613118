import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import avatar from "../../../../../../../utils/Avatar/avatar";
import dayjs from "dayjs";

interface ServiceInfoDocumentsTabProps {
  services: any;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const ServiceInfoDocumentsTab: FC<ServiceInfoDocumentsTabProps> = ({
  services,
  page,
  rowsPerPage,
  isLoading,
  handleChangePage,
}) => {
  const [searchName, setSearchName] = useState("");
  const [filteredServices, setFilteredServices] = useState<any>([]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  useEffect(() => {
    let filtered = services;

    if (searchName) {
      const searchTerm = searchName.toLowerCase();
      filtered = filtered.filter((service: any) =>
        service.candidate_name.toLowerCase().includes(searchTerm) ||
        service.candidate_email?.toLowerCase().includes(searchTerm) ||
        service.request_name.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredServices(filtered);
  }, [searchName, services]);

  const calculateTotalDocuments = (documents: any[]) => {
    return documents.reduce((total, doc) => total + doc.sub_document_count, 0);
  };

  const getTotalDocumentsCount = (services: any[]) => {
    return services.reduce((total, service) => total + calculateTotalDocuments(service.documents), 0);
  };

  return (
    <>
      {isLoading && (
        <Stack p={"1rem"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={20} />
        </Stack>
      )}
      {!isLoading && (
        <>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              background: "linear-gradient(90.5deg, #E9FAFF 5.76%, #F0F0FF 50.43%)",
              overflowY: "auto",
              boxSizing: "border-box",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                paddingBottom: "16px",
                width: "100%",
                maxHeight: "auto",
                overflowY: "auto",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={5} align="right">
                      <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-end">
                        <TextField
                          label="Search by Applicant Name"
                          variant="outlined"
                          value={searchName}
                          size="small"
                          onChange={handleSearchChange}
                          sx={{ maxWidth: 250 }}
                        />
                        <Typography>Total: {getTotalDocumentsCount(filteredServices)}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Applicant Name</TableCell>
                    <TableCell>Request Name</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Total Documents</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredServices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar {...avatar(row.candidate_name || "Unknown")} />
                            <Stack>
                              <Typography>{row.candidate_name || "No Name"}</Typography>
                              <Typography fontSize="14px" color="text.secondary">
                                {row.candidate_email || "No Email"}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>{row.request_name || "N/A"}</TableCell>
                        <TableCell>{row.designation || "N/A"}</TableCell>
                        <TableCell>{calculateTotalDocuments(row.documents) || 0}</TableCell>
                      </TableRow>
                    ))}

                  {filteredServices.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        <Typography>No Applicant Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={filteredServices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelRowsPerPage={""}
                sx={{
                  position: "relative",
                  top: "-5px",
                  marginTop: "0",
                  marginBottom: "16px",
                }}
              />
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ServiceInfoDocumentsTab;
