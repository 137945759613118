import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Divider,
  Button,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from "../../../../../utils/axios";
import { setNotificationMessage } from "../../../../../utils/redux";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { CreateComplianceOfficerProps } from '../Interfaces/AgencyInterfaces';
import { GetOpenedAgency, setIsAddComplianceOfficerDialogOpened } from '../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice';

const validationSchema = yup.object().shape({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().required('Email Address is required').email('Enter a valid email address'),
  agency_id: yup.string(),
});

const CreateComplianceOfficer: React.FC<CreateComplianceOfficerProps> = ({
  isOpen,
  onComplete
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [apiError, setAPIError] = useState<{ errorData: string } | null>(null);
  const dispatch = useDispatch();

  const openedAgency = useSelector(GetOpenedAgency);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      agency_id: openedAgency?.agency_id,
    },
  });

  const handleCreateComplianceOfficer = async (data: any) => {
    setLoading(true);
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies/team-members`,
        data
      );
      onComplete();
      handleClose();
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'success',
          message: 'Compliance Officer Added Successfully.',
        })
      );
    } catch (error: any) {
      console.log(error)
      if (error.response && error.response.status === 409) {
        const errorData = error.response.data?.Error;
        setAPIError({ errorData });
      } else {
        dispatch(
          setNotificationMessage({
            display: true,
            severity: 'error',
            message: 'Something went wrong while adding compliance officer, please try again later.',
          })
        );
      }
    }
    setLoading(false);
  };

  const handleClose = () => {
    reset();
    setAPIError(null);
    dispatch(setIsAddComplianceOfficerDialogOpened(false));
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontSize={'1.25rem'} fontWeight={500} letterSpacing={'0.00938rem'} lineHeight={'160%'}>
            New Compliance Officer
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={'1rem'}>
          <Stack gap={'0.5rem'} flexDirection={
            {
              xs: 'column',
              md: 'row'
            }
          }>
            <Controller
              name={`first_name`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id={`first_name`}
                  label="First Name"
                  variant="outlined"
                  required
                  fullWidth
                  error={!!errors.first_name}
                  helperText={errors.first_name?.message}
                />
              )}
            />
            <Controller
              name={`last_name`}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id={`last_name`}
                  label="Last Name"
                  variant="outlined"
                  required
                  fullWidth
                  error={!!errors.last_name}
                  helperText={errors.last_name?.message}
                />
              )}
            />
          </Stack>
          <Controller
            name={`email`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id={`email`}
                label="Email Address"
                variant="outlined"
                required
                fullWidth
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          {apiError && (
            <Alert severity="error">
              {apiError.errorData}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={handleSubmit(handleCreateComplianceOfficer)}
          variant="contained"
          disabled={loading}
          style={{ color: 'white' }}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'ADD COMPLIANCE OFFICER'}
        </Button>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateComplianceOfficer;
