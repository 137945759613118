import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
  Divider,
  Button,
  IconButton,
  CircularProgress,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from "../../../../../utils/axios";
import { setNotificationMessage } from "../../../../../utils/redux";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { EditAgencyProps } from "../Interfaces/AgencyInterfaces";
import { GetEditAgencyData, setEditAgencyData, setEditAgencyDialogOpened } from '../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice';

const validationSchema = yup.object().shape({
  name: yup.string().required('Agency Name is required'),
  ucheck_dbs_key: yup.string(),
  id: yup.string(),
});

const EditAgency: React.FC<EditAgencyProps> = ({ isOpen, onComplete }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const editAgencyData = useSelector(GetEditAgencyData);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: editAgencyData?.agency_name,
      ucheck_dbs_key: editAgencyData?.agency_dbs_key,
      id: editAgencyData?.agency_id,
    },
  });

  const handleEditAgency = async (data: any) => {
    setLoading(true);
    try {
      await axiosInstance.put(
        `${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies`,
        data
      );
      handleClose();
      onComplete();
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'success',
          message: 'Agency Updated Successfully.',
        })
      );
    } catch (error) {
      console.log(error)
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: 'Something went wrong while updating the agency. Please try again.',
        })
      );
    }
    setLoading(false);
  };

  const handleClose = () => {
    dispatch(setEditAgencyData(null));
    dispatch(setEditAgencyDialogOpened(false));
    reset();
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography fontSize={'1.25rem'} fontWeight={500} letterSpacing={'0.00938rem'} lineHeight={'160%'}>
            Edit Agency
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack gap={'1rem'}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="name"
                label="Agency Name"
                variant="outlined"
                required
                fullWidth
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
          <Controller
            name="ucheck_dbs_key"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                id="ucheck_dbs_key"
                label="DBS Key (optional)"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          type="button"
          onClick={handleSubmit(handleEditAgency)}
          variant="contained"
          disabled={loading}
          style={{ color: 'white' }}>
          {loading ? <CircularProgress size={24} color="inherit" /> : 'EDIT AGENCY'}
        </Button>
        <Button disabled={loading} onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAgency;
