import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  Typography,
  Divider,
} from "@mui/material";
import dayjs from "dayjs";
import avatar from "../../../../../../../utils/Avatar/avatar";

interface ServiceInfoEsignTabProps {
  activeTab: number;
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
  services: any;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const chipOptions = [
  { label: "All", value: "All" },
  { label: "Created", value: "CREATED" },
  { label: "Signed", value: "SIGNED" },
  { label: "Rejected", value: "REJECTED" },
];

const ServiceInfoEsignTab: FC<ServiceInfoEsignTabProps> = ({
  activeTab,
  activeFilterTab,
  setActiveFilterTab,
  services,
  page,
  rowsPerPage,
  isLoading,
  handleChangePage,
}) => {
  const [status, setStatus] = useState(activeFilterTab ? activeFilterTab : "All");
  const [searchName, setSearchName] = useState("");
  const [filteredServices, setFilteredServices] = useState<any>([]);

  const handleChipClick = (newStatus: string) => {
    setStatus(newStatus);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  useEffect(() => {
    let filtered = services;

    // Filter by status
    if (status !== "All") {
      filtered = filtered.filter(
        (service: any) => service.status.toUpperCase() === status.toUpperCase()
      );
    }

    // Filter by applicant name
    if (searchName) {
      const searchTerm = searchName.toLowerCase();
      filtered = filtered.filter((service: any) =>
        service.user_name?.toLowerCase().includes(searchTerm) ||
        service.receipent_email?.toLowerCase().includes(searchTerm) ||
        service.request_name?.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredServices(filtered);
  }, [status, searchName, services]);

  const Chips = React.useMemo(
    () => (
      <Stack direction="row" spacing={1} alignItems="center" gap={"0.25rem"}>
        {chipOptions.map((chip) => (
          <Chip
            key={chip.value}
            label={chip.label}
            clickable
            variant={"outlined"}
            color={status === chip.value ? "primary" : "default"}
            onClick={() => handleChipClick(chip.value)}
            sx={{ paddingLeft: "0.65rem", paddingRight: "0.65rem" }}
          />
        ))}
      </Stack>
    ),
    [status]
  );

  return (
    <>
      {isLoading && (
        <Stack p={"1rem"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={20} />
        </Stack>
      )}
      {!isLoading && (
        <>
          <Divider sx={{ margin: 0 }} />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              background: "linear-gradient(90.5deg, #E9FAFF 5.76%, #F0F0FF 50.43%)",
              overflowY: "auto",
              boxSizing: "border-box",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                paddingBottom: "16px",
                width: "100%",
                maxHeight: "auto",
                overflowY: "auto",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>{Chips}</TableCell>
                    <TableCell colSpan={3} align="right">
                      <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                        <TextField
                          label="Search by Applicant Name"
                          variant="outlined"
                          value={searchName}
                          size={"small"}
                          onChange={handleSearchChange}
                          sx={{ maxWidth: 250 }}
                          
                        />
                        <Typography>Total: {filteredServices.length}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Applicant Name</TableCell>
                    <TableCell>Request</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredServices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar {...avatar(row.user_name || 'Unknown')} />
                            <Stack>
                              <Typography>{row.user_name || 'No Name'}</Typography>
                              <Typography fontSize="14px" color="text.secondary">
                                {row.receipent_email || 'No Email'}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>{row.request_name}</TableCell>
                        <TableCell>{row.designation}</TableCell>
                        <TableCell>
                          <Chip label={row.status} clickable variant={"outlined"} />
                        </TableCell>
                        <TableCell>{dayjs(row.created_at).format("DD MMM YYYY")}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={filteredServices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelRowsPerPage={""}
                sx={{
                  position: "relative",
                  top: "-5px",
                  marginTop: "0",
                  marginBottom: "16px",
                }}
              />
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ServiceInfoEsignTab;
