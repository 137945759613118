import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Chip, Stack, Typography } from '@mui/material';
import { LimitCapHistoryItemProps } from '../../Interfaces/AgencyInterfaces';

const LimitCapHistoryItem: React.FC<LimitCapHistoryItemProps> = ({ date, value, label, isLastItem }) => {
    return (
        <TimelineItem sx={{
            ':before': {
                flex: 0,
                padding: 0,
            },
        }}>
            <TimelineSeparator>
                <TimelineDot variant="outlined" />
                {!isLastItem && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
                <Stack>
                    <Typography color={'#646464'} fontSize={'0.875rem'}>{date}</Typography>
                    <Stack flexDirection={'row'} gap={'1rem'}>
                        <Typography>{value}</Typography>
                        <Chip
                            variant="outlined"
                            label={label}
                            size='small'
                            style={{
                                borderColor: label === 'change' ? '#65B741' : 'default',
                                color: label === 'change' ? '#65B741' : 'default',
                            }}
                        />
                    </Stack>
                </Stack>
            </TimelineContent>
        </TimelineItem>
    );
};

export default LimitCapHistoryItem;
