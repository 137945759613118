import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Divider,
  Typography,
} from "@mui/material";
import avatar from "../../../../../../../utils/Avatar/avatar";
import dayjs from "dayjs";

interface ServiceInfoDBSTabProps {
  activeTab: number;
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
  services: any[];
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const filterOptions = [
  { label: "All", value: "All" },
  { label: "Submitted", value: "SUBMITTED" },
  { label: "In Review", value: "IN_REVIEW" },
  { label: "Approved", value: "APPROVED" },
];

const ServiceInfoDBSTab: FC<ServiceInfoDBSTabProps> = ({
  activeTab,
  activeFilterTab,
  setActiveFilterTab,
  services,
  page,
  rowsPerPage,
  isLoading,
  handleChangePage,
}) => {
  const [status, setStatus] = useState(activeFilterTab ? activeFilterTab : "All");
  const [dbs_type, setDbsType] = useState("All");
  const [searchName, setSearchName] = useState("");
  const [filteredServices, setFilteredServices] = useState<any[]>([]);

  const uniqueDbsTypes = Array.from(new Set(services.map((service) => service.dbs_type)));

  const handleChipClick = (newStatus: string) => {
    setStatus(newStatus);
  };

  const handleDbsTypeChange = (event: any) => {
    setDbsType(event.target.value);
  };

  useEffect(() =>{
    console.log(activeFilterTab)
  }, [activeFilterTab])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  useEffect(() => {
    let filtered = services;

    // Filter by status
    if (status !== "All") {
      filtered = filtered.filter((service) => service.status.toUpperCase() === status.toUpperCase());
    }

    // Filter by DBS type
    if (dbs_type !== "All") {
      filtered = filtered.filter((service) => service.dbs_type === dbs_type);
    }

    // Filter by applicant name
    if (searchName) {
      const searchTerm = searchName.toLowerCase();
      filtered = filtered.filter((service) =>
        service.candidate_name.toLowerCase().includes(searchTerm) ||
        service.candidate_email?.toLowerCase().includes(searchTerm) ||
        service.request_name.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredServices(filtered);
  }, [status, dbs_type, searchName, services]);

  const TableHeadFilterOptions = React.useMemo(() => (
    <Stack direction="row" spacing={1} alignItems="center" gap={"0.25rem"}>
      {filterOptions.map((chip) => (
        <Chip
          key={chip.value}
          label={chip.label}
          clickable
          variant={"outlined"}
          color={status === chip.value ? "primary" : "default"}
          onClick={() => handleChipClick(chip.value)}
          sx={{ paddingLeft: "0.65rem", paddingRight: "0.65rem" }}
        />
      ))}
    </Stack>
  ), [status]);

  return (
    <>
      {isLoading && (
        <Stack p={"1rem"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={20} />
        </Stack>
      )}
      {!isLoading && (
        <>
          <Divider sx={{ margin: 0 }} />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              background: "linear-gradient(90.5deg, #E9FAFF 5.76%, #F0F0FF 50.43%)",
              overflowY: "auto",
              boxSizing: "border-box",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                paddingBottom: "16px",
                width: "100%",
                maxHeight: "auto",
                overflowY: "auto",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>{TableHeadFilterOptions}</TableCell>
                    <TableCell colSpan={4}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                        width="100%"
                      >
                        <TextField
                          label="Search by Applicant Name"
                          variant="outlined"
                          value={searchName}
                          size={"small"}
                          onChange={handleSearchChange}
                          sx={{ flex: 1, maxWidth: 250 }}
                          autoComplete="off"
                        />
                        <FormControl sx={{ minWidth: 120 }}>
                          <InputLabel id="dbs-type-label">DBS Type</InputLabel>
                          <Select
                            labelId="dbs-type-label"
                            value={dbs_type}
                            label="DBS Type"
                            size={"small"}
                            onChange={handleDbsTypeChange}
                          >
                            <MenuItem value="All">All</MenuItem>
                            {uniqueDbsTypes.map((dbs_type, index) => (
                              <MenuItem key={index} value={dbs_type}>
                                {dbs_type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Typography>Total: {filteredServices.length}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Applicant Name</TableCell>
                    <TableCell>DBS Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Request</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredServices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar {...avatar(row.candidate_name)} />
                            <Stack>
                              <Typography>{row.candidate_name}</Typography>
                              <Typography fontSize="14px" color="text.secondary">
                                {row.candidate_email || 'No Email'}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip label={row.dbs_type} clickable variant={"outlined"} />
                        </TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.request_name}</TableCell>
                        <TableCell>{row.designation}</TableCell>
                        <TableCell>{dayjs(row.created_at).format("DD MMM YYYY")}</TableCell>
                      </TableRow>
                    ))}

                  {filteredServices.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography>No Applicant Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={filteredServices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelRowsPerPage={""}
                sx={{
                  position: "relative",
                  top: "-5px",
                  marginTop: "0",
                  marginBottom: "16px",
                }}
              />
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ServiceInfoDBSTab;
