import React, { FC } from "react";
import { Box, Grid, Typography, Stack, Radio, SvgIcon } from "@mui/material";
import { images } from "../../../../utils/constants/images";
import { useSelector } from "react-redux";
import { ReferenceData } from "../../../../utils/redux";
import { identifiers } from "../../../../utils/constants/identifiers";

const LeftSection: FC = () => {
  const reference = useSelector(ReferenceData);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          mb: { xs: 0, md: 7 },
          borderBottom: { xs: "1px solid #DADADA", md: "none" },
        }}
      >
        <img src={images.kedIcon} alt="kedIcon" />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 500,
            fontSize: "24px",
            lineHeight: "52px",
            color: "#333333",
          }}
        >
          Reference Form
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "20px",
            color: "#333333",
          }}
        >
          Making verification process easier for compliance officers
        </Typography>
      </Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          gap: "20px",
          width: "100%",
          px: 2,
        }}
      >
        {reference.available_routes.indexOf(
          identifiers.referee_details as string
        ) > -1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Stack>
                <Radio
                  disabled={
                    reference.active_index <
                    reference.available_routes.indexOf(
                      identifiers.referee_details as string
                    )
                  }
                  checked={
                    reference.active_index >=
                    reference.available_routes.indexOf(
                      identifiers.referee_details as string
                    )
                  }
                  checkedIcon={
                    reference.active_index >
                      reference.available_routes.indexOf(
                        identifiers.referee_details as string
                      ) ? (
                      <SvgIcon
                        component={images.checkBoxCircleChecked}
                        inheritViewBox
                      />
                    ) : undefined
                  }
                />
              </Stack>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  height: "72px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  width: "calc(100% - 150px)",
                }}
                data-tut="referee_details_tab"
              >
                <img
                  height="30px"
                  width="30px"
                  src={images.emailRefereeDetails}
                  alt="user"
                  className="email-list-icon"
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#5AB9F9",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  Referee Details
                </Typography>
              </Stack>
            </Box>
          )}
        {reference.available_routes.indexOf(
          identifiers.ked_questionnaire as string
        ) > -1 && !reference.referee_uploaded_doc_key && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid>
                <Radio
                  disabled={
                    reference.active_index <
                    reference.available_routes.indexOf(
                      identifiers.ked_questionnaire as string
                    )
                  }
                  checked={
                    reference.active_index >=
                    reference.available_routes.indexOf(
                      identifiers.ked_questionnaire as string
                    )
                  }
                  checkedIcon={
                    reference.active_index >
                      reference.available_routes.indexOf(
                        identifiers.ked_questionnaire as string
                      ) ? (
                      <SvgIcon
                        component={images.checkBoxCircleChecked}
                        inheritViewBox
                      />
                    ) : undefined
                  }
                />
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  height: "72px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  width: "calc(100% - 150px)",
                }}
              >
                <img
                  height="30px"
                  width="30px"
                  src={images.emailEmploymentDetails}
                  alt="user"
                  className="email-list-icon"
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#5AB9F9",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  KED Questionnaire
                </Typography>
              </Stack>
            </Box>
          )}
        {reference.available_routes.indexOf(
          identifiers.reference_documents as string
        ) > -1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid>
                <Radio
                  disabled={
                    reference.active_index <
                    reference.available_routes.indexOf(
                      identifiers.reference_documents as string
                    )
                  }
                  checked={
                    reference.active_index >=
                    reference.available_routes.indexOf(
                      identifiers.reference_documents as string
                    )
                  }
                  checkedIcon={
                    reference.active_index >
                      reference.available_routes.indexOf(
                        identifiers.reference_documents as string
                      ) ? (
                      <SvgIcon
                        component={images.checkBoxCircleChecked}
                        inheritViewBox
                      />
                    ) : undefined
                  }
                />
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  height: "72px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  width: "calc(100% - 150px)",
                }}
              >
                <img
                  height="30px"
                  width="30px"
                  src={images.emailRefereeDetails}
                  alt="user"
                  className="email-list-icon"
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#5AB9F9",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  {"Reference Form" +
                    (reference.reference_document_forms.length > 1 ? "s" : "")}
                </Typography>
              </Stack>
            </Box>
          )}
        {reference.available_routes.indexOf(
          identifiers.additional_questionnaire as string
        ) > -1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid>
                <Radio
                  disabled={
                    reference.active_index <
                    reference.available_routes.indexOf(
                      identifiers.additional_questionnaire as string
                    )
                  }
                  checked={
                    reference.active_index >=
                    reference.available_routes.indexOf(
                      identifiers.additional_questionnaire as string
                    )
                  }
                  checkedIcon={
                    reference.active_index >
                      reference.available_routes.indexOf(
                        identifiers.additional_questionnaire as string
                      ) ? (
                      <SvgIcon
                        component={images.checkBoxCircleChecked}
                        inheritViewBox
                      />
                    ) : undefined
                  }
                />
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  height: "72px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  width: "calc(100% - 150px)",
                }}
              >
                <img
                  height="30px"
                  width="30px"
                  src={images.emailRefereeDetails}
                  alt="user"
                  className="email-list-icon"
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#5AB9F9",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  {"Additional Questionnaire" +
                    (reference.additional_questionnaire_forms.length > 1
                      ? "s"
                      : "")}
                </Typography>
              </Stack>
            </Box>
          )}
        {reference.available_routes.indexOf(
          identifiers.reference_comments as string
        ) > -1 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid>
                <Radio
                  disabled={
                    reference.active_index <
                    reference.available_routes.indexOf(
                      identifiers.reference_comments as string
                    )
                  }
                  checked={
                    reference.active_index >=
                    reference.available_routes.indexOf(
                      identifiers.reference_comments as string
                    )
                  }
                  checkedIcon={
                    reference.active_index >
                      reference.available_routes.indexOf(
                        identifiers.reference_comments as string
                      ) ? (
                      <SvgIcon
                        component={images.checkBoxCircleChecked}
                        inheritViewBox
                      />
                    ) : undefined
                  }
                />
              </Grid>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                sx={{
                  height: "72px",
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  padding: "10px",
                  width: "calc(100% - 150px)",
                }}
              >
                <img
                  height="30px"
                  width="30px"
                  src={images.emailAdditionalComments}
                  alt="user"
                  className="email-list-icon"
                />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    color: "#5AB9F9",
                    display: { xs: "none", sm: "none", md: "block" },
                  }}
                >
                  Additional Comments
                </Typography>
              </Stack>
            </Box>
          )}
      </Box>
    </>
  );
};

export default LeftSection;
