import { Button, Divider, IconButton, InputLabel, Stack, TextField, useMediaQuery, useTheme, SwipeableDrawer } from "@mui/material";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import React, { useState } from "react";
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import FilterListIcon from "@mui/icons-material/FilterList";

interface DatePickerProps {
  isMobile: boolean,
  value: Date | null,
  onChange: (e: any) => void,
  minDate?: Date | null,
}

const FilterDatePicker: React.FC<DatePickerProps> = ({ isMobile, value, onChange, minDate }) => {
  const renderInputStyles = {
    '& .MuiInputBase-root': {
      padding: 0,
      margin: 0,
      border: 'none',
      '& input': {
        padding: '0',
        margin: '0',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      padding: '0',
      margin: '0',
    },
  };

  const renderInputParams = {
    sx: renderInputStyles,
  };

  const commonProps = {
    value,
    onChange,
    renderInput: (params: any) => (
      <TextField {...params} {...renderInputParams} />
    ),
    minDate,
  };

  return isMobile ? (
    <MobileDatePicker {...commonProps} />
  ) : (
    <DesktopDatePicker {...commonProps} />
  );
};

interface TableDatePickerProps {
  onApplyFilter: (start_date: Date | null, end_date: Date | null) => void
}

const TableDatePickerForm: React.FC<TableDatePickerProps> = ({ onApplyFilter }) => {

  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);

  const [initialDateFrom, setInitialDateFrom] = useState<Date | null>(dateFrom);
  const [initialDateTo, setInitialDateTo] = useState<Date | null>(dateTo);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isDrawerOpened, setIsDrawerOpened] = useState(false)
  const handleDrawerOpen = () => {
    setIsDrawerOpened(true);
  }

  const handleApplyFilter = () => {
    onApplyFilter(dateFrom, dateTo)
    setInitialDateFrom(dateFrom);
    setInitialDateTo(dateTo);

    if (isMobile) {
      setIsDrawerOpened(false)
    }
  }
  const handleClearFilter = () => {
    setDateFrom(null)
    setDateTo(null)
    setInitialDateFrom(null)
    onApplyFilter(null, null)
  }

  const FilterButtons = () => (
    <>
      <Stack padding={{
        xs: 0,
        md: '0.375rem 0rem 0.375rem 1rem'
      }} justifyContent={'center'}>
        <Button variant="outlined" onClick={handleApplyFilter}
          disabled={
            (dateFrom === null && dateTo === null) ||
            (dateFrom === initialDateFrom && dateTo === initialDateTo)
          }>Apply</Button>
      </Stack>
      <Stack justifyContent={'center'}>
        <Button style={{ color: '#000' }} onClick={handleClearFilter} disabled={dateFrom === null && dateTo === null}>Clear</Button>
      </Stack>
    </>
  )

  const FilterContent = () => {
    return (
      <Stack sx={{ padding: 0 }} gap={"1rem"}>
        <Stack borderRadius={'0.25rem'} border={'1px solid #C9C9C9'} flexDirection={'row'}>
          <Stack padding={'0.375rem 1rem'} justifyContent={'center'}>
            <InputLabel>Date From</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FilterDatePicker
                value={dateFrom}
                isMobile={isMobile}
                onChange={(date) => setDateFrom(date)}
              />
            </LocalizationProvider>
          </Stack>
          <Divider orientation="vertical" flexItem />
          <Stack padding={'0.375rem 1rem'} justifyContent={'center'}>
            <InputLabel>Date To</InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FilterDatePicker
                value={dateTo}
                isMobile={isMobile}
                onChange={(date) => setDateTo(date)}
                minDate={dateFrom}
              />
            </LocalizationProvider>
          </Stack>
          {!isMobile &&
            <>
              <Divider orientation="vertical" flexItem />
              <FilterButtons />
            </>
          }
        </Stack>
        {isMobile &&
          <Stack direction={"row"} gap={"1rem"} padding={"0"}>
            <FilterButtons />
          </Stack>
        }
      </Stack>
    )
  }

  return (
    <>
      {isMobile ? (
        <>
          <IconButton onClick={handleDrawerOpen}>
            <FilterListIcon />
          </IconButton>
          <SwipeableDrawer
            open={isDrawerOpened}
            onClose={() => setIsDrawerOpened(false)}
            onOpen={handleDrawerOpen}
            anchor="bottom"
            sx={{ borderRadius: '0.25rem 0.25rem 0rem 0rem' }}
          >
            <Stack sx={{ padding: '2rem 1rem' }}>
              <FilterContent />
            </Stack>
          </SwipeableDrawer>
        </>
      ) : <FilterContent />}
    </>
  )
}
export default TableDatePickerForm
