import React, { FC, useCallback, useEffect, useState } from "react";
import { Grid, Box, Typography, Stack, CircularProgress, Chip } from "@mui/material";
import PageLoader from "../../../../../../../../components/PageLoader";
import axios from "axios";
import { URLS } from "../../../../../../../../utils/constants/urls";
import {
  setNotificationMessage,
  setReload,
  Reload,
  VettingDocuments,
} from "../../../../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Amplify from "../../../../../../../../utils/services/amplify";
import YotiCompletionStatus from "./YotiCompletionStatus";
import axiosInstance from "../../../../../../../../utils/axios";
import { WarningAmberOutlined } from "@mui/icons-material";

interface Check {
  reason_code: string;
  message: string;
  type: string;
  checks: Check[];
}
const Yoti: FC<{
  scheme: "RTW" | "DBS_RTW" | "DBS";
  objective: string;
  active: number;
}> = ({ scheme, objective, active }) => {
  const dispatch = useDispatch();
  const { id } = useParams() as any;
  const reload = useSelector(Reload);
  const documents = useSelector(VettingDocuments);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>("");
  const [status, setStatus] = useState<"COMPLETED" | "ONGOING" | "SUBMITTED" | "ABORTED">(
    "ONGOING"
  );

  const [sessionId, setSessionId] = useState<string>("");


  const [checks, setChecks] = useState<Check[]>([])

  const getYotiUrl = useCallback(async () => {
    try {
      setLoading(true);
      const session = await Amplify.currentSession();
      const token = session.getIdToken().getJwtToken();
      const { data } = await axios.post(
        `${process.env.REACT_APP_NODE_API_URL}${URLS.yoti_rtw}/request/${id}?scheme=${scheme}&objective=${objective}&url=${process.env.REACT_APP_NODE_API_URL}`,
        null,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      if (data.state && data.state === 'ABORTED') {
        setStatus("ABORTED");
        setSessionId(data.session_id);
      } else {
        setUrl(data.url);
        setStatus(data.status);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "There is an error in getting YOTI Url",
        })
      );
    }
  }, [dispatch, id, objective, scheme]);

  useEffect(() => {
    (async () => {
      await getYotiUrl();
    })();
  }, [getYotiUrl]);

  useEffect(() => {
    (async () => {
      if (status === 'ABORTED') {
        const session = await Amplify.currentSession();
        const candidateId = session.getAccessToken().payload.sub;
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_NODE_V3_API_URL}/authenticated/yoti/checks/${sessionId}`
        );
        if (response.data.aborted) {
          setChecks(response.data.aborted);
        } else {
          setChecks(response.data.requirement_not_met);
        }
        setLoading(false);
      }
    })();
  }, [status]);


  const dispatchReload = () => {
    dispatch(setReload(!reload))
  }

  return (
    <Grid container>
      {/*{loading && <PageLoader/>}*/}
      <Grid item xs={12} sx={{
        paddingBottom: {
          xs: '8rem',
          md: 0
        },
        position: 'relative'
      }}>

        {loading &&
          <Stack
            position={"absolute"}
            left={0}
            right={0}
            bottom={0}
            alignItems={"center"}
            justifyContent={"center"}
            top={0}
            sx={{ background: 'rgba(255, 255, 255, 0.8)', backgroundFilter: 'blur(4px)', zIndex: 2 }}>
            <CircularProgress size={20} />
          </Stack>
        }

        {status === "ONGOING" ? (
          <Box
            sx={{
              background: "#D9D9D9",
              borderRadius: "20px",
              minHeight: "550px",
              display: "flex",

            }}
            margin={3}
            marginTop={0}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <iframe
              src={url}
              style={{
                height: "550px",
                width: "100%",
                borderRadius: "20px",
                border: "2px solid lightgray",
              }}
              allow="camera"
              title={"Yoti RTW - DBS"}
            ></iframe>
          </Box>
        ) : (
          <>
            {status === 'ABORTED' ? (
              <Stack sx={{ padding: '1rem' }}>

                <Stack sx={{
                  background: '#ffeaea',
                  borderRadius: '0.5rem',
                  boxShadow: '0 0 0 1px #e5c5c5',
                  padding: '1rem',
                  gap: '1rem',
                  mb: 2
                }}>
                  <Stack direction={"row"} gap={"0.5rem"} alignItems={"center"}>
                    <WarningAmberOutlined />
                    <Typography variant="h6" sx={{ fontSize: '1rem' }}>
                      The following issue(s) were identified during the Right to Work validation, please refer to compliance officer.
                    </Typography>
                  </Stack>



                  {checks.map((check) => (
                    <Stack sx={{
                      background: '#fff',
                      borderRadius: '0.5rem',
                      boxShadow: '0 0 0 1px #e5c5c5',
                      padding: '1rem',
                      gap: '1rem'
                    }}>

                      <Stack gap={"1rem"}>
                        <Chip label={status} color={"error"} variant="outlined" sx={{ width: 'fit-content' }} />
                        <Typography sx={{ fontSize: '1rem' }}>
                          {check.message}
                        </Typography>
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ) : (
              <YotiCompletionStatus activeDocument={documents[active].name} onClickNext={dispatchReload} />
            )
            }
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default Yoti;
