import { FC } from "react";
import {
  Stack,
} from "@mui/material";
import { AgencyDetailProps } from "../Interfaces/AgencyInterfaces";
import AgencySummary from "./AgencySummary";
import ComplianceOfficersTable from "./ComplianceOfficersTable";
import ServiceOverview from "./ServiceOverview"
import LimitCap from "./LimitCap";

const AgencyDetails: FC<AgencyDetailProps> = ({
  agency,
  onServiceRefresh
}) => {

  return (
    <Stack
      gap={
        {
          xs: '0.5rem',
          md: '2rem'
        }
      }
      padding={
        {
          xs: '0.5rem',
          md: '2rem'
        }
      }
      flexDirection={
        {
          xs: 'column',
          md: 'row'
        }
      }>
      <Stack gap={{ xs: '0.5rem', md: '2rem' }} >
        <AgencySummary
          agency={agency}
        />
        <LimitCap />
      </Stack>
      <Stack gap={'0.5rem'} style={{ width: '100%' }}>
        <ServiceOverview
          onServiceRefresh={onServiceRefresh}
          agency_name={agency.agency_name}
        />
        <ComplianceOfficersTable
          agency={agency}
        />
      </Stack>
    </Stack>
  );
}


export default AgencyDetails;