import React from 'react';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material';

interface StyledLinearProgressBarProps {
    customColor?: string;
    backgroundColor?: string;
}

const StyledLinearProgressBar = styled(LinearProgress)<StyledLinearProgressBarProps>(
    ({ theme, customColor, backgroundColor }) => ({
        height: '0.5rem',
        borderRadius: '0.5rem',
        gap: '0.5rem',
        alignItems: 'center',
        width: '8.5rem',
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: backgroundColor || theme.palette.mode[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: '0.5rem',
            backgroundColor: customColor || (theme.palette.mode === 'light' ? '#00B2FF' : '#308fe8'),
        },
    })
);

interface ProgressBarProps {
    value: number | undefined;
    total: number | undefined;
}

const AdminCustomProgressBar: React.FC<ProgressBarProps> = ({ value, total}) => {
  const progressValue = (total === undefined || value === undefined) ? 0 : (value / total) * 100;
  let customColor;
  let backgroundColor;
  if(progressValue >= 80) {
    customColor = '#e32c2c';
    backgroundColor = '#ffc8c8'
  }
  return(
    <StyledLinearProgressBar
      variant="determinate"
      value={progressValue}
      customColor={customColor}
      backgroundColor={backgroundColor}
    />
  )
};

export default AdminCustomProgressBar;
