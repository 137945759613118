import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  IconButton,
  Popover,
  Stack,
  Typography
} from "@mui/material";
import { images } from "../../../utils/constants/images";
import { URLS } from "../../../utils/constants/urls";
import axiosInstance from "../../../utils/axios";
import {
  getTotalNewNotificationsCount,
  isNewNotification,
  setNotificationMessage,
  setWebSocketData
} from "../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import { User, UserRole, } from "../../../utils/redux/reducer/authentication-slice";
import avatar from "../../../utils/Avatar/avatar";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { agency, routes } from "../../../utils/constants/routes";
import SubNotifications from "./SubNotifications";
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const NotificationListing: FC = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const Navigate = useNavigate();

  const [notifications, setNotifications] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [totalUnseenCount, setTotalUnseenCount] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isMarking, setIsMarking] = useState<boolean>(false);

  const [isBlinking, setIsBlinking] = useState(false);

  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  const [openRows, setOpenRows] = useState<number | null>(null);
  const role = useSelector(UserRole);
  const user: { [key: string]: string } = useSelector(User) as {
    [key: string]: string;
  };
  const newNotification = useSelector(isNewNotification);
  const notificationCount = useSelector(getTotalNewNotificationsCount);

  // Updated badge behavior logic
  const shouldBlink = newNotification && totalUnseenCount === 0;
  const shouldShowStaticRedDot = totalUnseenCount > 0;

  const originalTitleRef = useRef(document.title);

  useEffect(() => {
    // Log the change in newNotification
    if (newNotification) {
      let intervalId: NodeJS.Timeout | null = null;

      if (id) {
        getNotifications(true)
        dispatch(setWebSocketData({
          new_notification: false,
          is_tab_notification: false,
          total_new_notifications: 0
        }));
      }

      // Define the function to handle visibility change
      const handleVisibilityChange = () => {
        if (document.hidden && notificationCount > 0) {
          // Update the document title and set an interval to blink the title
          document.title = `(${notificationCount}) Notifications - KED`;
          intervalId = setInterval(() => {
            document.title = document.title === originalTitleRef.current ? `(${notificationCount}) Notifications - KED` : originalTitleRef.current;
          }, 3000);
        } else {
          // Clear the interval and restore the original title
          if (intervalId) {
            clearInterval(intervalId);
          }
          document.title = originalTitleRef.current;
        }
      };

      // Add the visibility change event listener
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Clean up the interval and event listener when the component unmounts or newNotification changes
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }

  }, [newNotification, notificationCount]);

  // Blinking effect when newNotification is true and no unseen notifications
  useEffect(() => {
    if (shouldBlink) {
      // Define the interval that toggles the badge visibility every 800ms (or adjust as needed)
      const intervalId = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 800);

      // Cleanup the interval when the component unmounts or when blinking should stop
      return () => clearInterval(intervalId);
    } else {
      setIsBlinking(false); // Ensure it's not blinking if condition doesn't meet
    }
  }, [shouldBlink]);

  const handleToggle = (index: number) => {
    setOpenRows((prevIndex) => (prevIndex === index ? null : index));

  }

  useEffect(() => {
    if (openRows === null) {
      getNotifications(true);
    }
  }, [openRows]);

  const getNotifications = useCallback(async (silent: boolean = false) => {
    const limit = 5;
    const page = 1;
    if (!silent) {
      setLoading(true);
    }
    try {
      if (role === 'agency') {
        const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_V3_API_URL}/authorized/notification?page=${page}&limit=${limit}`);
        if (response.status === 200) {
          const notifications = Object.values(response.data.notifications);
          notifications.sort((a: any, b: any) => a.seen - b.seen);
          setNotifications(notifications);
          setTotalCount(response.data.total_count);
          setTotalUnseenCount(response.data.total_notification_unseen);
          setNotificationsCount(response.data.total_count);
        }
      } else {
        const { status, data } = await axiosInstance.get(URLS.notification);
        if (status === 200) {
          let information = data.user_notification_list.sort((a: any, b: any) => b.created_at - a.created_at)
          setNotifications(information);
        }
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      console.log(e);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Unable to get notifications",
        })
      );
    }
  }, [dispatch, role]);
  //
  // const getNotificationsCount = useCallback(async () => {
  //   setLoading(true);
  //   try {
  //     const { status, data } = await axiosInstance.get(URLS.notification_count);
  //     if (status === 200) {
  //       setNotificationsCount(data.data);
  //     }
  //     setLoading(false);
  //   } catch (e: any) {
  //     setLoading(false);
  //     console.log(e);
  //     dispatch(
  //       setNotificationMessage({
  //         display: true,
  //         severity: "error",
  //         message: "Unable to get notifications count",
  //       })
  //     );
  //   }
  // }, [dispatch]);

  // useEffect(() => {
  //   (async () => {
  //     await getNotificationsCount();
  //   })();
  // }, [getNotificationsCount]);

  const handleClick = async (event: MouseEvent<HTMLButtonElement>) => {
    setOpenRows(null);
    setAnchorEl(event.currentTarget);
    setTotalUnseenCount(0);
    await getNotifications();
    dispatch(setWebSocketData({
      new_notification: false,
      is_tab_notification: false,
      total_new_notifications: 0
    }));
  };

  const handleClose = () => {
    setNotifications([]);
    setAnchorEl(null);
  };

  const navigate = async (candidateId: string, requestId: string) => {
    if (id) {
      await markNotificationsAsSeen(candidateId, requestId)
      Navigate(`${agency.requests}/${requestId}/applicants/${candidateId}`);
      handleClose();
    }
  };

  const markNotificationsAsSeen = async (candidateId: String, requestId: String) => {
    try {
      await axiosInstance.post(`${process.env.REACT_APP_NODE_V3_API_URL}/notification/candidate/${candidateId}/${requestId}`);
    } catch (error) {
      console.error('Error marking notifications as seen:', error);
    }
  };
  const markAllNotificationsAsSeen = async () => {
    try {
      setIsMarking(true)
      await axiosInstance.post(`${process.env.REACT_APP_NODE_V3_API_URL}/notification/mark-all/${user.sub}`);
      getNotifications(true)
      setIsMarking(false)

    } catch (error) {
      console.error('Error marking notifications as seen:', error);
    }
  };


  return (
    <>
      <div>
        <Badge color="error" sx={{
          '& .MuiBadge-badge': {
            background: shouldShowStaticRedDot ? 'red' : 'transparent',
            position: 'relative',
            top: '8px',
            right: '30px'
          }

        }}
          variant="dot"
          invisible={!shouldShowStaticRedDot && !isBlinking}>
          <Button
            aria-describedby={id}
            onClick={handleClick}
            sx={{ backgroundColor: "white", boxShadow: "none" }}
            data-tut="notification_btn"
          >
            <NotificationsOutlinedIcon sx={{ color: newNotification ? '#5AB9F9' : '#828282' }} />
          </Button>
        </Badge>

      </div>
      <Popover
        id={id}
        open={openPopOver}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Card
          sx={{
            maxWidth: 500,
            minWidth: {
              xs: '100%',
              md: 500
            },
            minHeight: 400,
            maxHeight: 'calc(100vh - 120px)',
            borderRadius: "12px",
            filter:
              "drop-shadow(0px 4.8px 14.4px rgba(47, 60, 69, 0.18)) drop-shadow(0px 25.6px 57.6px rgba(47, 60, 69, 0.22))",
          }}
        >
          {loading && <Stack alignItems={"center"} justifyContent={"center"} width={"100%"} height={"100%"} sx={{
            position: 'absolute',
            background: 'rgba(255, 255, 255, 0.5)',
            zIndex: 3
          }}>
            <CircularProgress />
          </Stack>
          }
          {notifications.length === 0 && !loading ? (
            <Grid textAlign={"center"} sx={{ p: 3 }}>
              <img
                src={images.emptyIcon}
                alt={"no notifications"}
                className={"vertical-align-middle empty-image"}
              />
              <Typography variant={"body2"}>No notifications found</Typography>
            </Grid>
          ) : (
            <>
              <CardHeader
                sx={{ boxShadow: "0px 1px 1px rgba(182, 204, 217, 0.25)" }}
                avatar={
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    <Typography
                      fontWeight="fontWeightBold">
                      Notifications
                    </Typography>
                    <Chip
                      color="primary"
                      label={totalUnseenCount}
                      size="small"
                      variant={"outlined"}
                    />
                  </Stack>
                }
                action={
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                    {/*{totalUnseenCount > 0 && !loading &&*/}
                    {/*  <Button variant="outlined" disabled={isMarking} onClick={() => markAllNotificationsAsSeen()}>*/}
                    {/*    MARK ALL AS READ*/}
                    {/*  </Button>*/}
                    {/*}*/}
                    {role === 'agency' && (
                      <Button
                        sx={{
                          color: '#333',
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        }}
                        variant="text"
                        onClick={() => { Navigate(agency.notifications); handleClose(); }}>
                        View All
                      </Button>
                    )}
                  </Stack>
                }
              />
              <Divider />
              {role === 'applicant' && (
                notifications.map((notification) => (
                  <CardContent>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: " #727272",
                        }}
                      >
                        {dayjs(new Date(notification.created_at)).format("HH:mm")}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 400,
                          fontSize: "12px",
                          color: " #727272",
                          marginLeft: "auto",
                        }}
                      >
                        {dayjs(new Date(notification.created_at)).format(
                          "DD-MM-YYYY"
                        )}
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "#333333",
                      }}
                    >
                      {notification.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#333333",
                        fontSize: "14px",
                        fontWeight: 400,
                        marginTop: "8px",
                      }}
                    >
                      {notification.message}
                    </Typography>
                  </CardContent>
                ))
              )}
              {role === 'agency' && (
                <CardContent sx={{ maxHeight: 'calc(100vh - 120px)', padding: 0, paddingBottom: '3rem' }}>
                  {notifications.map((notificationGroup: any, index: number) => (
                    <React.Fragment key={index}>
                      <Stack sx={{ padding: '1rem' }}>
                        <Stack
                          direction={'row'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          sx={{ opacity: notificationGroup.total_unseen === 0 ? '0.7' : 1, width: '100%' }}
                        >
                          <Stack
                            direction={'row'}
                            gap={'1rem'}
                            alignItems={'center'}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(notificationGroup.candidate_id, notificationGroup.request_id);
                            }}
                          >
                            <Avatar {...avatar(`${notificationGroup.candidate_name}`)} />
                            <Stack>
                              <Typography fontWeight="fontWeightBold">
                                {notificationGroup.candidate_name}
                              </Typography>

                              {notificationGroup.total_unseen == 0 ? (
                                <Typography fontSize={'0.75rem'}>
                                  No recent activity
                                  on <strong>{notificationGroup.request_info.name} ({notificationGroup.request_info.designation})</strong>.
                                </Typography>
                              ) : (
                                <Typography fontSize={'0.75rem'}>
                                  There is a new activity in
                                  application <strong>{notificationGroup.request_info.name} ({notificationGroup.request_info.designation})</strong>.
                                </Typography>
                              )
                              }
                            </Stack>
                          </Stack>
                          <Stack
                            direction={'row'}
                            alignItems={'center'}
                            gap={'1rem'}
                          >
                            <Chip
                              color={notificationGroup.total_unseen > 0 ? "primary" : "default"}
                              label={notificationGroup.total_unseen}
                              size="small"
                              style={{ color: 'white' }}
                            />
                            <IconButton onClick={() => {

                              handleToggle(index);
                            }}>
                              {openRows === index ?
                                <ExpandLessIcon /> : <ExpandMoreIcon />
                              }
                            </IconButton>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Divider />
                      <Stack>
                        <Stack sx={{ backgroundColor: '#F6F6F6', alignItems: 'center' }}>
                          <Collapse in={openRows === index} timeout="auto" unmountOnExit sx={{ alignItems: 'center', width: '100%' }}>

                            <SubNotifications handleClose={handleClose} notificationGroup={notificationGroup}
                              navigate={navigate} showArrows={true} />

                          </Collapse>
                        </Stack>

                      </Stack>
                    </React.Fragment>
                  ))}
                </CardContent>
              )}
            </>
          )}
        </Card>
      </Popover>
    </>
  );
};

export default NotificationListing;