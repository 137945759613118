import {
  Stack,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  CircularProgress,
  IconButton
} from "@mui/material";
import { Card } from "react-bootstrap";
import { FC } from "react";
import { ServicesOverviewProps } from "../Interfaces/AgencyInterfaces";
import {
  GetServiceDataLoading,
  GetServicesData,
} from "../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";
import { useDispatch, useSelector } from "react-redux";
import ServiceCategory from '../Components/AgencyDetails/ServiceOverviewCategory';
import RefreshIcon from '@mui/icons-material/Refresh';

const ServiceOverview: FC<ServicesOverviewProps> = ({ onServiceRefresh, agency_name }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isLoading = useSelector(GetServiceDataLoading);
  const servicesData = useSelector(GetServicesData);
  console.log('isLoading:', isLoading);
  console.log('servicesData:', servicesData);

  const handleRefresh = async () => {
    console.log("...Refreshed");
    onServiceRefresh();
  };

  return (
    <Card style={{ width: '100%' }}>
      <Stack flexDirection={'row'} justifyContent={'space-between'}>
        <Typography padding={'1rem'} fontWeight={'bold'} fontSize={'1rem'}>
          Services Overview
        </Typography>
        <IconButton color="primary" onClick={handleRefresh}>
          <RefreshIcon />
        </IconButton>
      </Stack>
      <Divider />
      {isLoading && (
        <Stack alignItems="center" justifyContent="center" minHeight={200} minWidth={{
          xs: "100%",
          md: 600
        }}>
          <CircularProgress size={40} />
        </Stack>
      )}
      {!isLoading && servicesData && (
        <Stack padding={'1rem 1.5rem'} gap={{ xs: '1.75rem', md: '3.5rem' }} flexDirection={{ sx: 'column', md: 'row' }}>

          <Stack gap={'1.75rem'} width={'100%'}>
            <ServiceCategory
              label="RTW Services"
              count={servicesData.rtw_services.total}
              details={[
                {
                  label: 'On Going',
                  value: servicesData.rtw_services.on_going.total,
                  count: [
                    { label: 'Right to Work', value: servicesData.rtw_services.on_going.rtw },
                    { label: 'DBS', value: servicesData.rtw_services.on_going.dbs },
                    { label: 'Right to Work + DBS', value: servicesData.rtw_services.on_going.dbs_rtw },
                  ],
                  
                },
                {
                  label: 'Submitted',
                  value: servicesData.rtw_services.submitted.total,
                  count: [
                    { label: 'Right to Work', value: servicesData.rtw_services.submitted.rtw },
                    { label: 'DBS', value: servicesData.rtw_services.submitted.dbs },
                    { label: 'Right to Work + DBS', value: servicesData.rtw_services.submitted.dbs_rtw },
                  ],
                  
                },
                {
                  label: 'Success',
                  value: servicesData.rtw_services.completed.total,
                  count: [
                    { label: 'Right to Work', value: servicesData.rtw_services.completed.rtw },
                    { label: 'DBS', value: servicesData.rtw_services.completed.dbs },
                    { label: 'Right to Work + DBS', value: servicesData.rtw_services.completed.dbs_rtw },
                  ],
                  
                },
                {
                  label: 'Rejected',
                  value: servicesData.rtw_services.failed.total,
                  count: [
                    { label: 'Right to Work', value: servicesData.rtw_services.failed.rtw },
                    { label: 'DBS', value: servicesData.rtw_services.failed.dbs },
                    { label: 'Right to Work + DBS', value: servicesData.rtw_services.failed.dbs_rtw },
                  ],
                  
                },
              ]}
            />
            <Divider />
            <ServiceCategory
              label="E-Sign Services"
              count={servicesData.esign_services.total}
              details={[
                {
                  count: [
                    { label: 'Created', value: servicesData.esign_services.created },
                    { label: 'Signed', value: servicesData.esign_services.signed },
                    { label: 'Rejected', value: servicesData.esign_services.rejected },
                  ],
                  
                },
              ]}
            />
          </Stack>


          {isMobile ? (
            <Divider />
          ) : (
            <Divider orientation="vertical" flexItem />
          )}


          <Stack gap={'1.75rem'} width={'100%'}>
            <ServiceCategory
              label="DBS Services"
              count={servicesData.ucheck_services.total}
              details={[
                {
                  label: 'Submitted',
                  value: servicesData.ucheck_services.submitted.total,
                  count: [
                    { label: 'Enhanced', value: servicesData.ucheck_services.submitted.enhanced },
                    { label: 'Enhanced + Workforce', value: servicesData.ucheck_services.submitted.enhanced_workforce },
                    { label: 'Standard', value: servicesData.ucheck_services.submitted.standard },
                    { label: 'Basic', value: servicesData.ucheck_services.submitted.basic },
                    { label: 'Scottish', value: servicesData.ucheck_services.submitted.scottish },
                  ],
                  
                },
                {
                  label: 'In Review',
                  value: servicesData.ucheck_services.in_review.total,
                  count: [
                    { label: 'Enhanced', value: servicesData.ucheck_services.in_review.enhanced },
                    { label: 'Enhanced + Workforce', value: servicesData.ucheck_services.in_review.enhanced_workforce },
                    { label: 'Standard', value: servicesData.ucheck_services.in_review.standard },
                    { label: 'Basic', value: servicesData.ucheck_services.in_review.basic },
                    { label: 'Scottish', value: servicesData.ucheck_services.in_review.scottish },
                  ],
                  
                },
                {
                  label: 'Approved',
                  value: servicesData.ucheck_services.approved.total,
                  count: [
                    { label: 'Enhanced', value: servicesData.ucheck_services.approved.enhanced },
                    { label: 'Enhanced + Workforce', value: servicesData.ucheck_services.approved.enhanced_workforce },
                    { label: 'Standard', value: servicesData.ucheck_services.approved.standard },
                    { label: 'Basic', value: servicesData.ucheck_services.approved.basic },
                    { label: 'Scottish', value: servicesData.ucheck_services.approved.scottish },
                  ],
                  
                },
              ]}
            />
            <Divider />
            <ServiceCategory
              label="References"
              details={[
                {
                  count: [
                    { label: 'Total Applicants', value: servicesData.references.total_applicants },
                    { label: 'Total References', value: servicesData.references.total_references },
                  ],
                  
                },
              ]}
            />
            <Divider />
            <ServiceCategory
              label="Documents"
              details={[
                {
                  count: [
                    { label: 'Total Applicants', value:  servicesData.documents.total_applicants },
                    { label: 'Total Documents', value: servicesData.documents.total_documents },
                  ],
                  
                },
              ]}
            />
          </Stack>
        </Stack>
      )}
    </Card>
  );
}

export default ServiceOverview;
