import {Stack, Typography} from "@mui/material";
import {FC} from "react";
import AdminCustomProgressBar from "./ProgressBar";

interface LimitCapItemProps {
  title: string,
  cap?: number,
  total?: number
}

const LimitCapItem: FC<LimitCapItemProps> = ({title, cap, total}) => {
  return(
    <Stack gap={'0.5rem'}>
      <Typography fontSize={'1rem'} lineHeight={'1.5rem'} fontWeight={500}>{title}</Typography>
      <Stack justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'}>
        <AdminCustomProgressBar value={total} total={cap} />
        <Typography fontSize={'1rem'} lineHeight={'1.5rem'} fontWeight={500}>{total}/{cap}</Typography>
      </Stack>
    </Stack>
  )
}

export default LimitCapItem
