import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Divider,
  Typography,
} from "@mui/material";
import avatar from "../../../../../../../utils/Avatar/avatar";
import dayjs from "dayjs";

interface ServiceInfoReferenceTabProps {
  activeTab: number;
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
  services: any;
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const chipOptions = [
  { label: "All", value: "All" },
  { label: "Employment", value: "EMPLOYMENT" },
  { label: "Character", value: "CHARACTER" },
  { label: "Academic", value: "ACADEMIC" },
];

const statusOptions = [
  { label: "All", value: "All" },
  { label: "Approved", value: "Approved" },
  { label: "Bounced", value: "Bounced" },
  { label: "Declined", value: "Declined" },
  { label: "Completed", value: "Completed" },
];

const ServiceInfoReferenceTab: FC<ServiceInfoReferenceTabProps> = ({
  activeTab,
  activeFilterTab,
  setActiveFilterTab,
  services,
  page,
  rowsPerPage,
  isLoading,
  handleChangePage,
}) => {
  const [type, setType] = useState("All");
  const [status, setStatus] = useState("All");
  const [searchName, setSearchName] = useState("");
  const [filteredServices, setFilteredServices] = useState<any>([]);

  const handleChipClick = (newType: string) => {
    setType(newType);
  };

  const handleStatusChange = (event: any) => {
    setStatus(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  useEffect(() => {
    let filtered = services;

    // Filter by type
    if (type !== "All") {
      filtered = filtered.filter(
        (service: any) => service.type.toUpperCase() === type.toUpperCase()
      );
    }

    // Filter by status
    if (status !== "All") {
      filtered = filtered.filter((service: any) => {
        const serviceStatus = service.is_approved
          ? "Approved"
          : service.is_deleted
          ? "Deleted"
          : service.is_completed
          ? "Completed"
          : service.is_bounced
          ? "Bounced"
          : service.is_declined
          ? "Declined"
          : service.status || "Pending";
        return serviceStatus === status;
      });
    }

    // Filter by applicant name
    if (searchName) {
      const searchTerm = searchName.toLowerCase();
      filtered = filtered.filter((service: any) =>
        service.candidate_name.toLowerCase().includes(searchTerm) ||
        service.candidate_email?.toLowerCase().includes(searchTerm) ||
        service.request_name.toLowerCase().includes(searchTerm)
      );
    }

    setFilteredServices(filtered);
  }, [type, status, searchName, services]);

  const Chips = React.useMemo(() => (
    <Stack direction="row" spacing={1} alignItems="center" gap={"0.25rem"}>
      {chipOptions.map((chip) => (
        <Chip
          key={chip.value}
          label={chip.label}
          clickable
          variant={"outlined"}
          color={type === chip.value ? "primary" : "default"}
          onClick={() => handleChipClick(chip.value)}
          sx={{ paddingLeft: "0.65rem", paddingRight: "0.65rem" }}
        />
      ))}
    </Stack>
  ), [type]);

  return (
    <>
      {isLoading && (
        <Stack p={"1rem"} alignItems={"center"} justifyContent={"center"}>
          <CircularProgress size={20} />
        </Stack>
      )}
      {!isLoading && (
        <>
          <Divider sx={{ margin: 0 }} />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              padding: "16px",
              background: "linear-gradient(90.5deg, #E9FAFF 5.76%, #F0F0FF 50.43%)",
              overflowY: "auto",
              boxSizing: "border-box",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                borderRadius: "12px",
                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                paddingBottom: "16px",
                width: "100%",
                maxHeight: "auto",
                overflowY: "auto",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell colSpan={2}>
                      {Chips}
                    </TableCell>
                    <TableCell colSpan={4}>
                      <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="flex-end"
                        width="100%"
                      >
                        <TextField
                          label="Search by Applicant Name"
                          variant="outlined"
                          value={searchName}
                          size="small"
                          onChange={handleSearchChange}
                          sx={{ flex: 1, maxWidth: 250 }}
                        />
                        <FormControl sx={{ minWidth: 120 }}>
                          <InputLabel id="status-label">Status</InputLabel>
                          <Select
                            labelId="status-label"
                            value={status}
                            label="Status"
                            size="small"
                            onChange={handleStatusChange}
                          >
                            {statusOptions.map((status) => (
                              <MenuItem key={status.value} value={status.value}>
                                {status.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Typography>Total: {filteredServices.length}</Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Applicant Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Request Name</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Created At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredServices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar {...avatar(row.candidate_name || "Unknown")} />
                            <Stack>
                              <Typography>{row.candidate_name || "No Name"}</Typography>
                              <Typography fontSize="14px" color="text.secondary">
                                {row.candidate_email || "No Email"}
                              </Typography>
                            </Stack>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Chip label={row.type} clickable variant={"outlined"} />
                        </TableCell>
                        <TableCell>
                          <Typography>
                            {row.is_approved
                              ? "Approved"
                              : row.is_deleted
                              ? "Deleted"
                              : row.is_completed
                              ? "Completed"
                              : row.is_bounced
                              ? "Bounced"
                              : row.is_declined
                              ? "Declined"
                              : row.status || "Pending"}
                          </Typography>
                        </TableCell>
                        <TableCell>{row.request_name || "N/A"}</TableCell>
                        <TableCell>{row.designation || "N/A"}</TableCell>
                        <TableCell>{dayjs(row.created_at).format("DD MMM YYYY")}</TableCell>
                      </TableRow>
                    ))}

                  {filteredServices.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <Typography>No Applicant Found</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={filteredServices.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[]}
                labelRowsPerPage={""}
                sx={{
                  position: "relative",
                  top: "-5px",
                  marginTop: "0",
                  marginBottom: "16px",
                }}
              />
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ServiceInfoReferenceTab;
