import { FC } from "react";
import {
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Divider,
    Chip,
    Avatar,
    useTheme,
    useMediaQuery,
} from "@mui/material";
import { Card } from "react-bootstrap";
import avatar from "../../../../../utils/Avatar/avatar";
import { images } from "../../../../../utils/constants/images";
import { PersonAddAlt } from "@mui/icons-material";
import { ComplianceOfficersTableProps } from "../Interfaces/AgencyInterfaces";
import { setIsAddComplianceOfficerDialogOpened } from "../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";
import { useDispatch } from "react-redux";

const ComplianceOfficersTable: FC<ComplianceOfficersTableProps> = ({
    agency
}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const handleOpenAddComplianceOfficerDialog = () => {
        dispatch(setIsAddComplianceOfficerDialogOpened(true))
    }

    return (
        <Card style={{ width: '100%', height: '100%' }}>
            <Stack flexDirection={'row'} justifyContent={'space-between'} padding={'1rem'} alignItems={'center'}>
                <Typography>
                    Compliance Officer ({agency.compliance_officer_count})
                </Typography>
                {agency.compliance_officers.length < 5 && (
                    <Button
                        variant="outlined"
                        startIcon={<PersonAddAlt />}
                        onClick={handleOpenAddComplianceOfficerDialog}
                    >
                        ADD
                    </Button>
                )}
            </Stack>
            <Divider />
            <Stack padding={'1rem 0rem'} gap={'1rem'}>
                {agency.compliance_officers.length > 0 ? (
                    <TableContainer>
                        <Table size="small" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    {!isMobile &&
                                        <TableCell>Email</TableCell>
                                    }
                                    <TableCell>Total Request</TableCell>
                                    {!isMobile &&
                                        <TableCell>Status</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agency.compliance_officers.map((officer) => (
                                    <TableRow
                                        key={officer.compliance_name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            <Stack flexDirection={'row'} alignItems={'center'} gap={'0.5rem'}>
                                                <Avatar {...avatar(officer.compliance_name)} />
                                                <Stack flexDirection={'column'}>
                                                    {officer.compliance_name}
                                                    {isMobile &&
                                                        <>
                                                            <Typography fontSize={'0.75rem'}>
                                                                {officer.compliance_email}
                                                            </Typography>
                                                        </>
                                                    }
                                                </Stack>
                                            </Stack>
                                        </TableCell>
                                        {!isMobile &&
                                            <TableCell>{officer.compliance_email}</TableCell>
                                        }
                                        <TableCell><Chip label={officer.request_count} /></TableCell>
                                        {!isMobile &&
                                            <TableCell><Chip
                                                label={officer.status ? "Active" : "Blocked Access"}
                                                color={officer.status ? "success" : "error"}
                                                style={{ color: "white" }}
                                            /></TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Stack alignItems="center" padding="2rem" >
                        <img
                            src={images.emptyIcon}
                            alt="no request"
                            className="vertical-align-middle empty-image mb-2"
                        />
                        <Typography fontSize="1rem" fontWeight={500} color="#61677A">
                            No Compliance Officers Found
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
}


export default ComplianceOfficersTable;