import { createSlice } from '@reduxjs/toolkit';
import {
  AgencyDetailsModel,
  ServicesData,
  FilteredDateProps
} from "../../../../screens/Authenticated/Admin/Agency/Interfaces/AgencyInterfaces";



interface AdminAgencySliceProps {
  isLimitCapChangeDialogOpened: boolean;
  isHistoryDialogOpened: boolean;
  isCreateAgencyDialogOpened: boolean;
  isEditAgencyDialogOpened: boolean;
  isAddCompianceOfficerDialogOpened: boolean;
  openedAgency?: AgencyDetailsModel,
  editAgencyData?: AgencyDetailsModel | null;
  servicesData?: ServicesData,
  isServiceDataLoading: boolean,
  filteredDate: FilteredDateProps;
}
const adminAgencySlice = createSlice({
  name: 'adminAgencySlice',
  initialState: {
    isLimitCapChangeDialogOpened: false,
    isHistoryDialogOpened: false,
    isCreateAgencyDialogOpened: false,
    isEditAgencyDialogOpened: false,
    isAddCompianceOfficerDialogOpened: false,
    openedAgency: null,
    editAgencyData: null,
    servicesData: null,
    isServiceDataLoading: true,
    filteredDate: {
      start_date: null,
      end_date: null
    }
  },
  reducers: {
    setLimitCapChangeDialog(state, action) {
      state.isLimitCapChangeDialogOpened = action.payload;
    },
    setOpenedAgency(state, action) {
      state.openedAgency = action.payload;
    },
    setIsCreateAgencyDialogOpened(state, action) {
      state.isCreateAgencyDialogOpened = action.payload;
    },
    setEditAgencyDialogOpened(state, action) {
      state.isEditAgencyDialogOpened = action.payload;
    },
    setIsAddComplianceOfficerDialogOpened(state, action) {
      state.isAddCompianceOfficerDialogOpened = action.payload;
    },
    setIsHistoryDialogOpened(state, action) {
      state.isHistoryDialogOpened = action.payload;
    },
    setServicesData(state, action) {
      state.servicesData = action.payload;
    },
     setEditAgencyData(state, action) {
      state.editAgencyData = action.payload;
    },
    setServicesDataLoading(state, action) {
      state.isServiceDataLoading = action.payload;
    },
    setFilteredDate(state, action) {
      state.filteredDate = action.payload;
    },
  }
})
export default adminAgencySlice.reducer;

export const {
  setLimitCapChangeDialog,
  setEditAgencyDialogOpened,
  setOpenedAgency,
  setIsHistoryDialogOpened,
  setIsAddComplianceOfficerDialogOpened,
  setIsCreateAgencyDialogOpened,
  setEditAgencyData,
  setServicesData,
  setFilteredDate,
  setServicesDataLoading } = adminAgencySlice.actions;

// limit cap dialog
export const IsLimitCapChangeDialogOpened = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isLimitCapChangeDialogOpened;
}
// get opened agency
export const GetOpenedAgency = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.openedAgency;
}
// history dialog
export const IsHistoryDialogOpened = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isHistoryDialogOpened;
}
// create agency dialog
export const IsCreateAgencyDialogOpened = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isCreateAgencyDialogOpened;
}
// create edit agency dialog
export const IsAddComplianceOfficerDialogOpened = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isAddCompianceOfficerDialogOpened;
}
// create edit agency dialog
export const IsEditAgencyDialogOpened = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isEditAgencyDialogOpened;
}
// get edit agency data
export const GetEditAgencyData = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.editAgencyData;
}
// get services data
export const GetServicesData = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.servicesData;
}
// get services data
export const GetServiceDataLoading = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.isServiceDataLoading;
}

export const GetFilteredDate = (state: {
  adminAgencySlice: AdminAgencySliceProps;
}) => {
  return state.adminAgencySlice.filteredDate;
}
