// Import necessary modules
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { setWebSocketData, UserRole } from "../redux";
import {useDispatch, useSelector} from "react-redux";
import { User } from "../redux/reducer/authentication-slice";

// Define the WebSocket context type
interface WebSocketContextType {
  ws: WebSocket | null;
  sendMessage: (action: string, data: any) => void;
}

// Create the WebSocket context
const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

// Custom hook to access the WebSocket context
export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};

// Define the props for the WebSocketProvider component
interface WebSocketProviderProps {
  children: ReactNode;
}
const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3001';

// WebSocketProvider component
export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const role = useSelector(UserRole);
  const user: { [key: string]: string } = useSelector(User) as { [key: string]: string };
  const dispatch = useDispatch();

  // State to manage reconnection attempts
  const [reconnectTimeout, setReconnectTimeout] = useState<NodeJS.Timeout | null>(null);

  // Effect to establish the WebSocket connection
  useEffect(() => {
    // Create a function to establish a WebSocket connection
    const establishConnection = () => {
      // Check if the user information is available before establishing the connection
      if (user && user.sub) {
        // Create a new WebSocket connection
        const socket = new WebSocket(wsUrl);

        // Event listener for when the WebSocket connection is open
        socket.onopen = () => {
          console.log('WebSocket connected');
          setWs(socket);
          clearReconnectTimeout(); // Clear any ongoing reconnection attempts
          const message = JSON.stringify({ action: "connection", user_id: user.sub, role: role });
          socket.send(message);
        };

        // Event listener for when the WebSocket connection is closed
        socket.onclose = () => {
          console.log('WebSocket disconnected');
          setWs(null);
          if(process.env.REACT_APP_ENV_NAME !== 'dev'){
            attemptReconnection(); // Try to reconnect
          }
        };

        // Event listener for incoming messages
        socket.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log(data);
          if (data && data.front_end_action === 'new-notification') {
            dispatch(setWebSocketData({
              new_notification: true,
              is_tab_notification: true,
              total_new_notifications: data.count,
            }));
          }
        };

        // Clean-up function
        return () => {
          if (ws) {
            ws.close();
          }
        };
      }
    };

    // Define the function for attempting reconnection
    const attemptReconnection = () => {
      const reconnectDelay = 2000; // Delay reconnection by 5 seconds
      const timeoutId = setTimeout(() => {
        console.log('Attempting to reconnect...');
        establishConnection(); // Try to re-establish the connection
      }, reconnectDelay);
      setReconnectTimeout(timeoutId);
    };

    // Clear any ongoing reconnection attempts
    const clearReconnectTimeout = () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
        setReconnectTimeout(null);
      }
    };

    // Call the establishConnection function initially
    establishConnection();

    // Clean-up function
    return () => {
      clearReconnectTimeout(); // Clear any ongoing reconnection attempts
      if (ws) {
        ws.close(); // Close the WebSocket connection if it exists
      }
    };
  }, [user, role]); // Dependencies: user and role

  // Function to send a message through the WebSocket connection
  const sendMessage = (action: string, data: any): void => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({ action, data });
      ws.send(message);
    }
  };

  // Provide the WebSocket context value to the children components
  return (
    <WebSocketContext.Provider value={{ ws, sendMessage }}>
      {children}
    </WebSocketContext.Provider>
  );
};
