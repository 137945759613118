import React, { useCallback, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogActions,
    Divider,
    Button,
    CircularProgress,
    Stack,
    TextField,
    IconButton,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeLimitGapProps } from "../Interfaces/AgencyInterfaces";
import axiosInstance from "../../../../../utils/axios";
import {
    setLimitCapChangeDialog,
    setNotificationMessage
} from "../../../../../utils/redux";
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { GetOpenedAgency } from "../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";

const validationSchema = yup.object().shape({
    limits: yup.object().shape({
        rtw: yup.number().required('Required').positive('Must be a positive intiger.').typeError('Must contain a valid number.'),
        dbs: yup.number().required('Required').positive('Must be a positive intiger.').typeError('Must contain a valid number.'),
        esign: yup.number().required('Required').positive('Must be a positive intiger.').typeError('Must contain a valid number.'),
    }),
});

const ChangeLimitGap: React.FC<ChangeLimitGapProps> = ({ isOpen, onComplete }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const openedAgency = useSelector(GetOpenedAgency);

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            limits: {
                rtw: openedAgency?.limits.rtw.cap || 0,
                dbs: openedAgency?.limits.dbs.cap || 0,
                esign: openedAgency?.limits.esign.cap || 0,
            },
        },
    });

    const handleUpdateLimitGap = useCallback(async (data: any) => {
        setLoading(true);
        try {
            await axiosInstance.put(
                `${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies/${openedAgency?.agency_id}/limits`,
                data
            );
            handleClose();
            onComplete();
            dispatch(
                setNotificationMessage({
                    display: true,
                    severity: 'success',
                    message: 'Limit Gap Updated Successfully.',
                })
            );
        } catch (error) {
            console.error('Error updating limit gap:', error);
            dispatch(
                setNotificationMessage({
                    display: true,
                    severity: 'error',
                    message: 'Something went wrong while updating the limit gap. Please try again.',
                })
            );
        }
        setLoading(false);
    }, []
    );

    const handleClose = () => {
        reset();
        dispatch(setLimitCapChangeDialog(false))
    }

    return (
        <Dialog open={isOpen} onClose={handleClose} maxWidth='xs' fullWidth>
            <DialogTitle>
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <Typography fontSize={'1.25rem'} fontWeight={500} letterSpacing={'0.00938rem'} lineHeight={'160%'}>
                        Change Limit Cap
                    </Typography>
                    <IconButton color="primary" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <Stack padding={'1rem 1.5rem'} gap={'1.5rem'}>
                <Stack justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
                    <Typography lineHeight={'1.5rem'} fontWeight={500} fontSize={'1rem'}>Right to Work</Typography>
                    <Controller
                        name="limits.rtw"
                        control={control}
                        defaultValue={openedAgency?.limits.rtw.cap}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                variant="outlined"
                                sx={{ width: '4rem' }}
                                error={!!errors.limits?.rtw}
                                helperText={errors.limits?.rtw?.message}
                            />
                        )}
                    />
                </Stack>
                <Divider />
                <Stack justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
                    <Typography lineHeight={'1.5rem'} fontWeight={500} fontSize={'1rem'}>DBS Check</Typography>
                    <Controller
                        name="limits.dbs"
                        control={control}
                        defaultValue={openedAgency?.limits.dbs.cap}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                variant="outlined"
                                sx={{ width: '4rem' }}
                                error={!!errors.limits?.dbs}
                                helperText={errors.limits?.dbs?.message}
                            />
                        )}
                    />
                </Stack>
                <Divider />
                <Stack justifyContent={'space-between'} alignItems={'center'} flexDirection={'row'}>
                    <Typography lineHeight={'1.5rem'} fontWeight={500} fontSize={'1rem'}>E-Sign</Typography>
                    <Controller
                        name="limits.esign"
                        control={control}
                        defaultValue={openedAgency?.limits.esign.cap}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                size="small"
                                variant="outlined"
                                sx={{ width: '4rem' }}
                                error={!!errors.limits?.esign}
                                helperText={errors.limits?.esign?.message}
                            />
                        )}
                    />
                </Stack>
            </Stack>
            <Divider />
            <DialogActions>
                <Button
                    type="button"
                    onClick={handleSubmit(handleUpdateLimitGap)}
                    variant="contained"
                    disabled={loading}
                    style={{ color: 'white' }}>
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'UPDATE'}
                </Button>
                <Button disabled={loading} onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangeLimitGap;
