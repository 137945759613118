import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {
  Button,
  Card,
  Typography,
  Stack,
  TextFieldProps,
  CircularProgress,
  Grid,
  Paper,
  IconButton
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import Modal from "../../../../../../components/modal/Modal";
import InputField from "../../../../../../components/InputField";
import {Validators} from "../../../../../../utils/validators";
import DateInput from "../../../../../../components/DateInput/DateInput";
import {identifiers} from "../../../../../../utils/constants/identifiers";
import dayjs from "dayjs";
import PrimaryButton from "../../../../../../components/Button/PrimaryButton";
import axiosInstance from "../../../../../../utils/axios";
import {URLS} from "../../../../../../utils/constants/urls";
import {
  Reload,
  setCandidateProfileReload, setName,
  setNotificationMessage,
  setReload, setRequestData,
  setValue
} from "../../../../../../utils/redux";
import {useParams} from "react-router-dom";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CopyToClipboard from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {AddOutlined, UploadFile} from "@mui/icons-material";
import VettingDocNumberModal from "./VettingDocNumbers/VettingDocNumberModal";
import UploadFiles from "../../../../Candidate/ViewRequest/VettingDocuments/DocumentList/common/UploadFiles";

interface PeriodOfStayProps {
  active: number,
  documents: any
}

const AgencyAddRightWorkInfo: FC<PeriodOfStayProps> = ({active, documents}) => {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [cNumber, setCNumber] = useState<string>('');
  const [dob, setDob] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const {id, candidate_id} = useParams() as any;
  const reload = useSelector(Reload);
  const [formError, setFormError] = useState<boolean>(false);

  useEffect(() => {
    if (documents[active]) {
      setDob(documents[active].dbs_dob || '');
      setCNumber(documents[active].dbs_cnumber || '');
    }
  }, [active, documents]);

  const handleNumberChange = (value: string, field: string, error?: { error: boolean; message: string }) => {
    if (error && error.error) {
      setFormError(true);
    } else {
      setFormError(false);
    }
    setCNumber(value);
  };

  const handleDobChange = (value: string, field: string, error?: { error: boolean; message: string }) => {
    if (error && error.error) {
      setFormError(true);
    } else {
      setFormError(false);
    }
    setDob(value);
  };

  const onUploadCertificateComplete = (status: boolean) => {
    setUploadModal(false);
  }

  const handleSave = useCallback(async () => {
    if (!!dob || !!cNumber) {
      try {
        setLoading(true);
        await axiosInstance.patch(`${URLS.requests}/${id}/document`, {
          dbs_cnumber: cNumber,
          dbs_dob: dob,
          vetting_doc_id: documents[active].id,
          candidate_id: candidate_id,
        });
        setLoading(false);
        const both: boolean = !!dob && !!cNumber;
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "success",
            message: `${both ? "C-Number & Date of Birth" : !!dob ? "Date of Birth" : "C-Number"} saved successfully`,
          })
        );
        dispatch(setReload(!reload));
        dispatch(setCandidateProfileReload(!reload));
        setOpenModal(false);
      } catch (e: any) {
        setLoading(false);
        dispatch(
          setNotificationMessage({
            display: true,
            severity: "error",
            message: "There is an error in saving the document",
          })
        );
      }
    } else {
      dispatch(
        setNotificationMessage({
          display: true,
          severity: "error",
          message: "Please enter DOB / C-Number",
        })
      );
    }
  }, [dispatch, reload, dob, cNumber, id, documents, active, candidate_id]);

  const uploadModalContent = useMemo(() => {
    return (
      <UploadFiles showHub={false} showBack={false} onComplete={onUploadCertificateComplete}/>
    )
  }, [])
  const rtwModal = useMemo(() => {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              color: "#333333",
              letterSpacing: "0.15px",
            }}
          >
            C Number
          </Typography>
          <InputField
            sx={{mt: 1}}
            fullWidth
            focused
            textChange={handleNumberChange}
            disabled={loading}
            value={cNumber}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} sx={{paddingTop: '1rem'}}>
          <Typography
            variant="h6"
            sx={{
              fontSize: 16,
              fontWeight: 400,
              color: "#333333",
              letterSpacing: "0.15px",
            }}
          >
            Date of Birth
          </Typography>
          <DateInput
            field={"date"}
            disableFuture={true}
            textChange={handleDobChange}
            inputFormat={identifiers.date_format as string}
            onChange={(e) => e}
            value={dob || null}
            renderInput={(params: TextFieldProps) => (
              <InputField
                {...params}
                className={"py-0 mb-0"}
                inputProps={{
                  ...params.inputProps,
                  sx: {
                    p: "1rem",
                    fontWeight: "bold",
                    backgroundColor: "#FFFFFF",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    backgroundColor: "#FFFFFF",
                  },
                }}
              />
            )}
            validators={[
              {
                check: Validators.required,
                message: "This field is required",
              },
              {
                check: Validators.maxDate,
                message: "Please enter a valid Date",
                num: new Date(dayjs(new Date()).format("YYYY-MM-DD 00:00:00.000")).getTime(),
              },
            ]}
          />
        </Grid>
        <Grid container alignItems="right" justifyContent="right">
          <Stack gap={"1rem"} direction={"row"}>
            <Button
              sx={{
                mt: 1,
                background: "#5AB9F9",
                color: "#ffffff",
                fontSize: 16,
                fontWeight: 500,
                p: "10px 24px",
                '&:hover': {
                  background: "#3e9bda",
                },
                '&:disabled': {
                  background: "#e7e7e7",
                  color: '#8f8f8f'
                }
              }}
              disabled={loading || formError}
              onClick={handleSave}
            >
              Save Changes
            </Button>
            <Button
              disabled={loading}
              fullWidth={false}
              sx={{
                border: "none",
                color: "#000",
                fontSize: 16,
                fontWeight: 500,
                p: "10px",
              }}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  }, [cNumber, dob, loading, formError]);

  return (
    <>
      <Grid xs={12} container className="card-vetting" sx={{marginTop: '2rem !important'}}>
        <Grid
          container
          className="card-head-table"
          direction="row"
          justifyContent={"space-between"}
          alignItems={'center'}
          sx={{marginBottom: '0 !important'}}
        >
          <Grid
            item
            xs={12}
            sx={{pl: 1, pt: 1, pb: 2}}
          >

            <Typography
              variant={"body2"}
              fontSize={"16px"}
              fontWeight={"bold"}
              paddingTop={"10px"}
              sx={{pl: {xs: "18px", md: 2}}}
            >
              DBS Info
            </Typography>

          </Grid>
        </Grid>
        <Grid xs={12} item md={12}>
          <Grid
            container
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
          >

            <Stack sx={{
              backgroundColor: "#f5fbff",
              padding: '1.5rem 3rem',
              width: '100%',
              borderBottomRightRadius: "12px",
              borderBottomLeftRadius: "12px",
            }}>

              <Stack justifyContent={"space-between"} direction={"row"} sx={{flex: 1, padding: '1rem 2rem'}}
                     component={Paper}>

                {documents[active].dbs_dob || documents[active].dbs_cnumber ?
                  (
                    <>
                      {documents[active].dbs_cnumber &&
                        <Stack>

                          <Typography
                            variant={"body2"}
                            fontSize={"14px"}
                          >
                            C Number
                          </Typography>
                          <Stack alignItems={"center"} justifyContent={"center"} flexDirection={"row"} gap={"1rem"}>
                            <Typography
                              variant={"body2"}
                              fontSize={"18px"}
                              fontWeight={"bold"}
                              paddingTop={"6px"}
                            >
                              {documents[active].dbs_cnumber}
                            </Typography>
                            <CopyToClipboard
                              text={cNumber}
                              onCopy={() =>
                                dispatch(
                                  setNotificationMessage({
                                    display: true,
                                    severity: "success",
                                    message: `C Number is copied to clipboard`
                                  })
                                )
                              }
                            >
                              <IconButton size={"small"}>
                                <ContentCopyIcon sx={{fontSize: '1.2rem'}}/>
                              </IconButton>
                            </CopyToClipboard>
                          </Stack>
                        </Stack>

                      }
                      {documents[active].dbs_dob &&
                        <Stack>
                          <Typography
                            variant={"body2"}
                            fontSize={"14px"}
                          >
                            Date of Birth
                          </Typography>
                          <Stack alignItems={"center"} justifyContent={"center"} flexDirection={"row"} gap={"1rem"}>
                            <Typography
                              variant={"body2"}
                              fontSize={"18px"}
                              fontWeight={"bold"}
                              paddingTop={"6px"}
                            >
                              {documents[active].dbs_dob}
                            </Typography>
                            <CopyToClipboard
                              text={dob}
                              onCopy={() =>
                                dispatch(
                                  setNotificationMessage({
                                    display: true,
                                    severity: "success",
                                    message: `Date of birth is copied to clipboard`
                                  })
                                )
                              }
                            >
                              <IconButton size={"small"}>
                                <ContentCopyIcon sx={{fontSize: '1.2rem'}}/>
                              </IconButton>
                            </CopyToClipboard>
                          </Stack>

                        </Stack>
                      }
                      <Stack direction={"row"} gap={"1rem"}>
                        <Button
                          sx={{width: 'fit-content', height: 'auto'}}
                          startIcon={<EditOutlinedIcon/>}
                          variant={"outlined"}
                          onClick={() => setOpenModal(true)}
                        >
                          Change
                        </Button>


                      </Stack>
                    </>
                  ) : (
                    <Button
                      sx={{width: 'fit-content'}}
                      startIcon={<AddOutlined/>}
                      variant={"outlined"}
                      onClick={() => setOpenModal(true)}
                    >
                      DBS Info
                    </Button>
                  )
                }

              </Stack>

            </Stack>

            <Modal
              open={openModal}
              setModalClose={setOpenModal}
              children={rtwModal}
              title={"Add DBS Info"}
              size="sm"
              className={undefined}
              closeButton={true}
            />
            <Modal
              open={uploadModal}
              setModalClose={setUploadModal}
              children={uploadModalContent}
              title={"Upload DBS Certificate"}
              size="sm"
              className={undefined}
              closeButton={true}
              padding={"0"}
            />


          </Grid>
        </Grid>
      </Grid>
      {documents[active].sub_document_count == 0 &&
        <Grid xs={12} container className="card-vetting" sx={{marginTop: '2rem !important'}}>
          <Grid
            container
            className="card-head-table"
            direction="row"
            justifyContent={"space-between"}
            alignItems={'center'}
            sx={{marginBottom: '0 !important'}}
          >
            <Grid
              item
              xs={12}
              sx={{pl: 1, pt: 1, pb: 2}}
            >

              <Button
                sx={{width: 'fit-content', color: '#fff'}}
                variant={"contained"}
                startIcon={<UploadFile/>}
                onClick={() => {
                  dispatch(setName(" : DBS Certificate"));
                  dispatch(
                    setRequestData({
                      request_id: id,
                      vetting_document_id: documents[active].id,
                      document_repo_id: "",
                    })
                  );
                  setUploadModal(true)

                }}
              >
                Upload Certificate
              </Button>

            </Grid>
          </Grid>
        </Grid>
      }

    </>
  );
};

export default AgencyAddRightWorkInfo;