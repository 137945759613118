import { FC } from "react";
import {
  Stack,
  Typography,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Card } from "react-bootstrap";
import LimitCapItem from "../Components/AgencyDetails/LimitCapItem";
import { setLimitCapChangeDialog } from "../../../../../utils/redux";
import { useDispatch, useSelector } from "react-redux";
import {
  GetOpenedAgency,
  setIsHistoryDialogOpened
} from "../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";
import InfoIcon from '@mui/icons-material/Info';

const LimitCap: FC<any> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const openedAgency = useSelector(GetOpenedAgency);

  const handleOpenDialog = () => {
    dispatch(setLimitCapChangeDialog(true))
  }
  const handleOpenHistoryDialog = () => {
    dispatch(setIsHistoryDialogOpened(true))
  }

  return (
    <Card style={{ width: isMobile ? '100%' : '20rem' }}>
      <Stack flexDirection={'row'} padding={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography sx={{ fontSize: '1rem', lineHeight: '1.43rem' }}>Limit Cap</Typography>
        {(openedAgency?.limits.rtw?.cap || openedAgency?.limits.rtw?.total ||
          openedAgency?.limits.dbs?.cap || openedAgency?.limits.dbs?.total ||
          openedAgency?.limits.esign?.cap || openedAgency?.limits.esign?.total) && (
            <Stack flexDirection={'row'} justifyContent={'center'} gap={'1rem'}>
              <Button variant="outlined" onClick={handleOpenDialog}>CHANGE</Button>
              <Button onClick={handleOpenHistoryDialog}>HISTORY</Button>
            </Stack>
          )}
      </Stack>
      <Divider />
      <Stack padding={'1rem'} gap={'1rem'}>
        <Stack padding={'1rem'} gap={'1rem'}>
          {openedAgency?.limits.rtw?.cap && (
            <LimitCapItem title={"Right To Work"} cap={openedAgency.limits.rtw.cap} total={openedAgency.limits.rtw.total} />
          )}

          {openedAgency?.limits.dbs?.cap && (
            <LimitCapItem title={"DBS Checks"} cap={openedAgency.limits.dbs.cap} total={openedAgency.limits.dbs.total} />
          )}

          {openedAgency?.limits.esign?.cap && (
            <LimitCapItem title={"E-Sign"} cap={openedAgency.limits.esign.cap} total={openedAgency.limits.esign.total} />
          )}

          {(!openedAgency?.limits.rtw?.cap && !openedAgency?.limits.rtw?.total &&
            !openedAgency?.limits.dbs?.cap && !openedAgency?.limits.dbs?.total &&
            !openedAgency?.limits.esign?.cap && !openedAgency?.limits.esign?.total) && (
              <Stack direction="row" alignItems="center" gap={'0.5rem'}>
                <InfoIcon color="info" />
                <Typography variant="body1" color="textSecondary">
                  Limit cap is empty.
                </Typography>
              </Stack>
            )}
        </Stack>
      </Stack>

    </Card>
  );
}


export default LimitCap;
