import React, { FC, useCallback, useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  Stack,
  Avatar,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Box,
  IconButton,
  TableHead,
  CircularProgress,
  TablePagination
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import avatar from '../../../../../../../utils/Avatar/avatar';
import { useDispatch, useSelector } from "react-redux";
import {
  GetFilteredDate,
  GetOpenedAgency,
  setServicesData,
  setServicesDataLoading
} from "../../../../../../../utils/redux/reducer/admin-reducers/admin-agency-slice";
import axiosInstance from "../../../../../../../utils/axios";
import { setNotificationMessage } from "../../../../../../../utils/redux";
import ServiceInfoRTWTab from "./ServiceInfoRTWTab";
import ServiceInfoDBSTab from './ServiceInfoDBSTab';
import ServiceInfoEsignTab from './ServiceInfoEsignTab';
import ServiceInfoReferenceTab from './ServiceInfoReferenceTab';
import ServiceInfoDocumentsTab from './ServiceInfoDocumentsTab';

interface DialogComponentProps {
  open: boolean;
  onClose: () => void;
  activeTab: number;
  setActiveTab: (tab: number) => void;
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
}

const ServiceInfoDialog: FC<DialogComponentProps> = ({
  open,
  onClose,
  activeTab,
  setActiveTab,
  activeFilterTab,
  setActiveFilterTab
}) => {
  const dispatch = useDispatch();
  const openedAgency = useSelector(GetOpenedAgency);
  const [serviceInfo, setServiceInfo] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);

  const filteredDates = useSelector(GetFilteredDate);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(8);

  const fetchServicesInfoData = useCallback(async () => {
    try {
      setIsLoading(true);
      setServiceInfo([]);
      let service_type = 'rtw';
      switch (activeTab) {
        case 1:
          service_type = 'dbs';
          break;
        case 2:
          service_type = 'esign';
          break;
        case 3:
          service_type = 'reference';
          break;
        case 4:
            service_type = 'documents';
          break;
        default:
          service_type = 'rtw';
          break;
      }

      const response = await axiosInstance.get(`${process.env.REACT_APP_NODE_ADMIN_API_URL}/authenticated/admin/agencies/info/${openedAgency?.agency_id}/${service_type}?start_date=${filteredDates.start_date}&end_date=${filteredDates.end_date}`);

      console.log("Response data for tab", activeTab, response.data);
      setServiceInfo(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(
        setNotificationMessage({
          display: true,
          severity: 'error',
          message: 'There is an error in fetching service data.',
        })
      );
    } finally {
      setIsLoading(false);
    }
  }, [activeTab, openedAgency, dispatch, filteredDates]);

  useEffect(() => {
    if (open) {
      fetchServicesInfoData();
    }
  }, [open, fetchServicesInfoData, activeTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '80vw',
          maxWidth: '80vw',
          height: '80vh',
          opacity: 1,
          margin: '0 auto',
          borderRadius: '5px',
        },
      }}
    >
      <DialogTitle sx={{ width: '100%', height: '72px', padding: '16px 24px', opacity: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
          <Stack direction="row" alignItems="start" gap={"2rem"}>
            <Stack direction="row" alignItems="center" gap={"0.5rem"}>
              <Avatar
                {...avatar(openedAgency?.agency_name ? openedAgency.agency_name : '')}
              />
              <Typography>{openedAgency?.agency_name}</Typography>
            </Stack>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              sx={{ flex: 1 }}
            >
              <Tab label="RIGHT TO WORK" disabled={isLoading} sx={{ width: 'fit-content', textWrap: 'nowrap' }} />
              <Tab label="DBS" disabled={isLoading} />
              <Tab label="E-SIGN" disabled={isLoading} />
              <Tab label="REFERENCES" disabled={isLoading} />
              <Tab label="DOCUMENTS" disabled={isLoading} />
            </Tabs>
          </Stack>
          <Stack direction={"row"} gap={"0.5rem"}>
            <Stack direction={"row"} alignItems="center">
              {filteredDates && (filteredDates.start_date || filteredDates.end_date) && (
                <>
                  <Typography sx={{ fontSize: '0.875rem' }}>Date:</Typography>
                  <Typography sx={{ fontSize: '0.875rem', marginLeft: '0.5rem' }}>
                    {filteredDates.start_date && filteredDates.end_date
                      ? `${new Date(filteredDates.start_date || '').toLocaleDateString('en-US')} - ${new Date(filteredDates.end_date || '').toLocaleDateString('en-US')}`
                      : filteredDates.start_date
                        ? new Date(filteredDates.start_date || '').toLocaleDateString('en-US')
                        : new Date(filteredDates.end_date || '').toLocaleDateString('en-US')}
                  </Typography>
                </>
              )}
            </Stack>
            <IconButton
              size="small"
              onClick={onClose}
              sx={{
                width: '20px',
                height: '20px',
                padding: '4.17px',
                gap: 0,
                opacity: 1,
                transition: 'opacity 300ms ease-out',
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider sx={{ marginBottom: 0 }} />
      <DialogContent sx={{ width: '100%', padding: 0, overflow: 'hidden' }}>
        {activeTab === 0 &&
          <ServiceInfoRTWTab
            activeTab={activeTab}
            activeFilterTab={activeFilterTab}
            setActiveFilterTab={setActiveFilterTab}
            services={serviceInfo}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            handleChangePage={handleChangePage}
          />
        }
        {activeTab === 1 &&
          <ServiceInfoDBSTab
            activeTab={activeTab}
             activeFilterTab={activeFilterTab}
            setActiveFilterTab={setActiveFilterTab}
            services={serviceInfo}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            handleChangePage={handleChangePage}
          />
        }
        {activeTab === 2 &&
          <ServiceInfoEsignTab
            activeTab={activeTab}
            activeFilterTab={activeFilterTab}
            setActiveFilterTab={setActiveFilterTab}
            services={serviceInfo}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            handleChangePage={handleChangePage}
          />
        }
        {activeTab === 3 &&
          <ServiceInfoReferenceTab
            activeTab={activeTab}
            activeFilterTab={activeFilterTab}
            setActiveFilterTab={setActiveFilterTab}
            services={serviceInfo}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            handleChangePage={handleChangePage}
          />
        }
        {activeTab === 4 &&
          <ServiceInfoDocumentsTab
            // activeTab={activeTab}
            // activeFilterTab={activeFilterTab}
            // setActiveFilterTab={setActiveFilterTab}
            services={serviceInfo}
            page={page}
            rowsPerPage={rowsPerPage}
            isLoading={isLoading}
            handleChangePage={handleChangePage}
          />
        }
      </DialogContent>
    </Dialog>
  );
};

export default ServiceInfoDialog;
