import React from 'react';
import {
    Stack,
    Chip,
    Typography,
} from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import dayjs from 'dayjs';
import {
    LimitCapHistoryModel,
} from '../../Interfaces/AgencyInterfaces';
import LimitCapHistoryItem from './LimitCapHistoryItem';

interface LimitCapHistoryTimelineProps {
    history: LimitCapHistoryModel['cap_history']['rtw'];
    label: string;
    currentLimit: number;
}

const LimitCapHistoryTimeline: React.FC<LimitCapHistoryTimelineProps> = ({ history, label, currentLimit }) => (
    <Stack padding={'1.5rem 0rem'} gap={'1.5rem'}>
        <Stack justifyContent={'space-between'} flexDirection={'row'} alignItems={'center'} gap={'2rem'}>
            <Typography fontSize={'1rem'} fontWeight={500} lineHeight={'1.5rem'}>
                {label}
            </Typography>
            <Chip label={currentLimit} variant="outlined" />
        </Stack>
        <Timeline sx={{ alignItems: 'flex-start', padding: 0, margin: 0 }}>
            {history.map((item, index) => (
                <LimitCapHistoryItem
                    key={index}
                    date={dayjs(item.date).format('MMM D YYYY')}
                    value={item.new_cap}
                    label={item.type}
                    isLastItem={index === history.length - 1}
                />
            ))}
        </Timeline>
    </Stack>
);

export default LimitCapHistoryTimeline;