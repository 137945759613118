import React, { FC, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Chip,
  CircularProgress,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Typography
} from "@mui/material";
import dayjs from "dayjs";
import avatar from "../../../../../../../utils/Avatar/avatar";

interface ServiceInfoRTWTabProps {
  activeTab: number;
  activeFilterTab: string;
  setActiveFilterTab: (tab: string) => void;
  services: any[];
  page: number;
  rowsPerPage: number;
  isLoading: boolean;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const ServiceInfoRTWTab: FC<ServiceInfoRTWTabProps> = ({
  activeTab,
  activeFilterTab,
  setActiveFilterTab,
  services,
  page,
  rowsPerPage,
  isLoading,
  handleChangePage
}) => {
  const [status, setStatus] = useState(activeFilterTab ? activeFilterTab : "All");
  const [scheme, setScheme] = useState("All");
  const [searchName, setSearchName] = useState("");
  const [filteredServices, setFilteredServices] = useState<any[]>([]);

  const uniqueSchemes = Array.from(new Set(services.map(service => service.scheme)));

  const handleChipClick = (newStatus: string) => {
    setStatus(newStatus);
  };

  const handleSchemeChange = (event: any) => {
    setScheme(event.target.value);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  useEffect(() => {
    let filtered = services;

    // Filter by status
    if (status !== "All") {
      filtered = filtered.filter(service => 
        status === "SUCCESS"
          ? service.status?.toUpperCase() === "COMPLETED" && service.identity_profile_status?.toUpperCase() !== "ABORTED"
          : status === "REJECTED"
          ? (service.identity_profile_status?.toUpperCase() === "FAILED" || service.identity_profile_status === "ABORTED")
          : service.status?.toUpperCase() === status.toUpperCase()
      );
    }

    // Filter by scheme
    if (scheme !== "All") {
      filtered = filtered.filter(service => service.scheme === scheme);
    }

    // Filter by applicant name
    if (searchName) {
      const searchText = searchName.toLowerCase();
      filtered = filtered.filter(service =>
        service.candidate_name.toLowerCase().includes(searchText) ||
        (service.candidate_email && service.candidate_email.toLowerCase().includes(searchText)) ||
        (service.request_name && service.request_name.toLowerCase().includes(searchText))
      );
    }

    setFilteredServices(filtered);
  }, [status, scheme, searchName, services]);

  return (
    <>
      {isLoading ? (
        <Stack p="1rem" alignItems="center" justifyContent="center">
          <CircularProgress size={20} />
        </Stack>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            padding: "16px",
            background: "linear-gradient(90.5deg, #E9FAFF 5.76%, #F0F0FF 50.43%)",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        >
          <TableContainer
            component={Paper}
            sx={{
              borderRadius: "12px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              paddingBottom: "16px",
              width: "100%",
              maxHeight: "auto",
              overflowY: "auto"
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Stack direction="row" spacing={1}>
                      {["All", "ONGOING", "SUBMITTED", "SUCCESS", "REJECTED"].map((label) => (
                        <Chip
                          key={label}
                          label={label}
                          clickable
                          variant="outlined"
                          color={status === label ? "primary" : "default"}
                          onClick={() => handleChipClick(label)}
                        />
                      ))}
                    </Stack>
                  </TableCell>
                  <TableCell colSpan={4}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
                      <TextField
                        label="Search by Applicant Name"
                        variant="outlined"
                        value={searchName}
                        size="small"
                        onChange={handleSearchChange}
                        sx={{ maxWidth: 250 }}
                      />
                      <FormControl sx={{ minWidth: 120 }}>
                        <InputLabel>Scheme</InputLabel>
                        <Select value={scheme} onChange={handleSchemeChange} label="Scheme" size="small">
                          <MenuItem value="All">All</MenuItem>
                          {uniqueSchemes.map((scheme, index) => (
                            <MenuItem key={index} value={scheme}>
                              {scheme}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography>Total: {filteredServices.length}</Typography>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Applicant Name</TableCell>
                  <TableCell>Scheme</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Request</TableCell>
                  <TableCell>Designation</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredServices
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar {...avatar(row.candidate_name)} />
                          <Stack>
                            <Typography>{row.candidate_name}</Typography>
                            <Typography fontSize="14px" color="text.secondary">
                              {row.candidate_email || "No Email"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TableCell>
                      <TableCell><Chip label={row.scheme} clickable variant={"outlined"} /></TableCell>
                      <TableCell>
                        {row.identity_profile_status?.toUpperCase() === "FAILED" || row.identity_profile_status?.toUpperCase() === "ABORTED"
                          ? row.identity_profile_status  
                          : row.status?.toUpperCase() === "COMPLETED" && row.identity_profile_status?.toUpperCase() !== "ABORTED"
                          ? "SUCCESS"
                          : row.status}
                      </TableCell>

                      <TableCell>{row.request_name}</TableCell>
                      <TableCell>{row.designation}</TableCell>
                      <TableCell>{dayjs(row.created_at).format("DD MMM YYYY")}</TableCell>
                    </TableRow>
                  ))}
                {filteredServices.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      <Typography>No Applicant Found</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={filteredServices.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[]}
              labelRowsPerPage=""
            />
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default ServiceInfoRTWTab;
