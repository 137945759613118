import {
  AddressHistory,
  PersonalHistory,
  PersonalInformation,
  PassportInformation,
  DrivingLicenceInformation,
  UserInformation,
} from '../../../../../../../../utils/redux/reducer/u-check-slice';
import dayjs from 'dayjs';

export interface UCheckProps {
  role?: string;
  activeStep?: number;
  handleBack?: () => void;
  handleNext?: () => void;
  steps?: string[];
  disabled?: boolean;
}

export const generatePayload: (
  personalInformation: PersonalInformation,
  personalHistory: PersonalHistory,
  currentAddress: { address: AddressHistory; form_valid: boolean },
  previousAddresses: { address: AddressHistory[]; form_valid: boolean },
  passportDetails: PassportInformation,
  drivingLicenceDetails: DrivingLicenceInformation,
  role: string,
  user?: { [key: string]: string } | null,
  candidateInfo?: UserInformation,
  idFirstId?: string,
  secondId?: string
) => {
  applicant_details: any;
} = (
  personalInformation,
  personalHistory,
  currentAddress,
  previousAddresses,
  passportDetails,
  drivingLicenceDetails,
  role,
  user,
  candidateInfo,
  idFirstId,
  secondId
) => {
  const payload = {
    applicant_details: {},
  };

  const middlenames: { middlename: string }[] = [];
  const other_surnames: {
    forename?: string;
    surname?: string;
    used_from?: string;
    used_to?: string;
  }[] = [];
  const other_forenames: {
    forename?: string;
    surname?: string;
    used_from?: string;
    used_to?: string;
  }[] = [];
  const previous_addresses: {
    address: AddressHistory;
    resident_dates: {
      resident_from_gyear_month: string;
      resident_to_gyear_month: string;
    };
  }[] = [];
  Object.keys(personalInformation).forEach((key) => {
    if (key.indexOf('middlename') > -1) {
      if (!!personalInformation[key as keyof PersonalInformation]) {
        let string = personalInformation[
          key as keyof PersonalInformation
        ] as string;
        let trimmedata = string.trim();
        middlenames.push({
          middlename: trimmedata,
        });
        console.log(personalInformation[key as keyof PersonalInformation]);
      }
    }
  });

  personalHistory.other_names.forEach((other_name) => {
    if (other_name.other_name_type === 'surname') {
      other_surnames.push({
        forename: !!other_name.forename ? other_name.forename : undefined,
        surname: !!other_name.surname ? other_name.surname : undefined,
        used_from: !!other_name.used_from
          ? dayjs(new Date(other_name.used_from)).format('YYYY-MM')
          : undefined,
        used_to: !!other_name.used_to
          ? dayjs(new Date(other_name.used_to)).format('YYYY-MM')
          : undefined,
      });
    } else {
      other_forenames.push({
        forename: !!other_name.forename ? other_name.forename : undefined,
        surname: !!other_name.surname ? other_name.surname : undefined,
        used_from: !!other_name.used_from
          ? dayjs(new Date(other_name.used_from)).format('YYYY-MM')
          : undefined,
        used_to: !!other_name.used_to
          ? dayjs(new Date(other_name.used_to)).format('YYYY-MM')
          : undefined,
      });
    }
  });

  previousAddresses.address.forEach((previous_address) => {
    previous_addresses.push({
      address: {
        address_county: !!previous_address.address_county
          ? previous_address.address_county.trim()
          : undefined,
        address_line1: !!previous_address.address_line1
          ? previous_address.address_line1.trim()
          : undefined,
        address_line2: !!previous_address.address_line2
          ? previous_address.address_line2.trim()
          : undefined,
        address_town: !!previous_address.address_town
          ? previous_address.address_town.trim()
          : undefined,
        country_code: !!previous_address.country_code
          ? previous_address.country_code
          : undefined,
        postcode: !!previous_address.postcode
          ? previous_address.postcode.trim()
          : undefined,
      },
      resident_dates: {
        resident_from_gyear_month: dayjs(
          new Date(previous_address.resident_from_gyear_month as string)
        ).format('YYYY-MM'),
        resident_to_gyear_month: dayjs(
          new Date(previous_address.resident_to_gyear_month as string)
        ).format('YYYY-MM'),
      },
    });
  });

  let applicant_identity_details: {
    ni_number?: string;
    nin?: string;
    evidence_checked_by?: string;
    identity_verified?: string;
    has_passport?: string;
    uk_driving_licence?: string;
    passport_details?: {
      passport_number?: string;
      passport_dob?: string;
      passport_issue_date?: string;
      passport_nationality?: string;
    };
    driver_licence_details?: {
      driver_licence_number?: string;
      driver_licence_type?: string;
      driver_licence_dob?: string;
      driver_licence_valid_from?: string;
      driver_licence_issue_country?: string;
    };
    id_type_verified?: {
      id1?: string;
      id2?: string;
    };
  } = {};

  if (personalInformation.has_nin === 'y') {
    applicant_identity_details = {
      ...applicant_identity_details,
      ni_number: personalInformation.nin,
    };
  }

  if (idFirstId !== '' && secondId !== '') {
    applicant_identity_details = {
      ...applicant_identity_details,
      id_type_verified: {
        id1: idFirstId,
        id2: secondId,
      },
    };
  }

  if (
    !!drivingLicenceDetails.uk_driving_licence ||
    !!passportDetails.has_passport
  ) {
    applicant_identity_details = {
      ...applicant_identity_details,
      evidence_checked_by: 'YOTI',
      has_passport: passportDetails.has_passport,
      uk_driving_licence: drivingLicenceDetails.uk_driving_licence,
      identity_verified: 'y',
    };
    if (passportDetails.has_passport === 'y') {
      const passport_details = {
        passport_number: !!passportDetails.passport_number
          ? passportDetails.passport_number
          : undefined,
        passport_dob: !!passportDetails.passport_dob
          ? passportDetails.passport_dob
          : undefined,
        passport_issue_date: !!passportDetails.passport_issue_date
          ? passportDetails.passport_issue_date
          : undefined,
        passport_nationality: !!passportDetails.passport_nationality
          ? passportDetails.passport_nationality
          : undefined,
      };
      applicant_identity_details = {
        ...applicant_identity_details,
        passport_details,
      };
    }
    if (drivingLicenceDetails.uk_driving_licence === 'y') {
      const driver_licence_details = {
        driver_licence_number: !!drivingLicenceDetails.driver_licence_number
          ? drivingLicenceDetails.driver_licence_number
          : undefined,
        driver_licence_type: 'photo',
        driver_licence_dob: !!drivingLicenceDetails.driver_licence_dob
          ? drivingLicenceDetails.driver_licence_dob
          : undefined,
        driver_licence_valid_from:
          !!drivingLicenceDetails.driver_licence_valid_from
            ? drivingLicenceDetails.driver_licence_valid_from
            : undefined,
        driver_licence_issue_country:
          !!drivingLicenceDetails.driver_licence_issue_country
            ? drivingLicenceDetails.driver_licence_issue_country
            : undefined,
      };
      applicant_identity_details = {
        ...applicant_identity_details,
        driver_licence_details,
      };
    }
  }
  let contact_number;
  let email;
  if (role !== 'agency') {
    if (!!user?.phone_number && !!user.email) {
      contact_number = user?.phone_number.split(user['custom:phone_code'])[1];
      email = user?.email;
    } else {
      contact_number = undefined;
      email = undefined;
    }
  } else {
    if (!!candidateInfo?.phone_number && !!candidateInfo.email) {
      contact_number = candidateInfo.phone_number;
      email = candidateInfo.email;
    } else {
      contact_number = undefined;
      email = undefined;
    }
  }

  payload.applicant_details = {
    title: !!personalInformation.title ? personalInformation.title : undefined,
    forename: !!personalInformation.forename
      ? personalInformation.forename
      : undefined,
    present_surname: !!personalInformation.present_surname
      ? personalInformation.present_surname
      : undefined,
    middlenames: middlenames.length > 0 ? middlenames : undefined,
    date_of_birth: !!personalInformation.date_of_birth
      ? personalInformation.date_of_birth
      : undefined,
    gender: !!personalInformation.gender
      ? personalInformation.gender
      : undefined,
    contact_email: email,
    current_address: currentAddress.form_valid
      ? {
          address: {
            address_county: !!currentAddress.address.address_county
              ? currentAddress.address.address_county.trim()
              : undefined,
            address_line1: !!currentAddress.address.address_line1
              ? currentAddress.address.address_line1.trim()
              : undefined,
            address_line2: !!currentAddress.address.address_line2
              ? currentAddress.address.address_line2.trim()
              : undefined,
            address_town: !!currentAddress.address.address_town
              ? currentAddress.address.address_town.trim()
              : undefined,
            country_code: !!currentAddress.address.country_code
              ? currentAddress.address.country_code
              : undefined,
            postcode: !!currentAddress.address.postcode
              ? currentAddress.address.postcode.trim()
              : undefined,
          },
          resident_from_gyear_month: dayjs(
            currentAddress.address.resident_from_gyear_month as string
          ).format('YYYY-MM'),
        }
      : undefined,
    previous_addresses:
      previous_addresses.length > 0 ? previous_addresses : undefined,
    additional_applicant_details: {
      birth_town: !!personalInformation.birth_town
        ? personalInformation.birth_town
        : undefined,
      birth_county: !!personalInformation.birth_county
        ? personalInformation.birth_county
        : undefined,
      birth_country: !!personalInformation.birth_country
        ? personalInformation.birth_country
        : undefined,
      birth_nationality: !!personalInformation.birth_nationality
        ? personalInformation.birth_nationality
        : undefined,
      contact_number: contact_number,
      mother_maidenname: !!personalInformation.mother_maidenname
        ? personalInformation.mother_maidenname
        : undefined,
      nationality_change: !!personalHistory.nationality_change
        ? personalHistory.nationality_change
        : undefined,
      current_nationality: !!personalHistory.current_nationality
        ? personalHistory.current_nationality
        : undefined,
      unspent_convictions: !!personalHistory.unspent_convictions
        ? personalHistory.unspent_convictions
        : undefined,
      birth_surname: personalHistory.birth_surname
        ? personalHistory.birth_surname
        : undefined,
      birth_forename: !!personalHistory.birth_forename
        ? personalHistory.birth_forename
        : undefined,
      declaration_by_applicant: 'y',
      birth_surname_until:
        !!personalHistory.birth_surname_until &&
        personalHistory.birth_surname_until !== 'Invalid date'
          ? dayjs(new Date(personalHistory.birth_surname_until)).format(
              'YYYY-MM'
            )
          : undefined,
      other_surnames: other_surnames.length > 0 ? other_surnames : undefined,
      other_forenames: other_forenames.length > 0 ? other_forenames : undefined,
    },
    applicant_identity_details:
      Object.keys(applicant_identity_details).length > 0
        ? applicant_identity_details
        : undefined,
  };

  return payload;
};
